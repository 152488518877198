import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'material-design-icons-iconfont/dist/material-design-icons.css' // <-- md 추가

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi' || 'md'
  }
})
