// 커스텀 필터 함수를 정의한다.
export default (value = '') => {
  value = '' + value // 문자열화

  // #1) 숫자를 세 자리 마다 쉼표를 넣은 문자로 변환한다. (1000 -> '1,000')
  // return value.split('').reverse().reduce((acc, digit, index) => {
  //   if (index > 0 && index % 3 === 0) acc.push(',')
  //   return [...acc, digit]
  // }, []).reverse().join('')

  // #2) 정규표현식을 사용한 2번째 방법
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
