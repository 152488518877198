<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <!-- <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn> -->

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>

            <v-col cols="12" xs="12" class="mt-0">
              <v-text-field
                value="물품구매관리팀"
                label="담당부서"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" class="mt-2">
              <span class="text-h6 mt-0 mb-2 pb-0 green--text">기본정보</span>
            </v-col>
            <v-col cols="12" xs="12">
              <v-row
                no-gutters
                align="center"
                justify="start"
              >
                <v-col cols="12" xs="9" sm="9">
                  <v-text-field
                    ref="txtShopName"
                    v-model.trim="form.name"
                    v-validate="'required|max:30'"
                    maxlength="30"
                    :error-messages="errors.collect('name')"
                    data-vv-name="name"
                    :counter="30"
                    required
                    label="상호*"
                    hint="중복체크 필수"
                    @keyup="chkShopNameOverlapReset"
                    :error="isOverlabed"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="3" sm="3">
                  <v-btn small text class="ma-2"
                    :color="mustChk ? 'error' : 'indigo'"
                    @click="chkShopNameOverlap"
                  >
                    <v-icon small class="text-left">
                      {{ chkMulti ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                    </v-icon>
                    중복체크
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.coNum"
                label="사업자번호"
                maxlength="12"
                counter="12"
                hint="예) 123-45-77777"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                v-model.trim="form.ceoName"
                label="대표자"
                maxlength="30"
                counter="30"
                hint="예) 홍길동"
                class="mr-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                v-model.trim="form.director"
                label="담당자"
                maxlength="30"
                counter="30"
                hint="예) 홍길동"
                class="ml-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.address"
                label="주소"
                maxlength="100"
                hint="예) 강남구 역삼로 111"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                v-model.trim="form.hp"
                label="휴대전화"
                maxlength="30"
                counter="30"
                hint="010-1234-5678"
                class="mr-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                v-model.trim="form.tel"
                label="일반전화"
                maxlength="30"
                counter="30"
                hint="02-1234-5678"
                class="ml-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                v-model.trim="form.fax"
                label="팩스"
                maxlength="30"
                counter="30"
                hint="02-1234-5678"
                class="mr-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                v-model.trim="form.email"
                label="이메일"
                maxlength="50"
                counter="50"
                hint="lawork@gmail.com"
                class="ml-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.homepage"
                label="홈페이지"
                maxlength="100"
                hint="예) abcd.co.kr"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.bankAccount"
                label="은행계좌"
                maxlength="100"
                hint="예) 하나은행 000-000000-0000"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.items"
                label="취금품목"
                maxlength="100"
                placeholder="복수인 경우 ,(쉼표)로 구분하세요.(예: 도서,명함)"
                hint="복수인 경우 ,(쉼표)로 구분하세요.(예: 도서,명함)"
                class="my-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="mt-2 mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

export default {
  components: {
    baseModal
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 650,
      height: 600,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      gubun1: '',
      gubun2: '',
      isStar: 0,
      name: '', // !! 상호 - 필수값(중복체크)
      coNum: '', // 사업자번호
      ceoName: '', // 대표자명
      director: '', // 담당자
      address: '', // 주소
      hp: '', // 휴대전화
      tel: '', // 일반전화
      fax: '', // 팩스
      email: '', // 이메일
      homepage: '', // 홈페이지
      bankAccount: '', // 은행계좌
      items: '' // 취급품목
    },
    // 구분: 수정 인덱스
    index: -1,
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        name: '상호'
      }
    },
    // 사용안함:구분: 수정시 처리자와 최종업데이트 정보
    // viewWname: '',
    // viewUpdatedAt: '',
    // 구분: 상호 중복체크
    mustChk: false, // 중복체크를 해야 하는지 여부
    chkMulti: false, // 참고: 이름 중복 확인했는지 여부
    isOverlabed: false // 참고: 이름 중복 되었는지 여부
  }),

  watch: {
    'form.name': { // !! 상호 중복체크를 하도록 하는 로직의 핵심임
      handler: function (val, oldVal) {
        if (val.length > 0) { // 값이 있으면
          // if (isSpKey(val)) { // 특수문자 필터링 - 필터
          // !! 원본 : /[\s{}[\]/?.,;:|)*~`!^+<>@#$%&\\=('"]/gi
          // 중요: 공백,()은 허용하기 위해 \s,() 를 뺌
          // (주)가나제과, 이사 김상백
          if (/[{}[\]/?.,;:|*~`!^+<>@#$%&\\='"]/gi.test(val)) {
            this.form.name = oldVal
            this.$refs.txtShopName.lazyValue = oldVal
          }

          this.mustChk = true // 중복체크 해야 함
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false // 중복 아니다.
        } else { // 내용이 아예 없으면 중복 체크하지 않아도 됨
          this.mustChk = false // 중복체크 안해도 됨
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false // 중복 아니다.
        }
      }
    }
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.form.id = 0
      this.form.gubun1 = ''
      this.form.gubun2 = ''
      this.form.isStar = 0
      this.form.name = '' // !! 상호 - 필수값(중복체크)
      this.form.coNum = '' // 사업자번호
      this.form.ceoName = ''
      this.form.director = ''
      this.form.address = ''
      this.form.hp = ''
      this.form.tel = ''
      this.form.fax = ''
      this.form.email = ''
      this.form.homepage = ''
      this.form.bankAccount = '' // 계좌
      this.form.items = '' // 취급품목

      this.index = -1 // 수정용 인덱스 초기화

      // 중복체크
      this.mustChk = false
      this.chkMulti = false
      this.isOverlabed = false
    },
    async open (title, options, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 초기화
        await this.initDialog()

        // 참고: 수정 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          this.index = index

          this.form.id = obj.id

          this.form.gubun1 = obj.gubun1
          this.form.gubun2 = obj.gubun2
          this.form.isStar = obj.isStar

          this.form.name = obj.name // !! 상호 - 필수값(중복체크)
          this.form.coNum = obj.coNum // 사업자번호
          this.form.ceoName = obj.ceoName
          this.form.director = obj.director
          this.form.address = obj.address
          this.form.hp = obj.hp
          this.form.tel = obj.tel
          this.form.fax = obj.fax
          this.form.email = obj.email
          this.form.homepage = obj.homepage
          this.form.bankAccount = obj.bankAccount
          this.form.items = obj.items

          // 참고: 편집일때는 처리자와 업데이트 일자를 보여준다.
          // this.viewWname = obj.wname
          // this.viewUpdatedAt = strDateFormat(obj.updatedAt) // 필터로 쓰진 않고 함수로 사용

          // !! 수정인 경우 중복체크를 한걸로다가 ~ 시간상 지연을 주기위해 nextTick() 사용
          this.$nextTick(function () {
            if (obj.name) {
              this.mustChk = false // 중복체크 필요없다
              this.chkMulti = true // 중복체크를 했다
              this.isOverlabed = false // 중복되지 않았다
            } else {
              this.mustChk = false
              this.chkMulti = false
              this.isOverlabed = false
            }
          })
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 등록
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // !! 상호 중복체크 확인
        if (this.mustChk && !this.chkMulti) { // 중복체크를 해야 하는데 하지 않았다
          this.isOverlabed = true // 중복여부를 가져오진 않았지만 수동으로 에러를 만든다.
          this.$refs.txtShopName.focus()
          throw new Error('중복체크를 하셔야 합니다.')
        }

        let resultId = 0 // !! 돌려줄 적용 아이디 - view 페이지로 넘기기 위해 반드시 필요!

        // !! DB 입력 처리 (등록/수정 동일하게 처리)
        const { data } = await this.$axios.post(`lawork/lwc/setShopContent`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        resultId = data.resultId

        // 등록/수정 후
        await this.initDialog()
        this.resolve(resultId) // !! 결과로 돌려받은 id값이 중요!
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        await this.initDialog() // 초기화
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 사용안함: 삭제 -- 인명부와 같이 상세페이지에서 삭제처리 함
    // async remove () {
    //   try {
    //     if (!this.form.id) throw new Error(`잘못된 요청입니다.`)

    //     // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
    //     const target = 'confirmDialog'
    //     const pop = await this.findParentRefs(this.$parent, target)
    //     if (!pop) throw new Error('팝업창을 열 수 없습니다.')
    //     // 찾았으면 팝업을 연다
    //     const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
    //     if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
    //       const { data } = await this.$axios.get(`/lawork/lwc/deleteShop/${this.form.id}`)
    //       if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
    //       await this.initDialog() // 초기화
    //       this.resolve('removed')
    //       this.dialog = false
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // !! 상호 중복체크 함수
    // 중복되어도 체크만 하면 등록가능하다! 동명이인이 있을 수 있으므로
    async chkShopNameOverlap () {
      try {
        // console.log(this.form)
        if (this.form.name.length > 0) {
          const { data } = await this.$axios.get(`lawork/lwc/chkShopNameOverlap/${this.form.name}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.chk) { // 중복 되었다
            this.mustChk = false // 중복되어도 등록할 수 있으니 풀어준다
            this.chkMulti = true // 역시 중복이어도 체크한걸로 넘어간다
            this.isOverlabed = false // 에러체크도 풀어준다.
            this.$refs.txtShopName.focus()
            throw new Error(`이미 등록된 거래처입니다.동명이인이라면 등록을 계속하십시오.`)
          } else {
            this.mustChk = false
            this.chkMulti = true
            this.isOverlabed = false
            this.$store.commit('SB_POP', { msg: '등록할 수 있는 거래처입니다.', color: 'success' })
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 이름 텍스트박스의 keyup 이벤트 - 중복체크 리셋
    async chkShopNameOverlapReset (e) {
      // 체크했는지 여부와 중복여부를 리셋시킨다.
      this.chkMulti = false
      this.isOverlabed = false
    },
    // !! [util함수] 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 사용안함: !! 바로가기 링크 - 이 경우는 독립메뉴 리스트로
    gotoDetail () {
      this.dialog = false
      // !! 현재 경로가 shop 디렉토리가 아닌 경우만 shop/ 로 이동시킨다
      if (!(this.$router.history.current.path.indexOf('shop') !== -1)) {
        this.$router.push(`/shop/list`)
      }
    }
  }
}
</script>
