// 참고: 기본 선택 색상 - 30개
const colors = [
  'black',
  'grey darken-2',
  'red lighten-2',
  'pink lighten-2',
  'purple lighten-2',
  'deep-purple lighten-2',
  'indigo lighten-2',
  'blue lighten-2',
  'cyan lighten-2',
  'teal lighten-2',
  'green lighten-2',
  'light-green lighten-2',
  'lime lighten-2',
  'yellow lighten-2',
  'amber lighten-2',
  'orange lighten-2',
  'deep-orange lighten-2',
  'grey lighten-1',
  'amber darken-3',
  'lime darken-3',
  'indigo darken-2',
  'red darken-1',
  'purple accent-1',
  'blue-grey lighten-2',
  'brown lighten-2',
  'info',
  'warning',
  'success',
  'error',
  'primary'
]

export default colors
