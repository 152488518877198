import Vue from 'vue'
import Vuex from 'vuex'
import storage from '@/lib/storage'
import { decodeToken } from '@/lib/jwt'

import todo from './modules/todo' // todo 모듈
import lwc from './modules/lwc' // lwc 모듈

// https://www.npmjs.com/package/vuex-persistedstate
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

// todo모듈: 이렇게 사전에 정의해도 된다.
// const todoState = createPersistedState({
//   paths: ['todo']
// })

export default new Vuex.Store({
  modules: {
    todo,
    lwc
  },

  // !! [2021.4.9] vuex-persistedstate 플러그인 적용
  plugins: [
    createPersistedState({
      paths: [ 'todo', 'lwc' ] // !! todo 모듈만 플러그인 적용한다!
    })
    // todoState // 사전 정의한 모듈을 매칭한다.
  ],

  // @:상태 - vue 의 data 처럼 오염을 막기위해 함수형태로 사용한다.
  state: () => ({
    token: storage.get('token'),
    ui: storage.get('ui'),
    // 구분: 스낵바 알림창
    sbAlert: {
      active: false,
      msg: '',
      color: 'error',
      timeout: 3000,
      shaped: false
    },
    // 구분: 에러 다이얼로그 알림창 - / 로 빼는 창
    dlAlert: {
      active: false,
      msg: '',
      color: 'error'
    },
    // 구분: 다이얼로그 알림창 중에 결제로 빠지는 창
    pyAlert: {
      active: false,
      msg: '',
      color: 'error'
    }
  }),
  // @:게터 - vue 의 computed 와 같다.
  // 주의점은 게터안에서 변이나 조작을 해선 안된다.
  // 상태의 상태를 가져오는 정도로 사용해야 한다.
  getters: {
  },
  // @:변이 - 변이는 무조건 동기적으로 움직인다.
  // 그러므로 동기적으로 움직여야 할 것은 변이로 처리하고 비동기를 사용하려면 액션을 사용해야 한다.
  mutations: {
    LOGIN (state, { token }) {
      state.token = token // 저장소(vuex)에 저장하고
      storage.set('token', token) // localStorage 에도 저장한다.

      // 토큰을 풀고 유저정보 저장
      decodeToken(token)
        .then(ui => {
          state.ui = ui
          storage.set('ui', ui)
        })
        .catch(e => console.error(e))
    },
    LOGOUT (state) { // 토큰과 유저정보 모두 삭제
      state.token = null
      storage.remove('token')

      state.ui = null
      storage.remove('ui')
    },
    // 구분: 스낵바 에러 메시지 팝업
    SB_POP (state, d) {
      state.sbAlert.msg = d.msg
      state.sbAlert.color = d.color || 'error'
      state.sbAlert.timeout = d.timeout || 3000
      state.sbAlert.shaped = d.shaped || false
      state.sbAlert.active = false
      if (d.active === undefined) state.sbAlert.active = true
    },
    // 구분: 모달 다이얼 로그 메시지 팝업 = 주로 에러 처리 알림. '/' 로 뺀다
    MD_POP (state, { msg, color, active }) {
      state.dlAlert.msg = msg
      state.dlAlert.color = color || 'error'
      state.dlAlert.active = active
      // if (active === undefined) state.dlAlert.active = true
    },
    // 구분: 모달 다이얼 알림 - 중에서 결제로 빠진다
    PY_POP (state, { msg, color, active }) {
      state.pyAlert.msg = msg
      state.pyAlert.color = color || 'error'
      state.pyAlert.active = active
      // if (active === undefined) state.pyAlert.active = true
    }
  },
  // @:액션 - 비동기로 사용할 수 었다는게 가장 큰 필요성이다!
  // https://vuex.vuejs.org/kr/guide/actions.html
  // 액션의 사용은 store.dispatch('someAction') 처럼 디스패치한다..
  actions: {
    LOGIN ({ commit }, { token }) {
      commit('LOGIN', { token })
    },
    LOGOUT ({ commit }) {
      commit('LOGOUT')
    }
  }
})
