import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

// !!참고: 상세페이지용 pdf 파일을 만드는 라이브러리

// !!중요: 나눔글꼴은 /proj01/vfsFont/vfs_fonts.js 파일에 위치한다.
// 이 파일을 빌드전에 node_modules/pdfmake/build/ 에 복사해야 한다!
// 사용하지 않을시는 fe/package.json 파일을 편집해야 한다.

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  },
  // 중요: 나눔글꼴 > 나눔스퀘어라운드 레귤러 폰트를 지정한다.
  Nanum: {
    normal: 'NanumSquareRoundR.ttf'
  }
}

// 이 라이브러리를 추가하고 아래처럼 사용하면 된다!
// pdfMake.createPdf(this.pdfDoc).download('optionalName.pdf') // pdf 를 만들고 다운로드
// pdfMake.createPdf(this.pdfDoc).open() // 만들고 새로운 탭에서 오픈!

// !! 공통으로 적용되는 상세뷰 스타일 정리
const pdfViewStyle = {
  header: {
    fontSize: 14,
    bold: false,
    alignment: 'center',
    margin: [0, 5, 0, 5]
  },
  subheader: {
    fontSize: 11,
    bold: false,
    alignment: 'right',
    margin: [0, 5, 0, 0],
    decoration: 'underline',
    lineHeight: 1.5
  },
  subheader2: {
    fontSize: 12,
    bold: false,
    alignment: 'right',
    margin: [0, 0, 25, 0],
    decoration: 'underline',
    lineHeight: 1.5
  },
  pheader: {
    fontSize: 12,
    bold: false,
    margin: [0, 12, 0, 4],
    decoration: 'underline',
    // markerColor: '#29B6F6', // the color of the bullets in a buletted list
    decorationStyle: 'double',
    // decorationColor: '#29B6F6', // the color of the text decoration, see color
    lineHeight: 1.5
  },
  tableHeader: {
    bold: false,
    fontSize: 10,
    fillColor: '#BBDEFB'
  },
  tableBody: {
    fontSize: 9,
    alignment: 'left',
    lineHeight: 1.5
  },
  tableBodyPast: {
    fontSize: 9,
    alignment: 'left',
    fillColor: '#eeeeee',
    lineHeight: 1.5
  },
  tableBody2: {
    fontSize: 11,
    alignment: 'left',
    margin: [0, 0, 0, 8],
    lineHeight: 1.5
  },
  tableBody3: {
    fontSize: 11,
    alignment: 'left',
    margin: [15, 0, 15, 15],
    lineHeight: 1.2
  },
  tableBody4: {
    fontSize: 11,
    alignment: 'left',
    margin: [25, 15, 25, 15],
    lineHeight: 1.7
  }
}

// @: 메뉴타이틀 함수
const menuTitle = (title) => {
  return {
    type: 'circle',
    ul: [{ text: title, style: 'pheader' }]
  }
}

// @: html 태그를 없애는 함수. 에디터로 입력한 내용(ex. 사실관계및쟁점정리)에 사용된다.
// !! 특이점은 어쨓거나 배열을 리턴한다
const rmTagReturnArray = (val) => {
  let returnValue = []
  if (val) {
    returnValue.push({
      text: val.replace(/<br>/gi, `\n`).replace(/<\/br>/gi, `\n`).replace(/<\/p>/gi, `\n`).replace(/(<([^>]+)>)/gi, '').replace(/\n{2,3}/gi, `\n`),
      border: [false, false, false, false]
    })
  } else {
    returnValue.push({
      text: `ⓧ 내용이 없습니다.`,
      border: [false, false, false, false],
      color: '#78909C'
    })
  }
  return returnValue
}

// @: view type 1 - 일반적인 내용, 기본내용과 사실관계및쟁점정리 항목에 사용
const viewType1 = (bodyContent) => {
  return bodyContent.length === 0 ? '' : {
    style: 'tableBody2',
    table: {
      widths: ['*'],
      body: [bodyContent]
    }
  }
}

// @: view type 2
// !! 진행내용 + 업무와 같은 borderless 내부테이블을 사용하는 리스트인 경우에 사용된다
// 주의: 아래 코드를 단축시키는 것도 좋겠지만 코드를 알아보고 이해하기 쉬워서 그냥 놔둔다.
const viewType2 = (bodyContent) => {
  if (bodyContent && bodyContent.length) { // 내용이 있을 때
    return {
      style: 'tableBody2',
      table: {
        widths: ['*'],
        body: [
          [
            {
              table: {
                body: bodyContent
              },
              border: [false, false, false, false]
            }
          ]
        ]
      }
    }
  } else { // 내용이 없을 때
    return {
      style: 'tableBody2',
      table: {
        widths: ['*'],
        body: [
          [
            {
              text: `ⓧ 내용이 없습니다.`,
              border: [false, false, false, false],
              color: '#78909C'
            }
          ]
        ]
      }
    }
  }
}

// @: view type 3
// 주의: 아래 코드를 단축시키는 것도 좋겠지만 코드를 알아보고 이해하기 쉬워서 그냥 놔둔다.
const viewType3 = (bodyContent) => {
  if (bodyContent && bodyContent.length) { // 내용이 있을 때
    return {
      style: 'tableBody3',
      table: {
        widths: ['*'],
        body: [
          [
            {
              table: {
                widths: ['*'],
                body: bodyContent
              },
              // border: [true, true, true, true]
              border: [false, false, false, false]
            }
          ]
        ]
      }
    }
  } else { // 내용이 없을 때
    return {
      style: 'tableBody3',
      table: {
        widths: ['*'],
        body: [
          [
            {
              text: `ⓧ 내용이 없습니다.`,
              border: [false, false, false, false],
              color: '#78909C'
            }
          ]
        ]
      }
    }
  }
}

// @: view type 4 - 실무계산기에서 사용한다. 여백의 여유를 주는 형식임
const viewType4 = (bodyContent) => {
  return bodyContent.length === 0 ? '' : {
    style: 'tableBody4',
    table: {
      widths: ['*'],
      body: [bodyContent]
    }
  }
}

// export default pdfMake
export {
  pdfMake,
  pdfViewStyle,
  menuTitle,
  rmTagReturnArray,
  viewType1,
  viewType2,
  viewType3,
  viewType4
}
