<template>
  <v-card
    tile
    :elevation="0"
  >
    <v-row no-gutters>
      <!-- 좌측 컨텐츠 시작 -->
      <v-col cols="12" md="6" lg="6" xl="6" class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-cog-refresh</v-icon> 계산조건
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              class="mr-2"
              @click.stop="doCalc"
            >
              <v-icon small>mdi-led-on</v-icon>
              계산
            </v-btn>
            <v-btn
              small
              @click.stop="doInit"
            >
              <v-icon small>mdi-refresh</v-icon>
              초기화
            </v-btn>
          </v-toolbar>
          <v-card
            tile
            outlined
            elevation="0"
            class="pa-2"
            min-height="568"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" class="ma-0 pa-0">
                <v-row
                  no-gutters
                  align="center"
                  justify="start"
                  class="pt-2"
                >
                  <v-col cols="4">
                    <v-select
                      v-model="rbChk"
                      :items="rbChkOptions"
                      item-text="text"
                      item-value="value"
                      label="신청방식"
                      menu-props="auto"
                      class="ma-0"
                      dense
                      outlined
                      filled
                      style="max-width: 120px;"
                      @change="rbChkChanged"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="abruyType"
                      :items="abruyTypeOptions"
                      item-text="text"
                      item-value="value"
                      label="가압류대상"
                      menu-props="auto"
                      class="ma-0"
                      dense
                      outlined
                      filled
                      style="max-width: 140px;"
                      @change="abruyChanged"
                    ></v-select>
                  </v-col>
                  <v-col cols="4" class="pl-4">
                    <v-text-field
                      ref="numSongdalFee"
                      v-model.trim="songdalFee"
                      v-validate="'required|max:8|is_not:0'"
                      :error-messages="errors.collect('songdalFee')"
                      data-vv-name="songdalFee"
                      required
                      maxlength="8"
                      label="*1회송달료(원)"
                      dense
                      outlined
                      filled
                      style="max-width: 120px;"
                      hint="예) 5,100"
                      @keypress="preventComma"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <span class="text-body-2 primary--text">*청구금액(원)</span>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <v-text-field
                    ref="numReqPrice"
                    v-model.trim="reqPrice"
                    v-validate="'required|max:15|is_not:0'"
                    :error-messages="errors.collect('reqPrice')"
                    data-vv-name="reqPrice"
                    required
                    maxlength="15"
                    dense
                    outlined
                    filled
                    style="max-width: 220px;"
                    @keypress="preventComma"
                  ></v-text-field>
                  <v-chip
                    v-show="rbChk === 2"
                    label
                    color="white"
                    class="error--text mt-1 ml-1"
                  >
                    <v-icon small left color="error">mdi-information</v-icon>
                    인지액 10% 감면
                  </v-chip>
                </v-row>
              </v-col>
              <v-col cols="12">
                <span class="text-body-2 primary--text">*당사자(명)</span>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <v-text-field
                    ref="numDangCnt"
                    v-model.trim="dangCnt"
                    v-validate="'required|max:8|is_not:0'"
                    :error-messages="errors.collect('dangCnt')"
                    data-vv-name="dangCnt"
                    required
                    maxlength="8"
                    dense
                    outlined
                    filled
                    style="max-width: 120px;"
                    placeholder="예) 2"
                    @blur="dangCnt = strToNumber(dangCnt) === 0 ? '1' : dangCnt"
                    @keypress="preventComma"
                  ></v-text-field>
                  <v-chip
                    v-show="rbChk === 2"
                    label
                    color="white"
                    class="text-caption error--text mt-1 ml-1"
                  >
                    <v-icon small left color="error">mdi-information</v-icon>
                    당사자수 = 전체 당사자수 - 전자소송 당사자수
                  </v-chip>
                </v-row>
              </v-col>
              <v-col cols="12"
                v-show="abruyType === 1 || abruyType === 4 || abruyType === 5"
              >
                <span class="text-body-2 primary--text">*목적물(개)</span>
              </v-col>
              <v-col cols="12"
                v-show="abruyType === 1 || abruyType === 4 || abruyType === 5"
              >
                <v-row
                  no-gutters
                  justify="start"
                >
                  <v-text-field
                    ref="numObjectCnt"
                    v-model.trim="objectCnt"
                    v-validate="'required|max:6|is_not:0'"
                    :error-messages="errors.collect('objectCnt')"
                    data-vv-name="objectCnt"
                    required
                    maxlength="6"
                    dense
                    outlined
                    filled
                    style="max-width: 80px;"
                    placeholder="예) 1"
                    @blur="objectCnt = strToNumber(objectCnt) === 0 ? '1' : objectCnt"
                    @keypress="preventComma"
                  ></v-text-field>
                  <v-chip
                    label
                    color="white"
                    class="text-caption error--text mt-1 ml-1"
                  >
                    <v-icon small left color="error">mdi-information</v-icon>
                      단독주택 - 토지 + 건물 = 2<br>아파트, 연립, 다세대 - 토지+건물 = 1
                  </v-chip>
                </v-row>
              </v-col>
              <v-col cols="12">
                <span class="text-body-2 primary--text">보험요율(%)</span>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <v-text-field
                    ref="numBohumPercent"
                    v-model.trim="bohumPercent"
                    v-validate="'required|max:8'"
                    :error-messages="errors.collect('bohumPercent')"
                    data-vv-name="bohumPercent"
                    required
                    maxlength="8"
                    dense
                    outlined
                    filled
                    style="max-width: 100px;"
                    placeholder="예) 0.302"
                  ></v-text-field>
                  <div
                    class="text-caption error--text mt-1 ml-1"
                  >
                    <div class="pl-2">
                      <v-icon small left color="error" class="ma-0 pa-0">mdi-information</v-icon>
                      일반법인, 개인 - 0.302%
                    </div>
                    <div class="pl-7">
                      상장기업 - 0.211%<br>
                      국가기관, 금융기관 - 0.151%<br>
                      [2012.04.02 시행]
                    </div>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
      <!-- <v-divider vertical class="ma-0 pa-0"></v-divider> -->
      <!-- 우측 컨텐츠 시작 -->
      <v-col class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-cube-send</v-icon> 계산결과
            <v-spacer></v-spacer>
            <v-btn
              small
              @click="doCopy"
              dark
            >
              <v-icon small>mdi-content-copy</v-icon>
              클립보드복사
            </v-btn>
          </v-toolbar>
          <v-card
            id="copyArea"
            tile
            outlined
            elevation="0"
            class="pa-2"
            min-height="568"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12">
                <div class="mt-2">
                  ● 신청방식: <span class="text-body-2 primary--text font-weight-bold">{{ rbChkTitle }}</span>
                </div>
                <div class="my-2">
                  ● 가압류대상: <span class="text-body-2 primary--text font-weight-bold">{{ abruyTitle }}</span>
                </div>
                <div class="my-2">
                  ⊙ 청구금액: <span class="text-body-2 primary--text font-weight-bold">{{ numberFormat(cRes.reqPrice) }}</span>원
                </div>

                <div class="mt-6 mb-2">
                  － 인지세: <span class="text-body-2 font-weight-bold">{{ numberFormat(cRes.injiPrice) }}</span>원
                </div>
                <div class="my-2">
                  － 송달료: <span class="text-body-2 font-weight-bold">{{ numberFormat(cRes.songdalPrice) }}</span>원
                </div>
                <div class="my-2">
                  － 등록면허세: <span class="text-body-2 font-weight-bold">{{ numberFormat(cRes.registPrice) }}</span>원
                </div>
                <div class="my-2">
                  － 지방교육세: <span class="text-body-2 font-weight-bold">{{ numberFormat(cRes.eduPrice) }}</span>원
                </div>
                <div class="my-2">
                  － 등기신청수수료: <span class="text-body-2 font-weight-bold">{{ numberFormat(cRes.susuPrice) }}</span>원
                </div>
                <div class="my-2">
                  － 공탁금: 현금 <span class="text-body-2 font-weight-bold">{{ numberFormat(cRes.gongtakPrice01) }}</span>원
                </div>
                <div class="pl-15">
                  보증보험 <span class="text-body-2 font-weight-bold">{{ numberFormat(cRes.gongtakPrice02) }}</span>원
                </div>

                <div class="mt-6 mb-2">
                  － 합계: 현금 <span class="text-body-2 error--text font-weight-bold">{{ numberFormat(cRes.totalPrice01) }}</span>원
                </div>
                <div class="pl-12">
                  보증보험 <span class="text-body-2 error--text font-weight-bold">{{ numberFormat(cRes.totalPrice02) }}</span>원
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import copy from 'copy-to-clipboard' // !! 클립보드 복사 플러그인
import ko from 'vee-validate/dist/locale/ko'

// 구분: filters
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import preventComma from '@/filters/preventComma' // keypress event 에서 쉼표막기
import strToNumber from '@/filters/strToNumber' // '10,000' => 를 10000 로 변환
import isNumberDot4 from '@/filters/isNumberDot4'

// ** 가압류 계산 항목
// 인지세 : 10000원 고정
// 송달료 : 송달횟수(3회 고정) * 당사자 총수 * 1회송달료(3550원 고정값, 이지만 입력값으로 받음)
// 공탁금1: 청구금액 * 가압류대상별 %
//        - 부동산: 0.1
//        - 유체동산: 0.8
//        - 채권: 0.4
//        - 자동차/선박  0.1
//        - 중기/건설기계: 0.1
// 공탁금2(보증보험료): 공탁금1 * 보험요율 (최저금액 : 15000워)
// 등록면허세 : 가압류대상별로 다름
//        - 부동산: 청구금액 * 0.002 (최저금액 : 3000원)
//        - 유체동산: 0
//        - 채권: 0
//        - 자동차/선박: 15000 * 갯수[목적물수] (최저금액 : 15000원)
//        - 중기/건설기계: 12000 * 갯수[목적물수] (최저금액 : 12000원)
// 지방교육세: 가압류대상별로 다름
//        - 부동산: 등록세 * 0.2
//        - 유체동산: 0
//        - 채권: 0
//        - 자동차/선박: 등록세 * 0.2
//        - 중기/건설기계: 등록세 * 0.2
// 등기신청수수료(기존 수입증지)
//        - 부동산: 갯수 * 3000
//        - 유체동산: 0
//        - 채권: 0
//        - 자동차/선박: 갯수[목적물수] * 3000
//        - 중기/건설기계: 갯수[목적물수] * 3000

// 중요: 초기값 - 고정값이고 년마다 변경될 수 있다!
// !! 1회송달료 (2018.8.13 4500에서 4700원으로 수정)
//            (2019.5.1 4800원으로 수정)
//            (2020.7.1 5100원으로 수정)
// !![2021.3.16] 정수형에서 문자열 숫자로 변경했다
// [2021.11.12] 5100 에에서  5200 로 변경
const _songdalFee = '5200'

const _requestPrice = 0 // 청구금액
const _dangCnt = 1 // 당사자수
const _injiFee = 10000 // 인지세(10000원 고정값)
const _songdalCnt = 3 // 송달횟수(가압류일때는 3이 고정값)
const _objectCnt = 1 // 갯수[목적물수](부동산,자동차,중기에서만 쓰임)
const _gongtak2Limit = 15000 // 공탁금2(보증보험료)의 최저값
const _bohumPercent = 0.302 // !! 보험요율(기본값은 법인,개인 요율 : 0.302%)

export default {
  components: {
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 신청방식
    rbChk: 1,
    rbChkOptions: [
      { text: '종이', value: 1 },
      { text: '전자', value: 2 }
    ],
    rbChkTitle: '종이',
    // 구분: 가압류 대상
    abruyType: 1,
    abruyTypeOptions: [
      { text: '부동산', value: 1 },
      { text: '동산', value: 2 },
      { text: '채권', value: 3 },
      { text: '자동차', value: 4 },
      { text: '중기', value: 5 }
    ],
    abruyTitle: '부동산',
    // 구분: 주요 입력변수
    reqPrice: '0', // 청구금액
    dangCnt: '1', // 당사자수
    objectCnt: '1', // 목적물
    songdalFee: '', // 1회 송달료
    bohumPercent: '0.302', // 보험요율
    // 구분:중요: 가압류 유형별 초기값 배열: 부동산/동산/채권/자동차/중기
    //   목적물 갯수: objectCnt, 공탁금1 요율 %: gongtakPercent,
    //   등록세 최저금액: regLimit, 교육세 요율 %: eduPercent, 등기신청수수료(기존 수입증지) 배수: susuFee
    aryAbruyType: {
      // 부동산
      1: {
        objectCnt: _objectCnt, gongtakPercent: 0.1, regLimit: 3000, eduPercent: 0.2, susuFee: 3000
      },
      // 동산
      2: {
        objectCnt: 0, gongtakPercent: 0.8, regLimit: 0, eduPercent: 0, susuFee: 0
      },
      // 채권
      3: {
        objectCnt: 0, gongtakPercent: 0.4, regLimit: 0, eduPercent: 0, susuFee: 0
      },
      // 자동차
      4: {
        objectCnt: _objectCnt, gongtakPercent: 0.1, regLimit: 15000, eduPercent: 0.2, susuFee: 3000
      },
      // 중기
      5: {
        objectCnt: _objectCnt, gongtakPercent: 0.1, regLimit: 12000, eduPercent: 0.2, susuFee: 3000
      }
    },
    // 구분: 계산결과를 담을 객체
    cRes: {
      reqPrice: 0, // 청구금액
      injiPrice: 0, // 계산된 인지세
      songdalPrice: 0, // 계산된 송달료
      registPrice: 0, // 계산된 등록면허세
      eduPrice: 0, // 계산된 지방교육세
      susuPrice: 0, // 계산된 등기신청수수료(수입증지)
      gongtakPrice01: 0, // 계산된 공탁금 1(현금)
      gongtakPrice02: 0, // 계산된 공탁금 2(보증보험)
      totalPrice01: 0, // 계산된 합계 1(현금)
      totalPrice02: 0 // 계산된 합계 2(보증보험)
    },
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        reqPrice: '청구금액',
        dangCnt: '당사자수',
        objectCnt: '목적물',
        songdalFee: '1회송달료',
        bohumPercent: '보험요율'
      }
    }
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    this.doInit()
  },

  watch: {
    // 구분: 청구금액 - !![2021.3.17] 수정
    'reqPrice': {
      handler: function (val, oldVal) {
        if (!val) {
          this.reqPrice = '0'
          this.$refs.numReqPrice.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.reqPrice = val.replace(/(^0+)/, '')
          }
          this.reqPrice = numberFormatR(this.reqPrice)
          this.reqPrice = numberFormat(this.reqPrice)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.reqPrice)) {
            this.reqPrice = oldVal
            this.$refs.numReqPrice.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 송달료 - !![2021.3.17] 수정
    'songdalFee': {
      handler: function (val, oldVal) {
        if (!val) {
          this.songdalFee = '0'
          this.$refs.numSongdalFee.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.songdalFee = val.replace(/(^0+)/, '')
          }
          this.songdalFee = numberFormatR(this.songdalFee)
          this.songdalFee = numberFormat(this.songdalFee)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.songdalFee)) {
            this.songdalFee = oldVal
            this.$refs.numSongdalFee.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 당사자수 !![2021.3.17] 수정
    'dangCnt': {
      handler: function (val, oldVal) {
        if (!val) {
          this.dangCnt = '0'
          this.$refs.numDangCnt.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.dangCnt = val.replace(/(^0+)/, '')
          }
          this.dangCnt = numberFormatR(this.dangCnt)
          this.dangCnt = numberFormat(this.dangCnt)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.dangCnt)) {
            this.dangCnt = oldVal
            this.$refs.numDangCnt.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 목적물 갯수 !![2021.3.17] 수정
    'objectCnt': {
      handler: function (val, oldVal) {
        if (!val) {
          this.objectCnt = '0'
          this.$refs.numObjectCnt.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.objectCnt = val.replace(/(^0+)/, '')
          }
          this.objectCnt = numberFormatR(this.objectCnt)
          this.objectCnt = numberFormat(this.objectCnt)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.objectCnt)) {
            this.objectCnt = oldVal
            this.$refs.numObjectCnt.lazyValue = oldVal
          }
        }
      }
    },
    'bohumPercent': { // !! 보험요율 - 소숫점 4자리까지 허용
      handler: function (val, oldVal) {
        if (val.length > 0) { // 이게 없으면 무한루프 걸릴 수 있다.
          if (isNumberDot4(val)) {
            this.bohumPercent = oldVal
            this.$refs.numBohumPercent.lazyValue = oldVal
          }
        }
      }
    }
  },

  methods: {
    numberFormat,
    numberFormatR,
    preventComma,
    strToNumber,
    isNumberDot4, // 소숫점 4자리까지 필터
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 구분: 신청방식 변경 이벤트 핸들러
    async rbChkChanged (e) {
      try {
        // 신청방식 타이틀 변수를 변경한다.
        this.rbChkTitle = this.rbChkOptions.find(r => r.value === this.rbChk).text
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 가압류대상 변경 이벤트 핸들러
    async abruyChanged (e) {
      try {
        // 가압류대상 타이틀 변수를 변경한다.
        this.abruyTitle = this.abruyTypeOptions.find(r => r.value === this.abruyType).text
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 초기화
    async doInit () {
      try {
        // !! form validation 초기화
        this.$validator.reset()

        this.rbChk = 1 // 종이
        this.rbChkTitle = '종이'

        this.abruyType = 1 // 부동산
        this.abruyTitle = '부동산'

        this.reqPrice = '0'
        this.dangCnt = '1'
        this.objectCnt = '1'

        this.songdalFee = _songdalFee // 1회 송달료
        this.bohumPercent = _bohumPercent // 보험요율

        this.cRes.reqPrice = 0
        this.cRes.injiPrice = 0
        this.cRes.songdalPrice = 0
        this.cRes.registPrice = 0
        this.cRes.eduPrice = 0
        this.cRes.susuPrice = 0
        this.cRes.gongtakPrice01 = 0
        this.cRes.gongtakPrice02 = 0
        this.cRes.totalPrice01 = 0
        this.cRes.totalPrice02 = 0
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 계산 실행
    async doCalc () {
      try {
        if (strToNumber(this.reqPrice) === 0) {
          this.reqPrice = '0'
        }

        if (strToNumber(this.dangCnt) === 0) {
          this.dangCnt = '1'
        }

        // 목적물은 필수값이 아니지만 없으면 자동으로 1을 넣어준다.
        if (!this.objectCnt || strToNumber(this.objectCnt) === 0) {
          this.objectCnt = '1'
        }

        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 중요: 선택된 압류유형으로 압류객체를 초기화한다.
        // 압류객체를 사용해 계산하면 된다
        const selAbruy = this.aryAbruyType[this.abruyType] // 선택된 압류유형
        let abru = {
          requestPrice: _requestPrice, // 청구금액
          dangCnt: _dangCnt, // 당사자수
          injiFee: _injiFee, // 인지세
          songdalCnt: _songdalCnt, // 송달횟수
          songdalFee: _songdalFee, // 1회 송달료
          bohumPercent: _bohumPercent, // 보험요율
          gongtak2Limit: _gongtak2Limit, // 공탁금 2 최저금액
          objectCnt: selAbruy.objectCnt, // 목적물 갯수
          gongtakPercent: selAbruy.gongtakPercent, // 공탁금 1 용율
          regLimit: selAbruy.regLimit, // 등록세 최저금액
          eduPercent: selAbruy.eduPercent, // 교육세 요율
          susuFee: selAbruy.susuFee // 등기신청수수료(기존 수입증지) 배수
        }

        // !! 입력받은 주요 변수를 압류객체에 매칭한다
        abru.requestPrice = strToNumber(this.reqPrice) // 청구금액
        abru.dangCnt = strToNumber(this.dangCnt) // 당사자수
        abru.objectCnt = strToNumber(this.objectCnt) // 목적물수
        abru.songdalFee = strToNumber(this.songdalFee) // 1회 송달료
        abru.bohumPercent = parseFloat(this.bohumPercent) // 보험요율

        // 중요: 가압류 계산 - 2015.12.03 - 계산 결과금액에서 소숫점이하는 뺀다.(버그) 차후 절상/절하 규칙을 알게되면 수정한다.
        // 소숫점 이하를 버리기 위해 Math.floor()를 이용한다.

        // 0) 청구금액: 등록된 값을 보여준다. 계산은 없다!
        this.cRes.reqPrice = abru.requestPrice

        // 1) 인지세 (신청방식이 전자인경우 10% 감면)
        this.cRes.injiPrice = (this.rbChk === 2) ? Math.floor(abru.injiFee * 0.9) : abru.injiFee

        // 2) 송달료 = 당사자수 * 송달횟수 * 1회송달료
        this.cRes.songdalPrice = Math.floor(abru.dangCnt * abru.songdalCnt * abru.songdalFee)

        // 3) 등록면허세,지방교육세,등기신청수수료(기존 수입증지) - 가압류대상에 따라 달라짐
        if (this.abruyType === 1) { // 부동산
          // 등록면허세 1 (부동산) : 청구금액 * 0.002 (최저금액(reg_limit) : 3000원)
          this.cRes.registPrice = Math.floor(abru.requestPrice * 0.002)
          if (this.cRes.registPrice < abru.regLimit) {
            this.cRes.registPrice = abru.regLimit
          }

          // 지방교육세 1 (부동산) : 등록세 * 0.2(부동산의 교육세 요율)
          this.cRes.eduPrice = Math.floor(this.cRes.registPrice * abru.eduPercent)

          // 등기신청수수료(기존 수입증지) 1 (부동산) : 갯수 * 3000
          this.cRes.susuPrice = Math.floor(abru.objectCnt * abru.susuFee)
          //
        } else if (this.abruyType === 2 || this.abruyType === 3) {
          // 등록면허세 2,3 (동산/채권) : 0
          this.cRes.registPrice = abru.regLimit

          // 지방교육세 2,3 (동산/채권) : 0
          this.cRes.eduPrice = abru.eduPercent

          // 등기신청수수료(기존 수입증지) 2,3 (동산/채권) : 0
          this.cRes.susuPrice = abru.susuFee
          //
        } else if (this.abruyType === 4 || this.abruyType === 5) {
          // 등록면허세 4,5 (자동차/중기) : 15000/12000(reg_limit) * 갯수
          this.cRes.registPrice = Math.floor(abru.regLimit * abru.objectCnt)

          // 지방교육세 4,5 (자동차/중기) : 등록세 * 0.2
          this.cRes.eduPrice = Math.floor(this.cRes.registPrice * abru.eduPercent)

          // 등기신청수수료(기존 수입증지) 4,5 (자동차/중기) : 갯수 * 3000
          this.cRes.susuPrice = Math.floor(abru.objectCnt * abru.susuFee)
          //
        }
        // 계산된 공탁금 1(현금) : 청구금액 * 적용 요율
        this.cRes.gongtakPrice01 = Math.floor(abru.requestPrice * abru.gongtakPercent)

        // 계산된 공탁금 2(보증보험) : 공탁금 1 * 적용요율
        this.cRes.gongtakPrice02 = Math.floor(this.cRes.gongtakPrice01 * (abru.bohumPercent / 100))
        if (this.cRes.gongtakPrice02 < abru.gongtak2Limit) {
          this.cRes.gongtakPrice02 = abru.gongtak2Limit
        }

        // 계산된 합계 1(현금): 인지세 + 송달료 + 등록세 + 지방교육세 + 수수료 + 공탁금 1(현금)
        this.cRes.totalPrice01 = this.cRes.injiPrice + this.cRes.songdalPrice + this.cRes.registPrice + this.cRes.eduPrice + this.cRes.susuPrice + this.cRes.gongtakPrice01

        // 계산된 합계 2(보증보험): 인지세 + 송달료 + 등록세 + 지방교육세 + 수수료 + 공탁금 2(보증보험)
        this.cRes.totalPrice02 = this.cRes.injiPrice + this.cRes.songdalPrice + this.cRes.registPrice + this.cRes.eduPrice + this.cRes.susuPrice + this.cRes.gongtakPrice02
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 클립보드에 복사하기
    // 주의: async 를 붙여선 안된다. 매뉴얼 참고
    async doCopy () {
      try {
        const elem = document.querySelector('#copyArea')
        if (!elem) throw new Error(`요청하는 엘리먼트가 없습니다.`)

        const headerTitle = '▣ 가압류신청비용\n'
        await copy(`${headerTitle}${elem.innerText}`)
        this.$store.commit('SB_POP', { msg: '클립보드에 복사되었습니다.', color: 'success' })
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
