<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    @keydown.esc="cancel"
  >

    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">상신</v-btn>

      <v-btn
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="dummy">임시저장</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>

            <!-- 좌측 컨텐츠 시작 --->
            <v-col
              cols="12"
              md="7"
              lg="7"
              xl="7"
            >
              좌좌좌
            </v-col>

            <!-- 가운데 세로 디바이더 -->
            <v-divider vertical class="ma-0 pa-0"></v-divider>

            <!-- 우측 컨텐츠 시작 --->
            <v-col>
              우우우
            </v-col>

          </v-row>

        </v-container><!-- 상단 타이틀 고정을 풀때 사용 -->
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

export default {
  components: {
    baseModal
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    // 구분: Form 관련
    form: {
      id: 0
    },
    // 구분: 수정 인덱스
    index: -1,
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        name: '상호'
      }
    }
  }),

  created () {},

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.form.id = 0

      this.index = -1 // 수정용 인덱스 초기화
    },
    async open (title, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title

        // 초기화
        await this.initDialog()

        // 참고: 수정 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          this.index = index

          this.form.id = obj.id
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 등록
    async agree () {
      try {
        // 참고: 입력값 검증
        // let isValidate = await this.$validator.validateAll()
        // if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // let resultId = 0 // !! 돌려줄 적용 아이디 - view 페이지로 넘기기 위해 반드시 필요!

        // // !! DB 입력 처리 (등록/수정 동일하게 처리)
        // const { data } = await this.$axios.post(`lawork/lwc/setShopContent`, this.form)
        // if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        // resultId = data.resultId

        // // 등록/수정 후
        // await this.initDialog()
        // this.resolve(resultId) // !! 결과로 돌려받은 id값이 중요!
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        await this.initDialog() // 초기화
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    }
  },
  props: {
    source: String
  }
}
</script>
