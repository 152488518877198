<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{ zIndex: options.zIndex }"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>
      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container class="pb-0 mb-0">
          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" xs="12">
              <v-row
                no-gutters
                align="center"
                justify="start"
              >
                <div style="width:220px">
                  <v-text-field
                    ref="txtMgroup"
                    v-model.trim="form.mgroup"
                    label="관리그룹 등록"
                    v-validate="'required|max:7'"
                    :error-messages="errors.collect('mgroup')"
                    data-vv-name="name"
                    required
                    placeholder="입력하세요(최대 7자)"
                    hint="중복체크 필수"
                    counter="7"
                    maxlength="7"
                    @keypress="rmSpKeys"
                    @keyup="chkOverlapReset"
                    :error="isOverlabed"
                  ></v-text-field>
                </div>
                <v-btn small text class="ma-2"
                  :color="mustChk ? 'error' : 'indigo'"
                  @click="chkOverlap"
                >
                  <v-icon small class="text-left">
                    {{ chkMulti ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                  </v-icon>
                  중복체크
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12">
              <div class="mb-8">
              </div>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import { rmSpKeys, rmSpChars } from '@/lib/keyEvents'

// filters
import isSpKey from '@/filters/isSpecialCharacter'

export default {
  components: {
    baseModal
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 400,
      zIndex: 300
    },
    // 구분: 넘어온 그룹정보
    group: null,
    // 구분: 폼
    form: {
      pId: 0, // 부모(카테고리) 아이디
      mgroup: '', // 추가할 카테고리 명
      sub: '' // 업데이트 할 서브 카테고리 문자열
    },
    // 구분: 부모 카테고리의 서브 카테고리
    subCates: [],
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        mgroup: '관리그룹'
      }
    },
    // 구분: 중복체크
    mustChk: false, // 중복체크를 해야 하는지 여부
    chkMulti: false, // 참고: 이름 중복 확인했는지 여부
    isOverlabed: false // 참고: 이름 중복 되었는지 여부
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  watch: {
    'form.mgroup': { // !! 중복체크 로직의 핵심임
      handler: function (val, oldVal) {
        if (val.length > 0) { // 값이 있으면
          if (isSpKey(val)) { // 특수문자 필터링
            this.form.mgroup = oldVal
            this.$refs.txtMgroup.lazyValue = oldVal
          }

          this.mustChk = true // 중복체크 해야 함
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false // 중복 아니다.
        } else { // 내용이 아예 없으면 중복 체크하지 않아도 됨
          this.mustChk = false // 중복체크 안해도 됨
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false // 중복 아니다.
        }
      }
    }
  },

  methods: {
    // 중요: 공통함수 --
    rmSpKeys, // keypress 특수문자 입력 막기
    rmSpChars, // keyup, down 특수문자 제거
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    async initDialog () {
      // 대신 form validation 초기화
      this.$validator.reset()
      // form 초기화
      this.form.pId = 0
      this.form.mgroup = ''
      this.form.sub = ''

      // 중복체크 초기화
      this.mustChk = false
      this.chkMulti = false
      this.isOverlabed = false
    },
    // !! 열기: 타이틀, 부모아이디가 넘어온다
    async open (title, pId, options) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 초기화
        await this.initDialog()

        // 중요: 넘어온 부모 카테고리(관리그룹) 아이디
        this.form.pId = pId

        // 해당 아이디의 관리그룹의 서브 카테고리를 가져오자
        this.subCates = await this.getSubCates(pId)

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 등록
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) {
          this.$refs.txtMgroup.focus()
          throw new Error('입력값을 확인해 주세요')
        }

        // !! 중복체크 확인
        if (this.mustChk && !this.chkMulti) { // 중복체크를 해야 하는데 하지 않았다
          this.isOverlabed = true // 중복여부를 가져오진 않았지만 수동으로 에러를 만든다.
          this.$refs.txtMgroup.focus()
          throw new Error('중복체크를 하셔야 합니다.')
        }

        // 고쳐: DB 처리 - 관리그룹 서브카테고리 등록
        this.subCates.push(this.form.mgroup)
        this.form.sub = this.subCates.join('|')
        const { data } = await this.$axios.post('admin/cate/addSubCate', this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        // 등록 후 부모창에 보낼 등록값
        const returnValue = this.form.mgroup

        // 초기화
        await this.initDialog()
        // this.resolve(true)
        this.resolve(returnValue)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        // 초기화
        await this.initDialog()
        // this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.resolve('')
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 중복체크 함수
    async chkOverlap () {
      try {
        if (this.form.mgroup.length > 0) {
          if (this.subCates.includes(this.form.mgroup)) { // 중복 되었다
            this.mustChk = true // 중복체크 해야 한다.
            this.chkMulti = false // 중복체크 안함
            this.isOverlabed = true // 중복되었다
            this.$refs.txtMgroup.focus()
            throw new Error(`이미 등록된 관리그룹입니다.`)
          } else {
            this.mustChk = false
            this.chkMulti = true
            this.isOverlabed = false
            this.$store.commit('SB_POP', { msg: '등록할 수 있는 관리그룹입니다.', color: 'success' })
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! [util 함수] 부모 카테고리 아이디로 서브카테고리를 가져온다
    async getSubCates (pId) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getSubCates/${pId}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return data.subCates // subCates 의 배열을 리턴한다
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 이름 텍스트박스의 keyup 이벤트 - 중복체크 리셋
    async chkOverlapReset (e) {
      // 체크했는지 여부와 중복여부를 리셋시킨다.
      this.chkMulti = false
      this.isOverlabed = false

      // 특수문자 체크를 하자
      this.form.mgroup = rmSpChars(this.form.mgroup)
    }
  }
}
</script>
