<template>
  <v-menu
    v-model="showMenu"
    :close-on-click="false"
    :close-on-content-click="false"
    :nudge-width="options.width"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
    origin="center center"
    transition="scale-transition"
  >
    <v-card
      elevation="0"
      width="620"
      max-height="650"
      min-height="650"
      class="px-0 py-0"
    >
      <v-toolbar
        light
        color="yellow lighten-2"
        style="cursor: grab;"
      >
        <v-icon class="hidden-xs-only mr-2" color="orange darken-3">mdi-alarm-light</v-icon>
        <v-toolbar-title class="hidden-xs-only">할일목록 ({{ doneTodosCount }}/{{ todoLength }})</v-toolbar-title>
        <v-spacer class="hidden-xs-only"></v-spacer>
        <v-btn
          icon
          @click.stop="closeTodo"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- !! 등록 -->
      <v-hover v-slot="{ hover }">
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                ref="txtComment"
                v-model.trim="form.comment"
                :rules="[rules.required, rules.limitComment]"
                label=""
                :maxlength="commentLimit"
                :counter="commentLimit"
                required
                dense
                placeholder="할일을 입력하세요"
                @keyup.enter="agree"
              >
                <v-icon
                  slot="prepend"
                  :color="hover ? 'orange darken-2' : ''"
                  class="hidden-xs-only"
                >mdi-comma</v-icon>
              </v-text-field>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon small class="ml-2 pl-2"
                :color="hover ? 'primary' : 'grey'"
                @click.stop="agree"
              >mdi-pencil</v-icon>
            </v-list-item-action>
            <v-list-item-action>
              <v-icon small class="pr-1"
                :color="hover ? 'error' : 'grey'"
                @click.stop="cancel"
              >mdi-close-thick</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-hover>

      <v-divider></v-divider>

      <v-card
        elevation="0"
        min-height="70"
        max-height="480"
        class="overflow-y-auto pa-4"
        v-click-outside="onClickOutside"
      >
        <draggable
          handle=".handle"
          :list="todos"
          :disabled="!dndEnabled"
          @start="dragging = true"
          @end="dragging = false"
          @update="orderChanged = true"
          @change="changeRows"
        >
          <v-row
            v-for="todo in todos"
            :key="todo.id"
            no-gutters
            align="center"
            justify="start"
            class="my-0"
            @click.stop="clickListContent(todo)"
          >
            <v-col class="py-1 px-0">
              <v-hover v-slot="{ hover }">
                <v-row no-gutters>
                  <div v-if="!todo.edit"
                    class="d-block"
                  >
                    <v-row no-gutters>
                      <v-col class="d-flex align-center py-2">
                        <div>
                          <v-icon small class="mr-1 pb-1"
                            :color="todo.done ? 'grey' : ''"
                            @click.stop="chkDone(todo)"
                          >
                          {{ todo.done ? 'mdi-checkbox-marked-circle-outline' : 'mdi-checkbox-blank-circle-outline' }}
                          </v-icon>
                        </div>
                        <div
                          class="text-body-1"
                          :class="todo.done ? 'grey--text' : 'text--primary'"
                          :style="hover ? 'cursor: pointer;' : ''"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <span v-on="on">{{ todo.text }}</span>
                            </template>
                            <span>{{ strDateFormat2(todo.updatedAt) }}</span>
                          </v-tooltip>
                        </div>
                        <div>
                          <v-icon
                            v-show="hover"
                            small class="handle ml-2" color="grey lighten-1"
                            style="cursor: move;"
                          >mdi-format-line-spacing</v-icon>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else>
                    <v-row no-gutters>
                      <v-icon small class="mr-1 pb-1"
                        @click.stop="chkDone(todo)"
                      >
                      {{ todo.done ? 'mdi-checkbox-marked-circle-outline' : 'mdi-checkbox-blank-circle-outline' }}
                      </v-icon>
                      <v-text-field
                        :ref="`editTxt-${todo.id}`"
                        v-model.trim="todo.text"
                        :rules="[rules.required, rules.limitComment]"
                        label=""
                        :maxlength="commentLimit"
                        @keyup.enter="editTodo(todo)"
                        dense
                        required
                        class="mt-1"
                        style="width: 465px;height: 36px;"
                      ></v-text-field>
                      <v-icon
                        small color="primary" class="ml-1 px-1"
                        @click.stop="editTodo(todo)"
                      >mdi-pencil</v-icon>
                      <v-icon
                        small color="error" class="mx-1 px-1"
                        @click.stop="delTodo(todo)"
                      >mdi-trash-can-outline</v-icon>
                      <v-spacer></v-spacer>
                      <v-icon
                        v-show="hover"
                        small class="handle mr-2" color="grey lighten-1"
                        style="cursor: move;"
                      >mdi-format-line-spacing</v-icon>
                    </v-row>
                  </div>
                </v-row>
              </v-hover>
              <!-- !! 리스트에 줄을 치려면 아래 주석을 풀어 -->
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </draggable>
      </v-card>

    </v-card>
  </v-menu>
</template>

<script>
import draggable from 'vuedraggable'

// filters
import strDateFormat2 from '@/filters/strDateFormat2'

import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('todo')

export default {
  components: {
    draggable
  },

  data: () => ({
    resolve: null, // 이 방법이 너무 참신하다!
    reject: null,
    options: {
      color: 'primary',
      width: 300
    },
    showMenu: false,
    x: 0,
    y: 0,
    // 구분: 폼
    form: {
      pId: 0,
      comment: ''
    },
    // 구분: 글자수 제한
    commentLimit: 100,
    // 구분: 드래그앤드랍 관련
    dndEnabled: true, // dnd 사용여부
    dragging: false,
    orderChanged: false, // 순서변경여부
    // 구분: 수정창을 닫을수 있는지 여부
    closeEidt: true
  }),

  computed: {
    ...mapState([
      'todos'
    ]),
    ...mapGetters([
      'todoLength',
      'getAllTodos',
      'doneTodosCount'
    ]),
    // data 에서는 this를 쓸 수 없으므로 computed 에서
    rules () {
      return {
        required: value => !!value || '입력값은 필수입니다',
        limitComment: value => value.length <= this.commentLimit || `글자수는 ${this.commentLimit}자 내외로 해야 합니다`
      }
    }
  },

  watch: {
    'form.comment': {
      handler: function (val, oldVal) {
        // 한글 글자수 제한을 넘지 못하게 깔끔하게 막는 방법
        if (val.length > this.commentLimit) {
          this.form.comment = oldVal
          this.$refs.txtComment.lazyValue = oldVal
        }
      }
    }
  },

  methods: {
    ...mapMutations([
      'orderPlusOne',
      'addTodo',
      'deleteTodo'
    ]),
    ...mapActions({
      plusOne: 'orderPlusOne',
      addList: 'addTodo',
      todoEdit: 'editTodo',
      changeDone: 'chkDone',
      rmTodo: 'removeTodo',
      setOrder: 'setOrder'
    }),
    strDateFormat2,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (const key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async init () {
      try {
        this.$refs.txtComment.resetValidation()
        this.form.pId = 0
        this.form.comment = ''

        this.dndEnabled = true // 드래그 앤 드랍 사용가능하게
        this.orderChanged = false // 순서변경여부 초기화
        this.dragging = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 메뉴 팝업을 띄우는 함수
    async show (evt) {
      try {
        if (!this.showMenu) { // !! 안전장치 - 이미 열려있으면 다시 열리지 않게 한다.
          evt.preventDefault()
          this.showMenu = false
          this.x = evt.clientX + 20
          this.y = evt.clientY - 20 // + 20

          this.$nextTick(() => {
            this.showMenu = true
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    async agree () {
      try {
        if (!this.$refs.txtComment.validate()) {
          this.$refs.txtComment.focus() // 자연스럽게 보이기 위해 포커싱
          throw new Error('입력값을 확인하세요.')
        }

        // !! 비어있는 리스트가 있다면 등록을 못하게 막는다
        if (this.chkIsEmptyTodo()) {
          throw new Error('목록을 확인하세요.')
        }

        // todos 배열중 id 의 최대값 찾기
        let max = 0
        if (this.todos.length) { // list 있는 경우 없으면 max=0
          const ids = this.todos.map(item => item.id) // id 의 배열만 뽑고
          max = Math.max(...ids) // 최대값 찾기
        }

        // 생성일, 최종업데이트 일
        const dt = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        const article = {
          id: 0, order: 0, done: false, edit: false, text: '', wemail: 'rhduddnr@gmail.com', wname: '고영발', createdAt: dt, updatedAt: dt
        }
        article.id = max + 1
        article.text = this.form.comment

        // 순서인 order 는 등록시 1로 하고, 나머지 리스트는 +1 해줘야 한다.
        article.order = 1

        // !![vuex action] 나머지의 order +1 과 등록을 동시에 처리한다.
        await this.addList(article)

        await this.init()
        this.$refs.txtComment.blur()
      } catch (e) {
        this.sbpop(e)
      }
    },
    async cancel () {
      try {
        this.showMenu = true
        await this.init()
        this.$refs.txtComment.blur()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 순서를 변경하는 이벤트에 발동된다..
    async changeRows () {
      try {
        // !! vuex actions 로 재지정
        await this.setOrder()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 수정 작업
    async editTodo (todo) {
      try {
        if (todo.text) { // 내용이 있으면 편집창 닫고
          // 최종업데이트 일 갱신
          todo.updatedAt = this.$moment().format('YYYY-MM-DD HH:mm:ss')

          // !! vuex actions 이용
          this.todoEdit(todo)

          this.closeEidt = true
          this.onClickOutside()
        } else { // 없으면 열려둔채 놔둔다.
          // !! 아래 변수를 false 로 해서 닫지 못하게 막는다.
          this.closeEidt = false

          // !! 클릭하면 포커싱주기 약간의 지연이 있으니 this.$nextTick() 필수사용
          this.$nextTick(() => {
            this.$refs[`editTxt-${todo.id}`][0].focus()
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 삭제 작업
    // https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Array/splice 참고
    async delTodo (todo) {
      try {
        // !![vuex actions] - 삭제와 순서재지정이 동시에 된다.
        this.rmTodo(todo)

        this.closeEidt = true
        this.onClickOutside()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 한일로 체크하거나 풀었을때 처리하는 함수
    // 체크하면 맨밑으로 풀면 맨위로 이동한다
    async chkDone (todo) {
      todo.done = !todo.done

      // 최종업데이트 일 갱신
      todo.updatedAt = this.$moment().format('YYYY-MM-DD HH:mm:ss')

      // !![vuex actions] - 체크처리와 순서재지정을 같이 처리한다.
      await this.changeDone(todo)
    },
    // 구분: 비어있는 목록이 있는지 체크
    chkIsEmptyTodo () {
      return this.todos.filter(t => t.text === '').length
    },
    // 구분: 리스트를 감싸는 <v-card> 외곽을 클릭하면 모든 에디트 창이 닫히도록
    onClickOutside () {
      // 등록창에 에러가 났으면 꺼버린다
      this.$refs.txtComment.resetValidation()

      // !! 비어있는 리스트가 있다면 편집을 못하게 막는다
      if (this.chkIsEmptyTodo()) {
        this.closeEidt = false
      }

      if (this.closeEidt) { // 닫을 수 있어야 닫는다.
        this.todos.map(t => { t.edit = false })
      }
    },
    // 구분: 리스트 클릭시 선택리스트만 에디트창으로 변경
    async clickListContent (todo) {
      try {
        // 등록창에 에러가 났으면 꺼버린다
        this.$refs.txtComment.resetValidation()

        // !! 비어있는 리스트가 있다면 편집을 못하게 막는다
        if (this.chkIsEmptyTodo()) {
          this.closeEidt = false
        }

        if (this.closeEidt) {
          this.onClickOutside() // 모든 리스트 닫기
          todo.edit = true // 선택 리스트만 에디트로 변경

          // !! 클릭하면 포커싱주기 약간의 지연이 있으니 this.$nextTick() 필수사용
          this.$nextTick(() => {
            this.$refs[`editTxt-${todo.id}`][0].focus()
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 닫기
    // !! 닫으면서 DB 저장
    async closeTodo () {
      try {
        // !! 모든 할일목록을 백앤드로 전송하여 DB에 저장
        const { data } = await this.$axios.post('lawork/member/setTodo', { todos: this.getAllTodos })
        if (!data.success) throw new Error('할일목록 저장에 실패하였습니다.')

        this.$nextTick(() => {
          this.showMenu = false // 팝업닫기
        })
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/* 안먹는데? */
.list__todo .v-input__control .v-input__slot .v-text-field__slot input[type="text"] {
  border-width: 0px !important;
  border-color: white !important;
}
</style>
