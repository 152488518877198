<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <!-- <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn> -->

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0">

          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" xs="12">
              <v-text-field
                ref="txtSubject"
                v-model.trim="form.subject"
                v-validate="'required|max:50'"
                :error-messages="errors.collect('subject')"
                data-vv-name="subject"
                required
                placeholder="입력하세요"
                :maxlength="subjectLimit"
                label="제목"
                class="pa-0 my-3"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <!-- vue2editor -->
              <vue-editor
                v-model="form.question"
                :editor-toolbar="customToolbar"
                style="height:400px;"
                class="mb-12"
              ></vue-editor>
            </v-col>
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

    </baseModal>
  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'

// !! vue2editor
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    baseModal,
    VueEditor
  },

  props: ['article'],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 800,
      height: 600,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0, // 아이디
      email: '',
      dbcode: '',
      name: '',
      office: '',
      subject: '',
      question: '', // 문의내용
      uId: 0, // user id
      oId: 0, // office id
      d1: '',
      d2: ''
    },
    dictionary: {
      messages: ko.messages,
      attributes: {
        subject: '제목'
      }
    },
    index: -1, // !! 수정 인덱스
    // 구분: 에디터 용
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }, { background: [] }],
      ['link']
    ],
    // 구분: 제목 글자수 제한
    subjectLimit: 50
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  watch: {
    'form.subject': {
      handler: function (val, oldVal) {
        // 글자수 제한을 넘지 못하게 깔끔하게 막는 방법
        if (val.length > this.subjectLimit) {
          this.form.subject = oldVal
          this.$refs.txtSubject.lazyValue = oldVal
        }
      }
    }
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (const key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화 함수
    async initDialog () {
      // form validation 초기화
      this.$validator.reset()

      this.form.id = 0

      // console.log(this.$store.state.ui)
      // !! email, dbcode, name, office 는 로그인 유저의 this.$store.state.ui 에서 가져온다.
      this.form.email = this.$store.state.ui.email
      this.form.dbcode = this.$store.state.ui.dbcode
      this.form.name = this.$store.state.ui.name
      this.form.office = this.$store.state.ui.officeName

      // !! user id
      this.form.uId = this.$store.state.ui.id
      this.form.d1 = `/user/${this.$store.state.ui.id}`
      // !! lawork id
      this.form.oId = this.$store.state.ui.oId
      this.form.d2 = `/lawork/${this.$store.state.ui.oId}`

      this.form.subject = ''
      this.form.question = ''

      this.index = -1 // !! 수정용 인덱스 초기화
    },
    async open (title, options, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        await this.initDialog()
        // console.log(this.form)

        // !! 편집 모드인 경우
        if (index > -1) {
          this.index = index

          this.form.id = obj.id
          this.form.subject = obj.subject
          this.form.question = obj.question
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.ddㅁㅁ
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 등록 버튼
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        if (!this.form.question) {
          throw new Error('내용을 입력하세요')
        }

        const { data } = await this.$axios.post('lawork/member/setQuestion', this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        await this.initDialog()
        this.resolve(data.rId) // !! data.rId 를 리턴한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소 버튼
    async cancel () {
      try {
        await this.initDialog()
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style scoped>
/* .quillWrapper{
  height: 200px;
  max-height: 200px;
} */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
