<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{ zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">닫기</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
        class="ma-0 pa-0"
      >
        <v-container fluid class="ma-0 pa-0">

          <v-row
            align="center"
            justify="center"
            no-gutters
            class="ma-0 pa-0"
          >
            <v-col cols="12">

              <!-- content area -->
              <v-row
                justify="space-between"
                class="ma-0 pa-0"
              >
                <!-- <v-col cols="2">
                  <v-card
                    tile
                    elevation="0"
                    class="ma-0 pa-0 overflow-y-auto"
                    :height="options.height"
                  >
                    <v-list-item-group v-model="menu" color="primary">
                      <v-list dense shaped>
                        <template v-for="menu in menus">
                          <v-list-item
                            :key="menu.title"
                            color="primary"
                            @click.stop="swapComponent(menu)"
                          >
                            <v-list-item-content>
                              <v-list-item-title>{{ menu.title }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-list-item-group>
                  </v-card>
                </v-col>

                <v-divider vertical></v-divider> -->

                <v-col class="ma-0 pa-0">
                  <v-toolbar dense flat>
                    <v-icon color="primary">mdi-calculator</v-icon>
                    <!-- <span class="font-weight-bold text-subtitle-1">{{ calcTitle }}</span> -->
                    <v-menu
                      rounded="b-xl"
                      offset-y
                      open-on-hover
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          text
                          v-bind="attrs"
                          v-on="on"
                          class="font-weight-bold text-subtitle-1"
                        >
                          {{ calcTitle }}
                          <v-icon small right>mdi-chevron-down</v-icon>
                          <!-- <v-icon small right>mdi-menu-down</v-icon> -->
                        </v-btn>
                      </template>
                      <v-list
                        dense
                        shaped
                      >
                        <v-list-item
                          v-for="menu in menus"
                          :key="menu.title"
                          @click="swapComponent(menu)"
                        >
                          <v-list-item-title>{{ menu.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      small
                      @click="pdfgen"
                    >
                      <v-icon small>mdi-download</v-icon>
                      저장
                    </v-btn>
                    <v-btn
                      text
                      small
                      @click="goGmailToWrite"
                    >
                      <v-icon small>mdi-gmail</v-icon>
                      G메일
                    </v-btn>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-container id="printArea" class="pa-0">
                    <component ref="refComponent" :is="currentComponent"></component>
                  </v-container>
                </v-col>
              </v-row>
              <!--/content area -->

            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

      <!-- <calc01 ref="refCalc01"></calc01>
      <calc02 ref="refCalc02"></calc02>
      <calc03 ref="refCalc03"></calc03>
      <calc04 ref="refCalc04"></calc04>
      <calc05 ref="refCalc05"></calc05>
      <calc06 ref="refCalc06"></calc06>
      <calc07 ref="refCalc07"></calc07> -->
      <!-- !! overlay -->
      <v-overlay :value="overlay" opacity="0.25">
        <v-progress-circular indeterminate size="70" color="primary"></v-progress-circular>
      </v-overlay>

    </baseModal>
  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import calc01 from '@/components/calc/calc01'
import calc02 from '@/components/calc/calc02'
import calc03 from '@/components/calc/calc03'
import calc04 from '@/components/calc/calc04'
import calc05 from '@/components/calc/calc05'
import calc06 from '@/components/calc/calc06'
import calc07 from '@/components/calc/calc07'
import calcEtc from '@/components/calc/calcEtc' // 기타(링크)

// @: pdfmake view - data 형에서 쓰인다!
import {
  pdfMake, // 이미 위에서 선언했으니 삭제
  pdfViewStyle,
  viewType4
} from '@/lib/pdfmakeView'

// @: sleep
import sleep from '@/lib/sleep'

export default {
  components: {
    baseModal,
    calc01,
    calc02,
    calc03,
    calc04,
    calc05,
    calc06,
    calc07,
    calcEtc
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 980,
      height: 580,
      zIndex: 200
    },
    // 구분: 실무계산기 제목
    calcTitle: '',
    // 구분: 메뉴와 컴포넌트
    currentComponent: null, // 'calc01', // 초기 컴포넌트
    menu: 0,
    menus: [
      { title: '인지액.송달료', compo: 'calc01' },
      { title: '일반기간', compo: 'calc02' },
      { title: '상소기간', compo: 'calc03' },
      { title: '이자계산', compo: 'calc04' },
      { title: '가압류신청비용', compo: 'calc05' },
      { title: '부동산경매신청예납비용', compo: 'calc06' },
      { title: '변호사보수산정', compo: 'calc07' },
      { title: '기타', compo: 'calcEtc' }
    ],
    // 구분: 오버레이용 변수
    overlay: false,
    // 구분: pdf
    pdfDoc: {
      styles: null,
      defaultStyle: null,
      // pageOrientation: 'landscape', // !! 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 10, 20, 10, 20 ],
      content: []
    }
  }),

  mounted () {
    // 마운트 후에 첫 페이지 지정
    this.swapComponent(this.menus[0])
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 초기화 - 단순히 오버레이를 닫는 역할뿐
    async initDialog () {
      // this.menu = 0
      // this.currentComponent = 'calc01'

      this.overlay = false
    },
    // 구분: 팝업 열기
    async open (title, options) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 초기화
        await this.initDialog()

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 닫기 버튼 이벤트 핸들러
    // * [2022.9.14] 계산영역의 값을 초기화 하지 않고 닫은 후 다시 열면 그전 계산결과가 그대로 남아있는 현상을 수정함.
    async cancel () {
      try {
        // ![2022.9.14] 모든 계산 영역 component 에는 doInit() 함수가 있다. 이 함수를 호출하여 초기화 한다.
        this.$refs.refComponent.doInit()

        await this.initDialog() // 메인 팝업의 함수를 호출한다.
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 컴포넌트 교체 함수
    swapComponent (component) {
      try {
        this.currentComponent = component.compo
        this.calcTitle = component.title
        if (!this.currentComponent) throw new Error('지정된 콤포넌트를 찾을 수 없습니다.')
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 지메일 쓰기로 이동
    async goGmailToWrite () {
      try {
        // const gmailUrl = `https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=new` // [2021.8.5] 느려서 주석처리
        const gmailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=` // 쓰기로 바로간다.
        const link = document.createElement('a')
        link.href = gmailUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 생성 함수2 - 데이터 형
    async pdfgen () {
      try {
        const elem = document.querySelector('#copyArea')
        if (!elem) throw new Error(`요청하는 엘리먼트가 없습니다.`)

        this.overlay = true

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfDoc.info = {
          title: `실무계산기 > ${this.calcTitle}`,
          subject: `실무계산기 > ${this.calcTitle}`
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfDoc.styles = pdfViewStyle // 중요: 일정은 리스트와 뷰 스타일 모두 사용
        this.pdfDoc.defaultStyle = { font: 'Nanum' }

        // 중요: 세로로
        this.pdfDoc.pageOrientation = 'portrait'

        // @: 헤더 타이틀
        const pdfHeaderTitle = `실무계산기 > ${this.calcTitle}`

        // @: 작성일
        const workDate = `작성: ${this.$moment().format('YYYY.MM.DD HH:mm')}`

        const calcContent = [] // 중요: 내용을 담을 배열

        let text = elem.innerText // 결과 문자열!
        let border = [false, false, false, false]

        calcContent.push([ { text, border } ]) // 내용을 배열에 담는다!

        // !! set content data
        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: workDate, style: 'subheader2' },
          viewType4(calcContent)
        ]
        this.pdfDoc.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfDoc).open()

        // shut off overlay
        sleep(350).then(() => { this.overlay = false })
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
