<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        v-if="isSupplyAuth || isEditable"
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index > -1 && isRemoveable"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">{{ isSupplyAuth ? '취소' : '확인' }}</v-btn>

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>

            <v-col cols="12" xs="12">
              <!-- !! 등록인 경우는 '신청'으로 픽스된다 -->
              <template v-if="index === -1">
                <v-text-field
                  value="신청"
                  label="관리상태"
                  readonly
                ></v-text-field>
              </template>
              <!-- !! 수정인 경우는 신청자 or 물품관리 권한자로 나뉜다 -->
              <template v-else>
                <!-- !! 물품관리 권한자인 경우(신청인이어도 상관없다 우선적으로 따진다) > 관리상태 셀렉트 선택가능 -->
                <v-select
                  v-if="isSupplyAuth === true"
                  v-model="form.status"
                  :items="status"
                  label="관리상태"
                  dense
                  menu-props="auto"
                  class="mt-4"
                  style="font-size: 0.785rem !important"
                ></v-select>
                <!-- !! 권한자가 아닌경우(신청인이던 뭐던) > 셀렉트는 안나온다 -->
                <v-text-field
                  v-else
                  :value="`${requestBuyStatus(form.status)}`"
                  label="관리상태"
                  readonly
                ></v-text-field>
              </template>
            </v-col>
            <!-- !! 신청일은 readonly -->
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                v-model="form.date1"
                label="신청일"
                readonly
                class="mr-1"
              ></v-text-field>
            </v-col>
            <!-- !! 신청자도 readonly -->
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                v-model="form.str2"
                label="신청자"
                readonly
                class="ml-1"
              ></v-text-field>
            </v-col>
            <!-- !! 신청자 본인만 수정가능 -->
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.str3"
                v-validate="'required|max:30'"
                maxlength="30"
                :error-messages="errors.collect('str3')"
                data-vv-name="str3"
                :counter="30"
                required
                label="물품*"
                placeholder="입력하세요(필수)"
                :readonly="!isEditable"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.str4"
                maxlength="50"
                :counter="50"
                label="규격"
                placeholder="입력하세요"
                :readonly="!isEditable"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.str5"
                maxlength="30"
                label="개수"
                placeholder="입력하세요"
                :readonly="!isEditable"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-row
                no-gutters
                align="center"
                justify="start"
              >
                <v-col cols="2">
                  <div style="width: 80px;">
                    <v-select
                      v-model="isBuyLimit"
                      :items="buyLimits"
                      label="구매기한"
                      class="mt-5 ml-2"
                      dense
                      @change="changeBuyLimit"
                      :readonly="!isEditable"
                    ></v-select>
                  </div>
                </v-col>
                <v-col cols="10">
                  <v-menu
                    ref="date2Selector"
                    v-model="date2Selector"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :readonly="!isEditable"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="disableBuyLimit"
                        v-model.trim="form.date2"
                        label=""
                        prepend-icon="event"
                        readonly
                        v-on="on"
                        style="width: 200px;"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="form.date2"
                      locale="ko"
                      @change="saveDate2"
                      :readonly="!isEditable"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12">
              <v-textarea
                ref="txt1"
                v-model="form.str1"
                outlined
                label="내용"
                placeholder="입력하세요"
                no-resize
                rows="5"
                counter="200"
                :rules="[rules.txtCounter]"
                @update:error="chkValidate = false"
                class="mt-5"
                :readonly="!isEditable"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// @: filters
import strDateFormat from '@/filters/strDateFormat'
import requestBuyStatus from '@/filters/requestBuyStatus'

export default {
  components: {
    baseModal
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 650,
      height: 600,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      teamId: 1, // 기본팀이 초기값 - !!필요없지만 누구나 등록이 가능하므로 1로 놔둠
      type: 45, // 중요: 구매신청
      depth: 1, // !!
      pId: 0, // 부모의 아이디
      pType: 0, // 부모는 없다
      pInfo: '', // 부모 정보위치
      isStar: 0,
      isSchedule: 0, // !! 일정에 표시안되도록
      subtype: 1, // 자동등록이 아님
      gubun1: '구매신청', // 구분명
      gubun2: '', // 중요: 신청자 이메일
      status: 1, // 관리상태
      date1: '', // 신청일
      date2: null, // 중요: 구매기한일
      str1: '', // 내용
      str2: '', // 신청자이름
      str3: '', // 물품 - 필수값
      str4: '', // 규격
      str5: '' // 갯수
    },
    // 구분: 관리상태
    status: [
      { text: '신청', value: 1 },
      { text: '접수', value: 2 },
      { text: '반려', value: 3 }
    ],
    // 구분:
    index: -1, // !! 수정 인덱스
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        str3: '물품'
      }
    },
    // 구분: 입력 검증
    rules: {
      txtCounter: v => v.length <= 200 || '200자 이하의 내용을 입력해 주세요'
    },
    // 구분: txt1 유효성 체크
    chkValidate: true,
    // 구분: 수정시 처리자와 최종업데이트 정보
    viewWname: '',
    viewUpdatedAt: '',
    // 구분: 구매기한
    date2Selector: false, // 구매기한일 셀렉터
    // 구분: 구매기한 있음 / 없음 여부
    isBuyLimit: 1,
    // 구분: 구매기한 날짜블록 보이기 여부
    disableBuyLimit: true,
    // 구분: 구매기한 셀렉트
    buyLimits: [
      { text: '없음', value: 1 },
      { text: '있음', value: 2 }
    ],
    // !!구분: 물품관리권한 여부
    isSupplyAuth: false,
    // !!구분: 글쓴이가 로그인한 유저 본인인지 여부
    itsMe: false,
    // !!구분: 수정가능 여부
    isEditable: true,
    // !!구분: 삭제가능 여부
    isRemoveable: true
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    requestBuyStatus, // !! 구매신청 관리상태를 리턴하는 메소드
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.form.id = 0
      this.form.teamId = 1 // 팀아이디 - !!필요없지만 누구나 등록이 가능하므로 1로 놔둠
      this.form.type = 45 // 중요: 구매신청
      this.form.depth = 1
      this.form.pId = 0
      this.form.pType = 0
      this.form.pInfo = ''
      this.form.isStar = 0
      this.form.isSchedule = 0
      this.form.subtype = 1
      this.form.gubun1 = '구매신청'
      this.form.gubun2 = this.$store.state.ui.email // !! 신청자 이메일
      this.form.status = 1
      this.form.date1 = this.$moment().format('YYYY-MM-DD') // 신청일 초기값(오늘)
      this.form.date2 = null // !! 구매기한일
      this.form.str1 = '' // 내용
      this.form.str2 = this.$store.state.ui.name // 신청자이름
      this.form.str3 = '' // 물품 - 필수값
      this.form.str4 = '' // 규격
      this.form.str5 = '' // 개수

      this.index = -1 // 수정용 인덱스 초기화

      // 중요: 물품관리 권한여부를 가져온다!
      this.isSupplyAuth = await this.requestSupplyAuth()
      this.itsMe = false // !! 신청자가 로그인한 현재 유저인가?
      this.isEditable = true // !! 수정가능
      this.isRemoveable = true // !! 삭제가능

      // !! 구매기한 초기값
      this.isBuyLimit = 1
      // !! 구매기한 날짜 보이기여부 초기값
      this.disableBuyLimit = true
    },
    async open (title, options, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 초기화
        await this.initDialog()

        // 참고: 수정 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          this.index = index

          this.form.id = obj.id

          this.form.gubun1 = obj.gubun1
          this.form.gubun2 = obj.gubun2 // !! 신청자 이메일 >> DB에서 가져온

          // 중요: 신청자가 현재 로그인 유저인가?
          if (this.form.gubun2 === this.$store.state.ui.email) {
            this.itsMe = true // 맞다
          } else {
            this.itsMe = false // 아님
          }

          // 관리상태 - 신청/접수/반려
          this.form.status = obj.status

          // 중요: 관리상태에 따른 수정/삭제 가능 여부
          if (this.form.status === 1) {
            // !! 신청상태인 경우
            // 신청인 본인만 수정가능
            this.isEditable = this.itsMe

            // 신청인과 물품관리권한자는 삭제가능
            this.isRemoveable = this.itsMe || this.isSupplyAuth
          } else {
            // !! 접수/반려 상태인 경우
            // 내용의 변경은 아무도 못함
            this.isEditable = false

            // 삭제는 물품관리권한자만 가능
            this.isRemoveable = this.isSupplyAuth
          }

          this.form.isStar = obj.isStar

          this.form.str1 = obj.str1 // 내용
          this.form.str2 = obj.str2 // !! 신청자 >> DB에 저장된 신청자
          this.form.str3 = obj.str3 // 물품 - 필수값
          this.form.str4 = obj.str4 // 규격
          this.form.str5 = obj.str5 // 개수

          // 신청일 처리
          this.form.date1 = obj.date1.substr(0, 10)

          // 중요: 구매기한일 처리
          if (obj.date2) {
            this.isBuyLimit = 2 // !! 구매기한 있음 처리
            this.disableBuyLimit = false // !! 구매기한 활성화
            if (!this.isEditable) {
              // !! 수정가능해야 구매기한이 활성화 된다
              this.disableBuyLimit = true
            }
            this.form.date2 = obj.date2.substr(0, 10) // !! 구매기한일처리
          }

          // 참고: 편집일때는 처리자와 업데이트 일자를 보여준다.
          this.viewWname = obj.wname
          this.viewUpdatedAt = strDateFormat(obj.updatedAt) // 필터로 쓰진 않고 함수로 사용
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 등록
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 중요: textarea 의 글자수가 200 이하로 조정되면 여기서 체크해서 처리한다.
        if (this.rules.txtCounter(this.$refs.txt1.value) === true) {
          this.chkValidate = true
        }
        if (!this.chkValidate) throw new Error('저장할 수 없습니다. 내용 항목의 글자수를 줄여주세요.')

        // !! 신청일 - 현재 시분초로
        this.form.date1 = `${this.form.date1.substr(0, 10)} ${this.$moment().format('HH:mm:ss')}`

        // !! 구매기한일 문자열 만들기 - 23:59:59 로 만든다..
        if (this.isBuyLimit === 2) { // 구매기한 있음의 경우
          this.form.date2 = this.form.date2 ? `${this.form.date2.substr(0, 10)} 23:59:59` : null
        } else { // 구매기한 없음의 경우
          this.form.date2 = null
        }
        // console.log(this.form)

        // !! DB 입력 처리 (등록/수정 동일하게 처리)
        const { data } = await this.$axios.post(`lawork/lwc/setRequestBuy`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        // 등록/수정 후
        await this.initDialog()
        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 취소
    async cancel () {
      try {
        await this.initDialog() // 초기화
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 삭제 - case.controllers.js 의 deleteLwcommon() 를 그대로 사용한다.
    async remove () {
      try {
        if (!this.form.id) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // console.log(this.form)
          const { data } = await this.$axios.get(`/lawork/case/deleteLwcommon/${this.form.id}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          await this.initDialog() // 초기화
          this.resolve('removed')
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 구매기한 셀렉트 선택시 저장 함수
    saveDate2 (date) {
      this.$refs.date2Selector.save(date)
    },
    // !! 바로가기 링크 - 이 경우는 독립메뉴 리스트로
    gotoDetail () {
      this.dialog = false
      // !! 현재 경로가 work 디렉토리가 아닌 경우만 work/ 로 이동시킨다
      if (!(this.$router.history.current.path.indexOf('work') !== -1)) {
        this.$router.push(`/work/list`)
      }
    },
    // 구분: 구매기한 변경 이벤트 핸들러
    changeBuyLimit () {
      try {
        if (this.isBuyLimit === 1) { // 없음인 경우
          this.form.date2 = null
          this.disableBuyLimit = true
        } else {
          this.form.date2 = this.$moment().format('YYYY-MM-DD')
          this.disableBuyLimit = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !!@: ---- Utility 함수 ----
    // 구분: 물품관리권한 체크
    async requestSupplyAuth () {
      try {
        const { data } = await this.$axios.get(`lawork/lwc/getAllTeamIds`)
        if (!data.success) this.sbpop(`오류가 발생하였습니다: ${data.message}`)
        return data.auths.t2
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
