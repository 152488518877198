import jwt from 'jsonwebtoken'

const jwtSecret = 'maddak0!out' // NOTE: be/.env 와 동일하게 맞춰야 한다!

/**
 * [중요] 자체 토큰 풀기 함수 : Promise 방식
 * 토큰이 만료가 되면 : { TokenExpiredError: jwt expired } 에러가 난다!
 * fe/router.js 의 axios.interceptors.response() 의 에러처리에서 잡는다!
 */
const decodeToken = function (token) {
  return new Promise(
    (resolve, reject) => {
      jwt.verify(token, jwtSecret, (error, decoded) => {
        if (error) reject(error)
        resolve(decoded)
      })
    }
  )
}

/**
 * [중요] 자체 토큰 유효성 체크 함수
 * 토큰 만료시 에러를 발생시키지 않는게 목적임
 * 토큰이 유효하면 true를 만료되었으면 false 를 리턴함
 */
const checkTokenValidate = function (token) {
  return new Promise(
    (resolve, reject) => {
      jwt.verify(token, jwtSecret, (error, decoded) => {
        if (error) resolve(false) // reject 가 아님
        resolve(true)
      })
    }
  )
}

export {
  decodeToken,
  checkTokenValidate
}
