<template>
  <v-card
    tile
    :elevation="0"
  >
    <v-row no-gutters>
      <!-- 좌측 컨텐츠 시작 -->
      <v-col cols="12" md="6" lg="6" xl="6" class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-cog-refresh</v-icon> 계산조건
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              class="mr-2"
              @click.stop="doCalc"
            >
              <v-icon small>mdi-led-on</v-icon>
              계산
            </v-btn>
            <v-btn
              small
              @click.stop="doInit"
            >
              <v-icon small>mdi-refresh</v-icon>
              초기화
            </v-btn>
          </v-toolbar>
          <v-card
            tile
            outlined
            elevation="0"
            class="pa-2"
            min-height="568"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" class="py-0">
                <v-radio-group v-model="rbChk" row>
                  <v-radio label="기간식계산법" value="기간식계산법"></v-radio>
                  <v-radio label="총일수계산법" value="총일수계산법"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" class="pb-0">
                <span class="text-body-2 primary--text">*금액(원)</span>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <!-- <span class="text-body-2 primary--text ml-2 mr-3 mt-2">원금</span> -->
                  <v-text-field
                    ref="numWongum"
                    v-model.trim="wongum"
                    v-validate="'required|max:15|is_not:0'"
                    :error-messages="errors.collect('wongum')"
                    data-vv-name="wongum"
                    required
                    maxlength="15"
                    dense
                    outlined
                    filled
                    style="max-width: 250px;"
                    @keypress="preventComma"
                  ></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="12" class="pb-2">
                <v-row
                  no-gutters
                  align="center"
                  justify="center"
                >
                  <v-col cols="12" md="4" sm="4" class="text-center">
                    <span class="text-body-2 primary--text">시기</span>
                  </v-col>
                  <v-col cols="12" md="4" sm="4" class="text-center">
                    <span class="text-body-2 primary--text">종기</span>
                  </v-col>
                  <v-col cols="12" md="4" sm="4" class="text-center">
                    <span class="text-body-2 primary--text">이자율(%)</span>
                  </v-col>
                </v-row>
              </v-col>
              <!-- !! 1 번째 -->
              <v-col cols="12">
                <v-row no-gutters align="start">
                  <v-col cols="1" class="text-center pt-2">
                    1.
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      ref="ds11"
                      v-model="ds11"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      :disabled="!day1Enable"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model.trim="day11"
                          placeholder="2020-01-01"
                          label=""
                          readonly
                          v-on="on"
                          dense
                          outlined
                          filled
                          :disabled="!day1Enable"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="dp11"
                        v-model="day11"
                        locale="ko"
                        @change="saveDate11"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="1" class="text-center pt-2">
                    ~
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      ref="ds12"
                      v-model="ds12"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      :disabled="!day1Enable"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          ref="refDay12"
                          v-model.trim="day12"
                          v-validate="'required'"
                          :error-messages="errors.collect('day12')"
                          data-vv-name="day12"
                          placeholder="2020-01-01"
                          label=""
                          readonly
                          v-on="on"
                          dense
                          outlined
                          filled
                          :disabled="!day1Enable"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="dp12"
                        v-model="day12"
                        locale="ko"
                        @change="saveDate12"
                        :min="day12MinDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="1" class="text-center">
                    &nbsp;
                  </v-col>
                  <v-col cols="3" class="text-center">
                    <v-text-field
                      ref="numDay1ija"
                      v-model="day1ija"
                      placeholder="2.5"
                      label=""
                      maxlength="6"
                      dense
                      outlined
                      filled
                      style="max-width: 70px;"
                      :disabled="!day1Enable"
                      @keyup.stop="d1keyup"
                      :error="d1ijaError"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <!--// 1 번째 -->
              <!-- !! 2 번째 -->
              <v-col cols="12">
                <v-row no-gutters align="start">
                  <v-col cols="1" class="text-center pt-2">
                    2.
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      ref="ds21"
                      v-model="ds21"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      :disabled="!day2Enable"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model.trim="day21"
                          placeholder="2020-01-01"
                          label=""
                          readonly
                          v-on="on"
                          dense
                          outlined
                          filled
                          :disabled="!day2Enable"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="dp21"
                        v-model="day21"
                        locale="ko"
                        @change="saveDate21"
                        :min="day21MinDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="1" class="text-center pt-2">
                    ~
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      ref="ds22"
                      v-model="ds22"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      :disabled="!day2Enable"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          ref="refDay22"
                          v-model.trim="day22"
                          v-validate="'required'"
                          :error-messages="errors.collect('day22')"
                          data-vv-name="day22"
                          placeholder="2020-01-01"
                          label=""
                          readonly
                          v-on="on"
                          dense
                          outlined
                          filled
                          :disabled="!day2Enable"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="dp22"
                        v-model="day22"
                        locale="ko"
                        @change="saveDate22"
                        :min="day22MinDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="1" class="text-center">
                    &nbsp;
                  </v-col>
                  <v-col cols="3" class="text-center">
                    <v-text-field
                      ref="numDay2ija"
                      v-model="day2ija"
                      placeholder="2.5"
                      label=""
                      maxlength="6"
                      dense
                      outlined
                      filled
                      style="max-width: 70px;"
                      :disabled="!day2Enable"
                      @keyup.stop="d2keyup"
                      :error="d2ijaError"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <!--// 2 번째 -->
              <!-- !! 3 번째 -->
              <v-col cols="12">
                <v-row no-gutters align="start">
                  <v-col cols="1" class="text-center pt-2">
                    3.
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      ref="ds31"
                      v-model="ds31"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      :disabled="!day3Enable"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model.trim="day31"
                          placeholder="2020-01-01"
                          label=""
                          readonly
                          v-on="on"
                          dense
                          outlined
                          filled
                          :disabled="!day3Enable"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="dp31"
                        v-model="day31"
                        locale="ko"
                        @change="saveDate31"
                        :min="day31MinDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="1" class="text-center pt-2">
                    ~
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      ref="ds32"
                      v-model="ds32"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      :disabled="!day3Enable"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          ref="refDay32"
                          v-model.trim="day32"
                          v-validate="'required'"
                          :error-messages="errors.collect('day32')"
                          data-vv-name="day32"
                          placeholder="2020-01-01"
                          label=""
                          readonly
                          v-on="on"
                          dense
                          outlined
                          filled
                          :disabled="!day3Enable"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="dp32"
                        v-model="day32"
                        locale="ko"
                        @change="saveDate32"
                        :min="day32MinDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="1" class="text-center">
                    &nbsp;
                  </v-col>
                  <v-col cols="3" class="text-center">
                    <v-text-field
                      ref="numDay3ija"
                      v-model="day3ija"
                      placeholder="2.5"
                      label=""
                      maxlength="6"
                      dense
                      outlined
                      filled
                      style="max-width: 70px;"
                      :disabled="!day3Enable"
                      @keyup.stop="d3keyup"
                      :error="d3ijaError"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <!--// 3 번째 -->
              <!-- !! 4 번째 -->
              <v-col cols="12">
                <v-row no-gutters align="start">
                  <v-col cols="1" class="text-center pt-2">
                    4.
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      ref="ds41"
                      v-model="ds41"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      :disabled="!day4Enable"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model.trim="day41"
                          placeholder="2020-01-01"
                          label=""
                          readonly
                          v-on="on"
                          dense
                          outlined
                          filled
                          :disabled="!day4Enable"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="dp41"
                        v-model="day41"
                        locale="ko"
                        @change="saveDate41"
                        :min="day41MinDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="1" class="text-center pt-2">
                    ~
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      ref="ds42"
                      v-model="ds42"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      :disabled="!day4Enable"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          ref="refDay42"
                          v-model.trim="day42"
                          v-validate="'required'"
                          :error-messages="errors.collect('day42')"
                          data-vv-name="day42"
                          placeholder="2020-01-01"
                          label=""
                          readonly
                          v-on="on"
                          dense
                          outlined
                          filled
                          :disabled="!day4Enable"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="dp42"
                        v-model="day42"
                        locale="ko"
                        @change="saveDate42"
                        :min="day42MinDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="1" class="text-center">
                    &nbsp;
                  </v-col>
                  <v-col cols="3" class="text-center">
                    <v-text-field
                      ref="numDay4ija"
                      v-model="day4ija"
                      placeholder="2.5"
                      label=""
                      maxlength="6"
                      dense
                      outlined
                      filled
                      style="max-width: 70px;"
                      :disabled="!day4Enable"
                      @keyup.stop="d4keyup"
                      :error="d4ijaError"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <!--// 4 번째 -->
            </v-row>
          </v-card>
        </v-container>
      </v-col>
      <!-- <v-divider vertical class="ma-0 pa-0"></v-divider> -->
      <!-- 우측 컨텐츠 시작 -->
      <v-col class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-cube-send</v-icon> 계산결과
            <v-spacer></v-spacer>
            <v-btn
              small
              @click="doCopy"
              dark
            >
              <v-icon small>mdi-content-copy</v-icon>
              클립보드복사
            </v-btn>
          </v-toolbar>
          <v-card
            id="copyArea"
            tile
            outlined
            elevation="0"
            class="pa-2"
            min-height="568"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12">
                <div
                  v-show="res1msg1"
                  class="mt-2 mb-6"
                >
                  ● 원금: <span class="text-body-2 primary--text font-weight-bold">{{ res1msg1 }}원</span>
                </div>
                <div
                  v-for="item in resItems"
                  :key="item.id"
                  class="mt-0 mb-4 text-body-2"
                >
                  {{ item.id }}. {{ item.term1 }}(<span class="text-body-2 font-weight-bold">{{ item.term2 }}</span>)<br>
                  <span class="ml-6">기간이자: <span class="font-weight-bold">{{ numberFormat(item.ija) }}</span>원</span>
                  <span class="ml-1">(이자율: {{ item.ijaPercent }}%)</span><br>
                  <span class="ml-6">이자누계: <span class="font-weight-bold">{{ numberFormat(item.ijaAcc) }}</span>원</span><br>
                  <span class="ml-6 error--text">원리금합계: <span class="font-weight-bold">{{ numberFormat(item.totalAmount) }}</span>원</span>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import copy from 'copy-to-clipboard' // !! 클립보드 복사 플러그인
import ko from 'vee-validate/dist/locale/ko'

// 구분: filters
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import preventComma from '@/filters/preventComma' // keypress event 에서 쉼표막기
import strToNumber from '@/filters/strToNumber' // '10,000' => 를 10000 로 변환
import isNumberDot4 from '@/filters/isNumberDot4'

export default {
  components: {
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    rbChk: '기간식계산법', // 기간식계산법/총일수계산법 라디오버튼 초기값 - 문자열임을 주의
    wongum: '0', // 원금
    // 구분: 1 번째
    day11: '', // day 1 시기
    day12: '', // day 1 종기
    ds11: false, // date 1-1 selector
    ds12: false, // date 1-2 selector
    day12MinDate: '', // day 1-2 의 미니멈데이
    day1ija: '', // 1 이자율
    d1ijaError: false, // 1 이자율 에러
    // 구분: 2 번째
    day21: '', // day 2 시기
    day22: '', // day 2 종기
    ds21: false, // date 2-1 selector
    ds22: false, // date 2-2 selector
    day21MinDate: '', // 2-1 의 미니멈데이
    day22MinDate: '', // 2-2 의 미니멈데이
    day2ija: '', // 2 이자율
    d2ijaError: false, // 2 이자율 에러
    // 구분: 3 번째
    day31: '', // day 3 시기
    day32: '', // day 3 종기
    ds31: false, // date 3-1 selector
    ds32: false, // date 3-2 selector
    day31MinDate: '', // 3-1 의 미니멈데이
    day32MinDate: '', // day 3-2 의 미니멈데이
    day3ija: '', // 3 이자율
    d3ijaError: false, // 3 이자율 에러
    // 구분: 4 번째
    day41: '', // day 4 시기
    day42: '', // day 4 종기
    ds41: false, // date 4-1 selector
    ds42: false, // date 4-2 selector
    day41MinDate: '', // 4-1 의 미니멈데이
    day42MinDate: '', // day 4-2 의 미니멈데이
    day4ija: '', // 4 이자율
    d4ijaError: false, // 4 이자율 에러
    // 구분: 1 번째는 항상 활성, 2 ~ 4 번째는 초기에 비활성 상태임
    day1Enable: true,
    day2Enable: false,
    day3Enable: false,
    day4Enable: false,
    // 구분:
    // ijaPercent: 0, // 연이율
    ijaNujuk: 0, // 누적이자
    res1msg1: '', // 계산결과 > 원금
    resItems: [], // 계산결과 > 배열
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        wongum: '금액',
        day12: '종기'
      }
    }
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    this.doInit()
  },

  created () {
    // vee-validate 커스텀 유효성 처리(이자율)
    // this.$validator.extend('chkIja', {
    //   getMessage: field => ``,
    //   validate: value => {
    //     const _val = parseFloat(value)
    //     if (!value || _val === 0 || isNaN(_val)) {
    //       return false
    //     } else {
    //       return true
    //     }
    //   }
    // })
  },

  watch: {
    // 구분: 원금 !![2021.3.17] 수정
    'wongum': {
      handler: function (val, oldVal) {
        if (!val) {
          this.wongum = '0'
          this.$refs.numWongum.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.wongum = val.replace(/(^0+)/, '')
          }
          this.wongum = numberFormatR(this.wongum)
          this.wongum = numberFormat(this.wongum)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.wongum)) {
            this.wongum = oldVal
            this.$refs.numWongum.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 1번째 이자율 - 소숫점 4자리까지 허용
    'day1ija': {
      handler: function (val, oldVal) {
        if (val.length > 0) {
          if (isNumberDot4(val)) {
            this.day1ija = oldVal
            this.$refs.numDay1ija.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 2번째 이자율 - 소숫점 4자리까지 허용
    'day2ija': {
      handler: function (val, oldVal) {
        if (val.length > 0) {
          if (isNumberDot4(val)) {
            this.day2ija = oldVal
            this.$refs.numDay2ija.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 3번째 이자율 - 소숫점 4자리까지 허용
    'day3ija': {
      handler: function (val, oldVal) {
        if (val.length > 0) {
          if (isNumberDot4(val)) {
            this.day3ija = oldVal
            this.$refs.numDay3ija.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 4번째 이자율 - 소숫점 4자리까지 허용
    'day4ija': {
      handler: function (val, oldVal) {
        if (val.length > 0) {
          if (isNumberDot4(val)) {
            this.day4ija = oldVal
            this.$refs.numDay4ija.lazyValue = oldVal
          }
        }
      }
    }
  },

  methods: {
    numberFormat,
    numberFormatR,
    preventComma,
    strToNumber,
    isNumberDot4, // 소숫점 4자리까지 필터
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 구분: 초기화
    async doInit () {
      try {
        // !! form validation 초기화
        this.$validator.reset()

        this.rbChk = '기간식계산법'
        this.wongum = '0'

        const todayStr = this.$moment().format('YYYY-MM-DD')

        // 구분: 1 번째
        this.day11 = todayStr // day 1 시기 초기값(오늘)
        this.day12 = todayStr // day 1 종기 초기값(오늘+1)
        this.day12MinDate = new Date(this.day11).toISOString().slice(0, 10) // day 1-2 의 미니멈데이
        this.day1ija = '' // 이자율
        this.d1ijaError = false // 1 이자율 에러

        // 구분: 2 번째
        this.day21 = todayStr
        this.day22 = todayStr
        this.day21MinDate = this.day12MinDate // !! 1번의 종기 이후로 제한된다!
        this.day22MinDate = this.day12MinDate
        this.day2ija = '' // 이자율
        this.d2ijaError = false // 2 이자율 에러

        // 구분: 3 번째
        this.day31 = todayStr
        this.day32 = todayStr
        this.day31MinDate = this.day22MinDate // !! 2번의 종기 이후로 제한된다!
        this.day32MinDate = this.day22MinDate
        this.day3ija = '' // 이자율
        this.d3ijaError = false // 3 이자율 에러

        // 구분: 4 번째
        this.day41 = todayStr
        this.day42 = todayStr
        this.day41MinDate = this.day32MinDate // !! 3번의 종기 이후로 제한된다!
        this.day42MinDate = this.day32MinDate
        this.day4ija = '' // 이자율
        this.d4ijaError = false // 4 이자율 에러

        this.day1Enable = true // !! 1번째는 항상 활성
        this.day2Enable = false
        this.day3Enable = false
        this.day4Enable = false

        // this.ijaPercent = 0 // 연이율
        this.ijaNujuk = 0 // 누적이자

        // 결과를 보기위한 변수 초기화
        this.res1msg1 = ''
        this.resItems = [] // 초기화
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 기간식이자기간 계산함수
    // @param: id/시기/종기/원금/이자율
    async calcInterest01 (id, sdate, edate, wgum, ija) {
      try {
        const sd = this.$moment(sdate) // 시기
        const ed = this.$moment(edate) // 종기

        // 원금, 이자 변환
        const wGum = strToNumber(wgum) // 정수로 변환
        const ijaPercent = parseFloat(ija) // 실수로 변환

        // 시기와 종기의 년차 - 모멘트로 구하기
        // console.log(ed.diff(sd, 'year'))
        // !! 문제점: 초일불산입방식이라 1998-02-01 ~ 2000-01-31 = 1년 으로 나와버린다!
        // 원하는 것은 1998-02-01 ~ 1999-01-31(1년)
        //            1999-02-01 ~ 2000-01-31(1년) 해서 2년이 나와야 한다!

        let csd = sd.clone() // 1년씩 누적시키는 변수 = 시기 복사
        let bsd = sd.clone() // before csd = 마지막 구간의 시작일을 담는 변수
        let nYear = 0 // 연 누적

        // !! 루프 : 루프를 돌기 때문에 누적일은 결국 시기를 넘어가게 된다.
        while (1) {
          // !! 시기로부터 +1년 = 초일산입이기 때문에 add(1, 'years') 후 add(-1. 'days') 를 해야 함
          csd.add(1, 'years').add(-1, 'days')
          // console.log(`csd: ${csd.format('YYYY.MM.DD')}`)

          // !! 종기보다 커지는 순간 루프중지
          // 중요: 중지전 마지막 년도 구간의 시작점을 구해야 한다
          if (ed.diff(csd, 'days') <= 0) {
            bsd = csd.clone() // 중요: 마지막 년도 구간을 구하기 위해 누적된 변수 복사
            // console.log(`>> bsd: ${bsd.format('YYYY.MM.DD')}`) // csd - 1년이 된다.

            bsd.add(-1, 'years').add(1, 'days') // 중요: 1년을 빼고 보정을 위해 1일을 더해야 함.
            // console.log(`csd - bad: ${csd.diff(bsd, 'days')}`)

            // 중요: 또 보정: 1년을 뺐는데 윤년이 끼었으면 bsd + 1일을 더 해줘야 한다.
            if (csd.diff(bsd, 'days') + 1 === 366) {
              bsd.add(1, 'days')
            }
            break
          }
          nYear++

          // 중요: 다음 년도 구간 보정을 위해 누적변수에 1일을 더해야 함.
          // 위에서 csd='2019-01-31' 로 되었다면 '2019-02-01'로 시작일을 만들어 준다.
          csd.add(1, 'days')
          // console.log(`csd: ${csd.format('YYYY.MM.DD')}`)
        }

        // console.log(`nYear: ${nYear}`)
        // console.log(`bsd: ${bsd.format('YYYY.MM.DD')}`) // csd - 1년이 된다.
        // console.log(`csd: ${csd.format('YYYY.MM.DD')}`) // 누적에 의해 시기를 넘기게 된다
        // console.log(ed.diff(bsd, 'days') + 1) // 중요: 자투리 일수 = 초일산입 때문에 +1일을 해야함.
        // // !!중요: csd - bsd + 1 = 366면 2.29가 포함된 것이다!!
        // console.log(csd.diff(bsd, 'days') + 1)

        // !! 하지만 이렇게 해도 1998-02-01 ~ 2000-01-31 = 1년 으로 나와버린다!
        // 남은 자투리기간(ed - bsd + 1) = 365 로 1년이지만 윤년보정을 하지 않았기에 자투리 일수로만 계산된다!

        const remainDays = ed.diff(bsd, 'days') + 1 // 남은 자투리 일수(종기 - 마지막 구간 시작일 + 1)
        const divDays = csd.diff(bsd, 'days') + 1 // 자투리일수를 나누는 기준일(365 or 366) = (누적일 - 마지막 구간 시작일 + 1)
        const divYear = remainDays / divDays // 자투리 일수 / 마지막 구간 기준일(365 or 366)
        // console.log(`remainDays: ${remainDays}`)
        // console.log(`divYear: ${divYear}`)
        // console.log(`divDays: ${divDays}`)

        // 중요: 다시 보정: remainDays / divDays === 1 이면 년도에 +1 해주어 보정을 한다
        if (divYear === 1) {
          nYear++ // 년도에 누적
          // !! 또 이렇게 되면 딱 나뉘어 떨어져서 자투리 일수가 없는 경우다!
        }

        // if (divYear === 1) {
        //   console.log(`${nYear}년`)
        // } else {
        //   if (nYear > 0) {
        //     console.log(`${nYear}년 ${remainDays}/${divDays}일`)
        //   } else {
        //     console.log(`${remainDays}/${divDays}일`)
        //   }
        // }
        // !! 위 조건식을 간단히 1줄로 요약하면 아래와 같다.
        // let viewMsg = `${nYear > 0 ? `${nYear}년 ` : ''}${divYear !== 1 ? `${remainDays}/${divDays}일` : ''}`
        // console.log(viewMsg)

        // a. 연차에 따른 이율 계산 = 원금 * 연차 * 연이율
        // b. 연차를 뺀 나머지 일수의 이율계산 = ((남은 자투리 일수) x 연이율/(365 or 366))
        // 이자 = a + b
        let ija01 = Math.floor(wGum * nYear * ijaPercent / 100)
        let ija02 = (divYear === 1) ? 0 : Math.floor(wGum * remainDays * (ijaPercent / 100) / divDays)

        let term1 = `${sd.format('YYYY.MM.DD')} ~ ${ed.format('YYYY.MM.DD')}` // 기간 1
        let term2 = `${nYear > 0 ? `${nYear}년 ` : ''}${divYear !== 1 ? `${remainDays}/${divDays}일` : ''}` // 기간 2

        // !! 리턴되는 모든 값은 초일산입된 값이다!
        // return {
        //   id, // 현재 계산중인 넘의 번호
        //   ijaAccount, // 계산된 이자
        //   nYear, // 년도 누적값
        //   remainDays, // 남은 자투리 일수(종기 - 마지막 구간 시작일 + 1)
        //   divDays, // 자투리일수를 나누는 기준일(365 or 366) = (누적일 - 마지막 구간 시작일 + 1)
        //   ijaPercent, // 이자율
        //   sDate: sd.format('YYYY.MM.DD'), // 시기 문자열
        //   eDate: ed.format('YYYY.MM.DD'), // 종기 문자열
        //   type: 1 // 기간식계산법
        // }
        return {
          id, // 현재 계산중인 넘의 번호
          term1, // 기간 1
          term2, // 기간 2
          ija: ija01 + ija02 // 계산된 이자
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 총일수계산법 계산함수
    // @param: id/시기/종기/원금/이자율
    async calcInterest02 (id, sdate, edate, wgum, ija) {
      try {
        const sd = this.$moment(sdate) // 시기
        const ed = this.$moment(edate) // 종기

        // 원금, 이자 변환
        const wGum = strToNumber(wgum) // 정수로 변환
        const ijaPercent = parseFloat(ija) // 실수로 변환

        // 1) 정확한 총일수를 계산한다.(중간에 윤년이 있는 해는 366일로 계산)
        // 2) 이자 = 원금 x 총일수 x 일별이자율(%) --- 소숫점이하 절사
        //    일별이자율(%) = 이자율(년이율 %) / 365 -- 윤년이랑 상관없이 365일로 계산한다.

        // 총경과일수
        const totalDayDiff = ed.diff(sd, 'day')
        // 초일산입 총경과일수: 초일산입계산법을 따른다면 총경과일수+1을 해야한다.
        const cTotalDayDiff = totalDayDiff + 1

        const cIja = Math.floor((wGum * cTotalDayDiff * (ijaPercent / 100)) / 365) // 계산된 이자

        let term1 = `${sd.format('YYYY.MM.DD')} ~ ${ed.format('YYYY.MM.DD')}` // 기간 1
        let term2 = `${cTotalDayDiff}일`

        // return {
        //   id, // 현재 계산중인 넘의 번호
        //   ijaAccount, // 계산된 이자
        //   cTotalDayDiff, // 경과일수(초일산입된)
        //   ijaPercent, // 이자율
        //   sDate: sd.format('YYYY.MM.DD'), // 시기
        //   eDate: ed.format('YYYY.MM.DD'), // 종기
        //   type: 2 // 총일수계산법
        // }
        return {
          id, // 현재 계산중인 넘의 번호
          term1, // 기간 1
          term2, // 기간 2
          ija: cIja // 계산된 이자
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 계산 실행
    async doCalc () {
      try {
        if (strToNumber(this.wongum) === 0) {
          this.wongum = '0'
        }

        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        this.resItems = [] // 초기화

        let cElems = [] // 실제 계산할 객체를 모아놓은 배열
        const intWongum = strToNumber(this.wongum) // 합산을 위해 정수로 변환된 원금
        this.ijaNujuk = 0 // 누적이자 초기화(초기화 함수에 있지만..혹시 몰라서 다시 한다)

        // 1 ~ 4까지의 이자율 검증 객체배열을 만든다.
        let Elems = [
          { id: 1, enabled: this.day1Enable, ija: this.day1ija, sd: this.day11, ed: this.day12, isError: false },
          { id: 2, enabled: this.day2Enable, ija: this.day2ija, sd: this.day21, ed: this.day22, isError: false },
          { id: 3, enabled: this.day3Enable, ija: this.day3ija, sd: this.day31, ed: this.day32, isError: false },
          { id: 4, enabled: this.day4Enable, ija: this.day4ija, sd: this.day41, ed: this.day42, isError: false }
        ]

        // 순회하면서 에러조사... 에러가 있으면 표시
        Elems.forEach(elem => {
          // !! 활성화 되어있고 이자율이 들어간 경우만 계산됨
          // !! 1 번째는 이자율이 안들어가 간 경우 즉시 에러 처리! 1 번째는 항상 활성화 된 상태이기도 하다
          if (elem.id === 1) {
            const tmpIja = parseFloat(elem.ija)
            if (!elem.ija || tmpIja === 0 || isNaN(tmpIja)) {
              this[`d${elem.id}ijaError`] = true // 커스텀 에러 발생
              elem.isError = true
              throw new Error('이자율을 확인해 주세요')
            } else {
              cElems.push(elem)
            }
          } else { // 1 번째 이외의 항목은 이자율이 비어있어도 즉시에러로 처리하지 않는다.
            if (elem.enabled && elem.ija) {
              const tmpIja = parseFloat(elem.ija)
              if (tmpIja === 0 || isNaN(tmpIja)) { // 0 이거나 isNaN === true 인 경우 에러로 처리
                this[`d${elem.id}ijaError`] = true // 커스텀 에러 발생
                elem.isError = true
              } else {
                cElems.push(elem)
              }
            }
          }
        })

        // 1 ~ 4 까지의 계산을 순환하며 계산 && 에러가 있는지 확인 (1번은 즉시 에러 처리라 해당 없다.)
        // && 1번을 제외한 나머지는 throw new Error() 를 하지 않는다..
        // 에러인 경우라도 1번을 제외하고 그 앞까지 계산을 한다.
        // if (Elems.map(e => e.isError).includes(true))

        // !! 1) 원금뷰: 정상적인 계산이 있고 && 원금이 있으면 원금을 뷰하자.
        if (this.wongum && cElems.length > 0) {
          this.res1msg1 = `${numberFormat(this.wongum)}`
        }

        // 정상적인 넘들만 순회하면서 계산하자!
        // cElems 에는 정상적인(활성화 되어있고 && 에러가 아닌) 계산내역만 배열로 생성되어있다!
        let calcRes = null
        cElems.forEach(async elem => {
          if (this.rbChk === '기간식계산법') {
            // 기간식계산법
            calcRes = await this.calcInterest01(elem.id, elem.sd, elem.ed, this.wongum, elem.ija)
          } else {
            // 총일수계산법
            calcRes = await this.calcInterest02(elem.id, elem.sd, elem.ed, this.wongum, elem.ija)
          }
          this.ijaNujuk += calcRes.ija // 이자를 누적시킨다.
          this.resItems.push({
            id: calcRes.id,
            term1: calcRes.term1,
            term2: calcRes.term2,
            ija: calcRes.ija, // 계산된 이자
            ijaPercent: elem.ija, // 이자율(네이밍을 헷갈리게 지었네 ㅠㅠ)
            ijaAcc: this.ijaNujuk, // 누적된이자
            totalAmount: intWongum + this.ijaNujuk // 원리금합계(원금 + 누적이자)
          })
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: day1-1 선택시 저장 함수
    saveDate11 (date) {
      this.$refs.ds11.save(date)
      // !! day12의  미니엄 일자가 자동으로 지정된다!
      this.day12MinDate = new Date(this.day11).toISOString().slice(0, 10)
      this.day12 = this.day12MinDate
    },
    // day1-2 선택시 저장 함수
    saveDate12 (date) {
      this.$refs.ds12.save(date)
      // !! day21, day22  미니엄 일자가 자동으로 지정된다!
      const nextDay = this.$moment(this.day12).add(1, 'days').format('YYYY-MM-DD')
      this.day21 = this.day21MinDate = nextDay
      this.day22 = this.day22MinDate = nextDay
    },
    // 구분: day2-1 선택시 저장 함수
    saveDate21 (date) {
      this.$refs.ds21.save(date)
      // !! day22의  미니엄 일자가 자동으로 지정된다!
      this.day22MinDate = new Date(this.day21).toISOString().slice(0, 10)
      this.day22 = this.day22MinDate
    },
    // day2-2 선택시 저장 함수
    saveDate22 (date) {
      this.$refs.ds22.save(date)
      // !! day31, day32  미니엄 일자가 자동으로 지정된다!
      const nextDay = this.$moment(this.day22).add(1, 'days').format('YYYY-MM-DD')
      this.day31 = this.day31MinDate = nextDay
      this.day32 = this.day32MinDate = nextDay
    },
    // 구분: day3-1 선택시 저장 함수
    saveDate31 (date) {
      this.$refs.ds31.save(date)
      // !! day32의  미니엄 일자가 자동으로 지정된다!
      this.day32MinDate = new Date(this.day31).toISOString().slice(0, 10)
      this.day32 = this.day32MinDate
    },
    // day3-2 선택시 저장 함수
    saveDate32 (date) {
      this.$refs.ds32.save(date)
      // !! day41, day42  미니엄 일자가 자동으로 지정된다!
      const nextDay = this.$moment(this.day32).add(1, 'days').format('YYYY-MM-DD')
      this.day41 = this.day41MinDate = nextDay
      this.day42 = this.day42MinDate = nextDay
    },
    // 구분: day4-1 선택시 저장 함수
    saveDate41 (date) {
      this.$refs.ds41.save(date)
      // !! day42의  미니엄 일자가 자동으로 지정된다!
      this.day42MinDate = new Date(this.day41).toISOString().slice(0, 10)
      this.day42 = this.day42MinDate
    },
    // day4-2 선택시 저장 함수
    saveDate42 (date) {
      this.$refs.ds42.save(date)
    },
    // 구분: 1 번째 이자율 키업 이벤트 핸들러
    d1keyup (e) {
      try {
        const _val = parseFloat(e.target.value)
        if (!e.target.value || _val === 0 || isNaN(_val)) {
          this.d1ijaError = true
          this.day2Enable = false
        } else {
          this.d1ijaError = false
          this.day2Enable = true
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 2 번째 이자율 키업 이벤트 핸들러
    d2keyup (e) {
      try {
        const _val = parseFloat(e.target.value)
        if (!e.target.value) {
          // 값이 없는 경우 에러가 아니다! 그리고 다음 순번도 활성화 되지 않는다!
          this.d2ijaError = false
          this.day3Enable = false
        } else if (_val === 0 || isNaN(_val)) {
          this.d2ijaError = true
          this.day3Enable = false
        } else {
          this.d2ijaError = false
          this.day3Enable = true
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 3 번째 이자율 키업 이벤트 핸들러
    d3keyup (e) {
      try {
        const _val = parseFloat(e.target.value)
        if (!e.target.value) {
          this.d3ijaError = false
          this.day4Enable = false
        } else if (_val === 0 || isNaN(_val)) {
          this.d3ijaError = true
          this.day4Enable = false
        } else {
          this.d3ijaError = false
          this.day4Enable = true
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 4 번째 이자율 키업 이벤트 핸들러
    d4keyup (e) {
      try {
        const _val = parseFloat(e.target.value)
        if (!e.target.value) {
          this.d4ijaError = false
        } else if (_val === 0 || isNaN(_val)) {
          this.d4ijaError = true
        } else {
          this.d4ijaError = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 클립보드에 복사하기
    // 주의: async 를 붙여선 안된다. 매뉴얼 참고
    async doCopy () {
      try {
        const elem = document.querySelector('#copyArea')
        if (!elem) throw new Error(`요청하는 엘리먼트가 없습니다.`)

        const headerTitle = '▣ 이자계산\n'
        await copy(`${headerTitle}${elem.innerText}`)
        this.$store.commit('SB_POP', { msg: '클립보드에 복사되었습니다.', color: 'success' })
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
