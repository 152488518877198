<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >

    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <!-- <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn> -->

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <!-- <v-tooltip
        slot="tooltip-area"
        bottom class="ml-3"
        color="primary"
      >
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            small
            style="cursor:pointer;"
          >mdi-help-circle</v-icon>
        </template>
        <span class="text-body-2">일괄신규등록은 [관리자>정보일괄관리]에서 가능합니다.</span>
      </v-tooltip> -->

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >mdi-star-outline</v-icon>
        <v-icon
          v-else
          color="yellow"
        >mdi-star</v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <!-- !! start of form -->
          <v-row no-gutters>
            <!-- [2021.1.26] 중요: 담당부서 변경불가 폐지 -->
            <!-- <v-col cols="12" xs="12" class="mt-0">
              <v-select
                v-model="form.teamId"
                :items="teams"
                item-text="text"
                item-value="value"
                label="담당부서"
                menu-props="auto"
                class="mt-1"
                :disabled="index > -1 ? true : false"
              ></v-select>
            </v-col>
            <v-col v-show="index > -1" cols="12" xs="12">
              <v-alert type="info" dense text class="text-caption">
                담당부서의 변경은 관리자에게 문의하십시오.
              </v-alert>
            </v-col> -->
            <!-- !! [2021.1.26] 담당부서를 수정가능하게 수정 -->
            <v-col cols="12" xs="12" class="mt-0 pb-2">
              <v-select
                v-model="form.teamId"
                :items="teams"
                item-text="text"
                item-value="value"
                label="담당부서"
                v-validate="'required|decimal|greaterThanZero'"
                :error-messages="errors.collect('teamId')"
                data-vv-name="teamId"
                required
                dense
                menu-props="auto"
                class="mt-4"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" class="mt-0">
              <span class="text-h6 mt-2 mb-0 pb-0 green--text">기본내용</span>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="pr-2">
              <v-select
                v-model="form.type2"
                :items="courtTypes"
                item-text="text"
                item-value="value"
                label="기관"
                menu-props="auto"
                class="mt-1"
              ></v-select>
            </v-col>
            <!-- !! 관할 -->
            <v-col cols="12" xs="12" sm="8">
              <!-- !! 관할명 자동완성. -->
              <v-combobox
                ref="refCourtName"
                v-model="form.courtName"
                :filter="ackFilter"
                :items="keyword.courtName"
                :search-input.sync="sCourtName"
                :append-icon="!sCourtName ? 'mdi-arrow-down-drop-circle' : 'mdi-plus-circle-outline'"
                :menu-props="{ maxHeight: 340 }"
                label="관할"
                maxlength="30"
                hint="예)서울중앙지방법원 or 서울중앙지법"
                class="mt-1"
                @change="changeCourtName"
              >
                <template v-slot:selection="{ item }">
                  <span>{{ item }}</span>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" xs="12" sm="12">
              <v-row
                no-gutters
                align="center"
                justify="start"
              >
                <div style="width:470px">
                  <v-text-field
                    ref="txtCaseNum"
                    v-model="form.caseNum"
                    v-validate="'required|max:30'"
                    :error-messages="errors.collect('caseNum')"
                    data-vv-name="caseNum"
                    maxlength="30"
                    counter="30"
                    required
                    label="*사건번호"
                    hint="예)2020가단123456"
                    @keyup="chkCaseNumOverlapReset"
                    :error="isOverlabed1"
                  ></v-text-field>
                </div>
                <v-btn small text class="ma-2"
                  :color="mustChk1 ? 'error' : 'indigo'"
                  @click="chkCaseNumOverlap"
                >
                  <v-icon small class="text-left">
                    {{ chkMulti1 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                  </v-icon>
                  중복체크
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12">
              <v-row
                no-gutters
                align="center"
                justify="start"
              >
                <div style="width:470px">
                  <v-text-field
                    ref="txtManageNum"
                    v-model="form.manageNum"
                    maxlength="30"
                    counter="30"
                    label="관리번호"
                    hint="예)조직내부관리번호 LW-123"
                    :error="isOverlabed2"
                  ></v-text-field>
                </div>
                <v-btn small text class="ma-2"
                  :color="mustChk2 ? 'error' : 'indigo'"
                  @click="chkManageNumOverlap"
                >
                  <v-icon small class="text-left">
                    {{ chkMulti2 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                  </v-icon>
                  중복체크
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12">
              <!-- !! 사건명 자동완성. -->
              <v-combobox
                v-model="form.caseName"
                :filter="ackFilter"
                :items="keyword.caseName"
                :search-input.sync="sCaseName"
                :append-icon="!sCaseName ? 'mdi-arrow-down-drop-circle' : 'mdi-plus-circle-outline'"
                :menu-props="{ maxHeight: 340 }"
                label="*사건명"
                v-validate="'required|max:30'"
                :error-messages="errors.collect('caseName')"
                data-vv-name="caseName"
                maxlength="30"
                required
                hint="예)손해배상"
                class="mt-1"
              >
                <template v-slot:selection="{ item }">
                  <span>{{ item }}</span>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="pr-2">
              <!-- !! 의뢰인유형 자동완성. -->
              <v-combobox
                v-model="form.clType"
                :filter="ackFilter"
                :items="keyword.dangType"
                :append-icon="!sDangType ? 'mdi-arrow-down-drop-circle' : 'mdi-plus-circle-outline'"
                :menu-props="{ maxHeight: 340 }"
                label="의뢰인유형"
                maxlength="30"
                hint="예)원고"
                class="mt-1"
              >
                <template v-slot:selection="{ item }">
                  <span>{{ item }}</span>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" xs="12" sm="8">
              <v-row no-gutters>
                <v-text-field
                  v-model.trim="form.clName"
                  v-validate="'required|max:30'"
                  :error-messages="errors.collect('clName')"
                  data-vv-name="clName"
                  maxlength="30"
                  counter="30"
                  required
                  label="*의뢰인명"
                  hint="예)홍길동"
                ></v-text-field>
                <v-btn small text class="mt-6 ml-2"
                  :color="form.clGusok ? 'purple darken-2' : 'teal darken-2'"
                  @click="form.clGusok = !form.clGusok"
                >
                  <v-icon small class="text-left">
                    {{ form.clGusok ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                  </v-icon>
                  구속
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="pr-2">
              <!-- !! 상대방유형 자동완성. -->
              <v-combobox
                v-model="form.opType"
                :filter="ackFilter"
                :items="keyword.dangType"
                :append-icon="!sDangType ? 'mdi-arrow-down-drop-circle' : 'mdi-plus-circle-outline'"
                :menu-props="{ maxHeight: 340 }"
                label="상대방유형"
                maxlength="30"
                hint="예)피고"
                class="mt-1"
              >
                <template v-slot:selection="{ item }">
                  <span>{{ item }}</span>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" xs="12" sm="8">
              <v-row no-gutters>
                <v-text-field
                  v-model.trim="form.opName"
                  maxlength="30"
                  counter="30"
                  label="상대방명"
                  hint="예)홍길동"
                ></v-text-field>
                <v-btn small text class="mt-6 ml-2"
                  :color="form.opGusok ? 'purple darken-2' : 'teal darken-2'"
                  @click="form.opGusok = !form.opGusok"
                >
                  <v-icon small class="text-left">
                    {{ form.opGusok ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                  </v-icon>
                  구속
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12">
              <!-- !! 재판부 자동완성. -->
              <v-combobox
                v-model="form.jepanbu"
                :filter="ackFilter"
                :items="keyword.jepanbu"
                :search-input.sync="sJepanbu"
                :append-icon="!sJepanbu ? 'mdi-arrow-down-drop-circle' : 'mdi-plus-circle-outline'"
                :menu-props="{ maxHeight: 340 }"
                label="재판(담당)부"
                maxlength="30"
                hint="예)제2민사부 02-1234-5678"
                class="mt-1"
              >
                <template v-slot:selection="{ item }">
                  <span>{{ item }}</span>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                ref="requestBill"
                v-model.trim="form.requestBill"
                maxlength="30"
                counter="30"
                label="소가/청구금액"
                placeholder="예) 원고소가 10,000,000 원"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="pr-2">
              <v-select
                v-model="form.gubun1"
                :items="gubun1Select"
                item-text="text"
                item-value="value"
                label="소송분야"
                dense
                menu-props="auto"
                class="mt-4"
                @change="changeGubun1"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="pr-2">
              <v-select
                v-model="form.gubun2"
                :items="gubun2Select"
                item-text="text"
                item-value="value"
                label="상세분야"
                dense
                menu-props="auto"
                class="mt-4"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-select
                v-model="form.gubun3"
                :items="simGubuns"
                item-text="text"
                item-value="value"
                label="심급"
                dense
                menu-props="auto"
                class="mt-4"
              ></v-select>
            </v-col>
            <!-- <v-col cols="12" xs="12">
              <v-alert type="info" dense text class="text-caption">
                소송분야의 추가,변경,삭제는 [관리자>카테고리관리]에서 가능합니다.<br>
                접수일, 종국결과는 [진행내용]에서 관리됩니다.
              </v-alert>
            </v-col> -->
            <v-col cols="12" xs="12" class="mt-2">
              <v-row
                align="center"
                justify="start"
                no-gutters
              >
                <span class="text-h6 mt-2 mb-0 pb-0 green--text">관리개요</span>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small class="mt-1 ml-2" color="primary" style="cursor:pointer;">mdi-help-circle</v-icon>
                  </template>
                  <span class="text-body-2">소송분야, 관리그룹의 변경은 [관리자>카테고리관리]에서 가능합니다.</span>
                </v-tooltip>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="6" class="pr-2">
              <v-select
                v-model="form.status"
                :items="statusSelect"
                item-text="text"
                item-value="value"
                label="관리상태"
                dense
                menu-props="auto"
                class="mt-4"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-select
                v-model="form.gubun4"
                :items="jinhengGubuns"
                item-text="text"
                item-value="value"
                label="진행방식"
                dense
                menu-props="auto"
                class="mt-4"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" class="mt-0">
              <span class="text-subtitle-2 mt-0 mb-2 pb-0 grey--text">관리그룹</span>
            </v-col>
            <v-col cols="12"
              v-for="(group, i) in mGroups"
              :key="i"
            >
              <v-toolbar dense flat>
                <v-btn text class="px-1">
                  {{ mGroups[i].title }}
                </v-btn>
                <div class="mx-5 mt-5 mb-2" style="width:300px">
                  <v-select
                    v-model="form[`mgroup${i+1}`]"
                    :items="mGroups[i].selects"
                    dense
                    menu-props="auto"
                    style="font-size: 0.785rem !important"
                  ></v-select>
                </div>
                <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                  <v-icon
                    small
                    @click="addMGroup(group, i)"
                    class="pb-1"
                  >mdi-plus-box-outline</v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
            <!-- <v-col cols="12" xs="12" class="mt-4">
              <v-alert type="info" dense text class="text-caption">
                수임일은 [수임계약]에서 관리됩니다.
              </v-alert>
            </v-col> -->
            <v-col cols="12" xs="12">
              <div class="mb-8"></div>
            </v-col>
          </v-row>
          <!-- !! end of form -->

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import sleep from '@/lib/sleep'

import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// filters
import isSpKey from '@/filters/isSpecialCharacter'
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format

// !![2021.10.12]lwc vuex 모듈 불러오기
import { createNamespacedHelpers } from 'vuex'

// !! lwc vuex 모듈
const _Lwc_ = createNamespacedHelpers('lwc')

export default {
  components: {
    baseModal
  },

  props: [],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    mdiPlusBoxOutlineButtonColor: 'info darken-1',
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      teamId: 0, // 중요: 초기는 선택으로 픽스
      oriTeamId: 0, // 중요: 수정시 상세페이지에서 넘어온 원본팀아이디를 저장한다.
      type1: 1, // !! 1(소송)/2(자문) - 소송으로 fix
      type2: 1, // [1 ~ 5] 법원/검찰/경찰/헌재/기타
      gubun1: '', // 상위 소송/자문 분야
      gubun2: '', // 하위 소송/자문 분야
      gubun3: '1심', // 심급
      gubun4: '전자', // 진행방식
      courtName: '', // 관할명
      caseNum: '', // 사건번호(중복체크 대상임)
      caseName: '', // 사건명
      manageNum: '', // 관리번호(중복체크 대상임)
      clType: '', // 의뢰인유형
      opType: '', // 상대방유형
      clName: '', // 의뢰인이름
      opName: '', // 상대방이름
      clGusok: 0, // 의뢰인구속여부
      opGusok: 0, // 상대방구속여부
      jepanbu: '', // 재판부
      requestBill: '', // 청구금액
      status: 0, // 관리상태 정수 - 카테고리 참고
      status2: '', // 관리상태 문자열 - 카테고리 참고
      mgroup1: '', // 관리그룹 1
      mgroup2: '', // 관리그룹 2
      isStar: 0, // 중요표시 : 별
      // !![2021.10.12 추가]
      receiveDate: null, // 접수일(자문요청일)
      suimDate: null, // 수임일(자문마감일)
      endDate: null, // 종국일
      endType: '', // 종국결과
      endMemo: '', // 상세결과
      endResult: '', // 결과평가
      appealPlan: '' // 향후계획
    },
    // 구분:
    teams: [], // 소속팀
    // 구분: 관리그룹 - 차후 5개까지 확장가능
    mGroups: [
      {
        id: 0,
        title: '',
        value: '',
        selects: [
          { text: '선택', value: '' }
        ]
      },
      {
        id: 0,
        title: '',
        value: '',
        selects: [
          { text: '선택', value: '' }
        ]
      }
    ],
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        teamId: '담당부서', // !! [2021.1.26]담당부서 추가
        caseNum: '사건번호',
        caseName: '사건명',
        clName: '의뢰인명'
      }
    },
    // 구분: 기관 셀렉트
    courtTypes: [
      { text: '법원', value: 1 },
      { text: '검찰', value: 2 },
      { text: '경찰', value: 3 },
      { text: '헌법재판소', value: 4 },
      { text: '기타', value: 5 }
    ],
    // 구분:중요: 소송분야 카테고리를 재패칭한 배열
    caseCates: [],
    // 구분: 소송분야 상위 셀렉트
    gubun1Select: [
      { text: '선택', value: '' }
    ],
    // 구분: 소송분야 하위 셀렉트
    gubun2Select: [
      { text: '선택', value: '' }
    ],
    // 구분: 심급 셀렉트
    simGubuns: [
      { text: '선택', value: '' },
      { text: '1심', value: '1심' },
      { text: '2심', value: '2심' },
      { text: '3심', value: '3심' }
    ],
    // 구분: 진행방식 셀렉트
    jinhengGubuns: [
      { text: '일반', value: '일반' },
      { text: '전자', value: '전자' }
    ],
    // 구분: 관리상태 셀렉트 - !! [2021.2.26] 카테고리로 변경
    statusSelect: [],
    // 구분: 사건번호 중복체크용 변수들
    mustChk1: false, // 중복체크를 해야 하는지(계약번호에 입력된 경우)
    chkMulti1: false, // 참고: 중복 확인했는지 여부
    isOverlabed1: false, // 참고: 중복 되었는지 여부
    // 구분: 관리번호 중복체크용 변수들
    mustChk2: false, // 중복체크를 해야 하는지(계약번호에 입력된 경우)
    chkMulti2: false, // 참고: 중복 확인했는지 여부
    isOverlabed2: false, // 참고: 중복 되었는지 여부
    // 구분: 자동완성키워드 객체
    keyword: {
      courtName: [], // 관할명
      caseName: [], // 사건명
      dangType: [], // 중요:[2021.4.9] 당사자유형
      // clType: [], // 의뢰인유형
      // opType: [], // 상대방유형
      jepanbu: [] // 재판부
    },
    // 구분:[2021.4.9 추가] 자동완성키워드용 캡션
    kwCaption: {
      courtName: '관할',
      caseName: '사건명',
      dangType: '당사자유형', // 중요:[2021.4.9 추가]
      jepanbu: '재판부'
    },
    // 구분: 자동완성키워드 검색변수 용도
    sCourtName: null, // !! 관할명 찾기
    sCaseName: null, // !! 사건명 찾기
    // sClType: null, // !! 의뢰인유형 찾기
    // sOpType: null, // !! 상대방유형 찾기
    sDangType: null, // !![2021.4.9] 당사자유형찾기
    sJepanbu: null, // !! 재판부 찾기
    // 구분:
    index: -1 // !! 수정 인덱스
  }),

  watch: {
    'form.caseNum': { // !! 사건번호 자동감지 & 중복체크
      handler: function (val, oldVal) {
        if (val.length > 0) { // 값이 있으면
          if (isSpKey(val)) { // 특수문자 필터링
            this.form.caseNum = oldVal
            this.$refs.txtCaseNum.lazyValue = oldVal
          }
          // this.mustChk1 = true // 중복체크 해야 함
          this.mustChk1 = false // !![2021.10.6] 중복체크 필수를 풀었다
          this.chkMulti1 = false // 중복확인을 안했다
          this.isOverlabed1 = false // 중복 아니다.
        } else { // 내용이 아예 없으면 중복 체크하지 않아도 됨
          this.mustChk1 = false // 중복체크 안해도 됨
          this.chkMulti1 = false // 중복확인을 안했다
          this.isOverlabed1 = false // 중복 아니다.
        }
      }
    },
    'form.manageNum': { // !! 관리번호 자동감지 & 중복체크
      handler: function (val, oldVal) {
        if (val.length > 0) { // 값이 있으면
          if (isSpKey(val)) { // 특수문자 필터링
            this.form.manageNum = oldVal
            this.$refs.txtManageNum.lazyValue = oldVal
          }
          this.mustChk2 = true // 중복체크 해야 함
          this.chkMulti2 = false // 중복확인을 안했다
          this.isOverlabed2 = false // 중복 아니다.
        } else { // 내용이 아예 없으면 중복 체크하지 않아도 됨
          this.mustChk2 = false // 중복체크 안해도 됨
          this.chkMulti2 = false // 중복확인을 안했다
          this.isOverlabed2 = false // 중복 아니다.
        }
      }
    },
    'form.requestBill': {
      handler: function (val, oldVal) {
        if (val.length > 0) {
          if (val.length > 1 && val[0] === '0') {
            this.form.requestBill = val.substr(1)
          } else {
            // 1) 숫자중 쉼표를 없애고
            this.form.requestBill = numberFormatR(this.form.requestBill)
            // 2) 숫자3자리 마다 쉼표를 찍는다.
            this.form.requestBill = numberFormat(this.form.requestBill)
          }
        }
      }
    }
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  // 중요: 2021.1.26 커스텀룰추가 - 팀선택 셀렉트의 value 는 0보다 커야 한다.
  created () {
    this.$validator.extend(
      'greaterThanZero',
      {
        getMessage: field => field + ' 를 선택해야 합니다!',
        validate: (value) => {
          // value must be > zero
          if (value > 0) return true
          return false
        }
      })
  },

  computed: {
    // 중요: lwc 모듈 게터
    ..._Lwc_.mapGetters([
      'getTeamNameByTid'
    ])
  },

  methods: {
    isSpKey, // 특수문자 필터
    numberFormat,
    numberFormatR,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 자신이 속한 팀을 가져온다
    // 기본팀은 필수, (관리자팀,물품구매팀,회의실관리팀은 제외)
    async getTeams () {
      try {
        const { data } = await this.$axios.get('lawork/case/getMyBasicTeamInfo')
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        // 중요: [2021.1.26] 팀선택 셀렉트 맨 앞에 '선택' 을 넣는다.
        data.teams.unshift({ text: '선택', value: 0 })
        // console.log(data.teams)

        this.form.teamId = data.teams[0].value

        return data.teams
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 카테고리에서 관리그룹 패칭
    async getGroups () {
      try {
        // 카테고리 : 관리그룹 = 1
        this.setCates('1').then(({ cates }) => {
          if (cates && cates.length > 0) {
            for (let i = 0; i < cates.length; i++) {
              this.mGroups[i].id = cates[i].id
              this.mGroups[i].title = cates[i].gubun1

              // select 초기화 하고 다시 매칭
              this.mGroups[i].selects = [{ text: '선택', value: '' }]
              const subs = cates[i].sub.split('|')
              subs.forEach(sub => {
                this.mGroups[i].selects.push({ text: sub, value: sub })
              })
            }
          }
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // form 초기화
      // this.$refs.form.reset() // !! 이넘이 시간이 오래 걸려서 밑에 폼 초기화가 제대로 안된다!

      // 대신 form validation 초기화
      this.$validator.reset()

      this.form.id = 0

      this.form.teamId = 0 // 중요: 팀아이디 초기값
      this.form.oriTeamId = 0 // 수정시 사용하는 상세페이지에서 넘어온 원본팀아이디

      this.form.type1 = 1 // 소송/자문 - 소송으로 fix
      this.form.type2 = 1 // 기관 - 법원으로 fix
      this.form.gubun1 = '' // 소송분야 - 상위
      this.form.gubun2 = '' // 소송분야 - 하위
      this.form.gubun3 = '1심' // 심급 - 1심/2심/3심
      this.form.gubun4 = '전자' // 진행방식 - 일반/전자
      this.form.courtName = '' // 관할명
      this.form.caseNum = '' // 사건번호
      this.form.caseName = '' // 사건명
      this.form.manageNum = '' // 관리번호
      this.form.clType = '' // 의뢰인유형
      this.form.opType = '' // 상대방유형
      this.form.clName = '' // 의뢰인명
      this.form.opName = '' // 상대방명
      this.form.clGusok = 0 // 의뢰인구속여부
      this.form.opGusok = 0 // 상대방구속여부
      this.form.jepanbu = '' // 재판부
      this.form.requestBill = '' // 청구금액
      this.form.status = 0 // 상태 정수 - 카테고리 참고
      this.form.status2 = '' // 상태 문자열 - 카테고리
      this.form.mgroup1 = '' // 관리그룹 1
      this.form.mgroup2 = '' // 관리그룹 1
      this.form.isStar = 0 // 중요표시
      // !![2021.10.12 추가]
      this.form.receiveDate = null // 접수일(자문요청일)
      this.form.suimDate = null // 수임일(자문마감일)
      this.form.endDate = null // 종국일
      this.form.endType = '' // 종국결과
      this.form.endMemo = '' // 상세결과
      this.form.endResult = '' // 결과평가
      this.form.appealPlan = '' // 향후계획

      // 중요: 자동완성키워드도 초기화
      this.keyword.courtName = [] // 관할
      this.keyword.caseName = [] // 사건명
      this.keyword.dangType = [] // !![2021.4.9] 당사자명
      // this.keyword.clType = [] // 의뢰인유형
      // this.keyword.opType = [] // 상대방유형
      this.keyword.jepanbu = [] // 재판부

      this.index = -1 // 수정용 인덱스 초기화

      // 사건번호 중복체크
      this.mustChk1 = false
      this.chkMulti1 = false
      this.isOverlabed1 = false

      // 관리번호 중복체크
      this.mustChk2 = false
      this.chkMulti2 = false
      this.isOverlabed2 = false
    },
    // 구분: 팝업 오픈
    async open (title, options, index = -1, obj = {}) {
      this.dialog = true
      this.title = title
      this.options = Object.assign(this.options, options)

      // 소송분야(type1=2) 카테고리 패칭
      this.setCates('2').then(({ cates }) => {
        if (cates && cates.length > 0) {
          // 소송분야 상위 셀렉트 만들기
          this.gubun1Select.push(...cates.map(c => ({ text: c.gubun1, value: c.gubun1 })))
          // !! 모든 소송분야의 카테고리 재패칭
          this.caseCates.push(...cates.map(c => ({ key: c.gubun1, subs: c.sub.split('|') })))
        }
      })

      // !! [2021.2.26 추가] 관리상태 카테고리 만들기
      this.setCates('21').then(({ cates }) => {
        if (cates && cates.length > 0) {
          this.statusSelect.push(...cates.map(c => ({ text: c.gubun1, value: c.num })))
          // 관리상태의 초기값 지정
          this.form.status = this.statusSelect[0].value
        }
      })

      // 관리그룹 - 카테고리에서 가져온다
      await this.getGroups()

      // 초기화
      await this.initDialog()

      // 자신이 속한 팀리스트를 가져온다
      this.teams = await this.getTeams()

      // !!중요: - 자동완성 키워드 배열 패칭
      // DB 에서 패칭해 온다.
      this.keyword = await this.getKeywords()

      // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
      if (index > -1) {
        this.index = index

        this.form.id = obj.id

        this.form.teamId = obj.teamId

        this.form.oriTeamId = obj.teamId // 중요: 상세페이지에서 넘어온 원본팀아이디를 따로 저장해야 한다.

        this.form.type1 = obj.type1 // 소송(1)/자문(2)
        this.form.type2 = obj.type2 // [1 ~ 5] 법원/검찰/경찰/헌재/기타
        // this.form.gubun1 = obj.gubun1 // 소송분야 상위
        // this.form.gubun2 = obj.gubun2 // 소송분야 하위
        this.form.gubun3 = obj.gubun3 // 심급
        this.form.gubun4 = obj.gubun4 // 진행방식
        this.form.courtName = obj.courtName
        this.form.caseNum = obj.caseNum
        this.form.caseName = obj.caseName
        this.form.manageNum = obj.manageNum
        this.form.clType = obj.clType
        this.form.opType = obj.opType
        this.form.clName = obj.clName
        this.form.opName = obj.opName
        this.form.clGusok = obj.clGusok
        this.form.opGusok = obj.opGusok
        this.form.jepanbu = obj.jepanbu
        this.form.requestBill = obj.requestBill
        this.form.status = obj.status
        this.form.status2 = obj.status2
        this.form.mgroup1 = obj.mgroup1
        this.form.mgroup2 = obj.mgroup2
        this.form.isStar = obj.isStar
        // !![2021.10.12 추가]
        this.form.receiveDate = obj.receiveDate // 접수일(자문요청일)
        this.form.suimDate = obj.suimDate // 수임일(자문마감일)
        this.form.endDate = obj.endDate // 종국일
        this.form.endType = obj.endType // 종국결과
        this.form.endMemo = obj.endMemo // 상세결과
        this.form.endResult = obj.endResult // 결과평가
        this.form.appealPlan = obj.appealPlan // 향후계획

        // 수정인 경우 중복체크를 한걸로다가 ~ 시간상 지연을 주기위해 nextTick() 사용
        // 중요: [2021.10.6] 수정 - 중복체크가 필수가 아니게 되어서 풀어준다..
        this.$nextTick(function () {
          if (obj.caseNum) {
            this.mustChk1 = false // 중복체크 필요없다
            // this.chkMulti1 = true // 중복체크를 했다
            this.chkMulti1 = false // 중복체크 필요없다 !![2021.10.6] 수정
            this.isOverlabed1 = false // 중복되지 않았다
          } else {
            this.mustChk1 = false
            this.chkMulti1 = false
            this.isOverlabed1 = false
          }
        })
        // 관리번호
        this.$nextTick(function () {
          if (obj.manageNum) {
            this.mustChk2 = false // 중복체크 필요없다
            this.chkMulti2 = true // 중복체크를 했다
            this.isOverlabed2 = false // 중복되지 않았다
          } else {
            this.mustChk2 = false
            this.chkMulti2 = false
            this.isOverlabed2 = false
          }
        })

        // !! 수정인 경우 소송분야 상위 카테고리 처리
        this.$nextTick(async function () {
          if (obj.gubun1) {
            // 넘어온 소송분야 상위로 맞추고
            this.form.gubun1 = obj.gubun1
            // 상위카테고리로 하위 카테고리 셀렉트 만들기
            await this.changeGubun1()
          }
        })
        // !! 수정인 경우 소송분야 하위 카테고리 선택 처리
        this.$nextTick(function () {
          if (obj.gubun2) {
            this.form.gubun2 = obj.gubun2
          }
        })
      }

      // 프로미스를 리턴하면서 resolve를 매칭하고있다.
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    // 구분: 등록 버튼 이벤트 핸들러
    async agree () {
      try {
        // 중요: 자동완성 항목에 입력하고 바로 등록 버튼을 누르면 자동완성 항목이 비어있는 것으로 인식한다.
        // 아래처럼 약간의 시간텀을 주어야 제대로 된다.
        sleep(50).then(() => { this.ioProc() })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 자동완성의 즉각 반영 문제로 등록/수정을 실제로 하도록 한번 더 감싼 함수
    async ioProc () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        // if (!isValidate) throw new Error('입력값을 확인해 주세요')
        if (!isValidate) throw new Error(`입력값을 확인해 주세요. - ${this.errors.items[0].msg}`)

        // !! 사건번호 중복체크 확인
        if (this.mustChk1 && !this.chkMulti1) { // 중복체크를 해야 하는데 하지 않았다
          this.isOverlabed1 = true // 중복여부를 가져오진 않았지만 수동으로 에러를 만든다.
          this.$refs.txtCaseNum.focus()
          throw new Error('중복체크를 하셔야 합니다.')
        }

        // !! 관리번호 중복체크 확인
        if (this.mustChk2 && !this.chkMulti2) { // 중복체크를 해야 하는데 하지 않았다
          this.isOverlabed2 = true // 중복여부를 가져오진 않았지만 수동으로 에러를 만든다.
          this.$refs.txtManageNum.focus()
          throw new Error('중복체크를 하셔야 합니다.')
        }

        // 중요: [2021.2.26] 관리상태의 변경으로 추가됨
        // 선택한 관리상태의 정수값(status)로 문자열값을 만들어준다
        this.form.status2 = this.statusSelect.find(s => s.value === this.form.status).text

        // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        // !! 돌려주는 객체
        let resolve = {
          affectedId: 0,
          isDeleted: false,
          teamError: false,
          chInfo: null
        }

        if (this.index > -1) {
          // !! 편집 모드인 경우
          const { data } = await this.$axios.post('lawork/case/edit', this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
          resolve.affectedId = data.editedId
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
        } else {
          // !! 입력 모드인 경우
          const { data } = await this.$axios.post(`lawork/case/add`, this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          resolve.affectedId = data.insertId
          resolve.isDeleted = false
          resolve.teamError = false
          resolve.chInfo = null
        }

        // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        // 중요:!![2021.4.9] 키워드 관리 대대적인 수정 - 새로운 키워드가 있는 경우 등록한다.
        let sendKeyword = [] // 새로운 키워드가 있는 경우 이 배열에 넣고 백앤드로 보내서 입력한다
        let newKeyword = false // 새로 들어간 키워드가 1개라도 있으면 true

        // !! 순회하면서 폼의 필드값이 자동완성 배열에 기존에 없던 넘이면 맨 앞에 넣어준다.
        // 중요:[2021.4.9] 소송/자문의 등록/수정 창에만 당사자유형을 처리하기 위해 아래처럼 해줘야 한다!
        for (let field in this.keyword) {
          // 중요: 당사자유형인 경우 특별처리 - 의뢰인/상대방 타입을 일일히 처리해야 한다.
          if (field === 'dangType') {
            // 의뢰인 유형
            let newDT = false // 의뢰인/상대방 둘 중 하나 혹은 둘다 업데이트 된경우를 캐치하는 플래그
            if (this.form['clType'] && !this.keyword[field].includes(this.form['clType'])) {
              this.keyword[field].push(this.form['clType'])
              newKeyword = true
              newDT = true
            }
            // 상대방유형
            if (this.form['opType'] && !this.keyword[field].includes(this.form['opType'])) {
              this.keyword[field].push(this.form['opType'])
              newKeyword = true
              newDT = true
            }
            //
            if (newDT) {
              // 새로운 의뢰인/상대방 유형이 있는 경우 배열에 푸시
              sendKeyword.push({ field, caption: this.kwCaption[field], keywords: this.keyword[field] })
            }
          } else {
            // !! 당사자유형이 아닌경우는 예전과 동일하게 편하게 처리한다.
            if (this.form[field] && !this.keyword[field].includes(this.form[field])) {
              // this.keyword[field].unshift(this.form[field]) // 얘는 앞에다가?
              this.keyword[field].push(this.form[field])
              newKeyword = true
              sendKeyword.push({ field, caption: this.kwCaption[field], keywords: this.keyword[field] })
            }
          }
        }

        // !! 새로운 추가 키워드가 있으면 백앤드로 보내서 입력한다.
        // 기존과 다르게 배열을 넘기는 lawork/lwc/setKeywordArray 로 보낸다.
        if (newKeyword) {
          const { data } = await this.$axios.post(`lawork/lwc/setKeywordArray`, { sendKeyword })
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        }

        // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        await this.initDialog()
        this.resolve(resolve) // !! 2021.2.2 팀에러 처리를 위해 객체를 리턴하는 것으로 수정됨
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 취소
    async cancel () {
      try {
        // 초기화
        await this.initDialog()
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:
    // [util함수] 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 그룹추가 팝업 열기
    async addMGroup (group, index) {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'addMgroupDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 부모 그룹 타이틀과 아이디를 넘긴다
        const returnValue = await pop.open(group.title, group.id)
        // 정상적으로 등록이 되었으면 - 그넘으로 선택한다.
        if (returnValue) {
          await this.getGroups() // 셀렉트 리프레시 하고
          this.form[`mgroup${index + 1}`] = returnValue // 선택
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 사건번호 중복체크
    // 중요:[2021.10.6] 사건번호 중복체크는 이제 필수값이 아니다. 스킵하고 넘어가도 된다.
    // !![2021.10.12] 관할명 + 사건번호로 중복을 체크하도록 수정
    async chkCaseNumOverlap () {
      try {
        // !! this.mustChk1 = false 면 중복체크가 필요없으므로 DB 쿼리를 하지 않는다.
        // if (this.form.caseNum.length > 0 && this.mustChk1) {
        // !!주의:[2021.10.6] 이제 중복체크는 필수가 아니므로 this.mustChk1 는 조건에서 없앴다
        if (this.form.caseNum.length > 0) {
          // !![2021.10.12] 관할명이 없는 경우 리턴
          if (!this.form.courtName) {
            this.$store.commit('SB_POP', { msg: '관할을 먼저 선택해 주십시오.', color: 'warning', shaped: true })
            this.$refs.refCourtName.focus() // 관할명으로 포커스 이동
            return
          }

          const { data } = await this.$axios.get(`lawork/case/chkCaseNumOverlap/${this.form.courtName}/${this.form.caseNum}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.chk) { // 중복 되었다
            const { tIds } = data // 넘어온 팀아이디 배열
            let chkMsg = ''
            if (tIds.length > 1) { // 복수라면 첫번째 팀만 보이고 '~팀외 ~' 처리
              chkMsg = `${this.getTeamNameByTid(tIds[0])}외 ${tIds.length - 1}`
            } else {
              chkMsg = `${this.getTeamNameByTid(tIds[0])}`
            }

            this.mustChk1 = false // 중복되어도 등록할 수 있으니 풀어준다
            this.chkMulti1 = true // 역시 중복이어도 체크한걸로 넘어간다
            this.isOverlabed1 = false // 에러체크도 풀어준다.
            this.$refs.txtCaseNum.focus()
            // throw new Error(`이미 등록된 사건입니다. 쌍방대리금지위반이 아니라면 등록을 계속하십시오.`)
            const _msg_ = `이미 등록된 사건입니다(${chkMsg}). 쌍방대리금지위반이 아니라면 등록을 계속하십시오.`
            this.$store.commit('SB_POP', { msg: _msg_, color: 'warning', shaped: true })
          } else {
            this.mustChk1 = false
            this.chkMulti1 = true
            this.isOverlabed1 = false
            this.$store.commit('SB_POP', { msg: '등록할 수 있는 사건입니다.', color: 'success' })
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 사건번호 텍스트박스의 keyup 이벤트 - 중복체크 리셋
    async chkCaseNumOverlapReset (e) {
      // 체크했는지 여부와 중복여부를 리셋시킨다.
      this.chkMulti1 = false
      this.isOverlabed1 = false
    },
    // 구분: 관리번호 중복체크
    async chkManageNumOverlap () {
      try {
        // !! this.mustChk2 = false 면 중복체크가 필요없으므로 DB 쿼리를 하지 않는다.
        if (this.form.manageNum.length > 0 && this.mustChk2) {
          const { data } = await this.$axios.get(`lawork/case/chkManageNumOverlap/${this.form.manageNum}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.chk) { // 중복 되었다
            this.mustChk2 = true // 중복되었으니 다시 체크해야 한다
            this.chkMulti2 = false // 중복확인 여부 > 중복체크를 다시 해야 한다
            this.isOverlabed2 = true // 중복되었다.
            this.$refs.txtManageNum.focus()
            // throw new Error(`이미 사용중인 관리번호입니다.`)
            const _msg_ = `이미 사용중인 관리번호입니다.(${data.teamName})`
            this.$store.commit('SB_POP', { msg: _msg_, color: 'warning', shaped: true })
          } else {
            this.mustChk2 = false
            this.chkMulti2 = true
            this.isOverlabed2 = false
            this.$store.commit('SB_POP', { msg: '등록할 수 있는 관리번호입니다.', color: 'success' })
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: [2021.10.12] 관할 셀렉트 변경시 이벤트 핸들러
    async changeCourtName () {
      try {
        // 사건번호 중복체크를 풀어준다.
        this.mustChk1 = false
        this.chkMulti1 = false
        this.isOverlabed1 = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 소송분야 상위 셀렉트 체인지 이벤트 핸들러
    // DB 에 재전송 하지 않게 미리 open() 함수에서 넣어둔 this.caseCates 배열을 이용한다.
    async changeGubun1 () {
      try {
        // 소송분야 하위를 초기화 하고
        this.gubun2Select = [{ text: '선택', value: '' }]
        this.form.gubun2 = ''
        // 상위 선택이 있으면 하위 셀렉트를 만든다.
        if (this.form.gubun1 && this.form.gubun1.length > 0) {
          this.gubun2Select.push(...this.caseCates.find(c => c.key === this.form.gubun1).subs)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 자동완성 키워드 필터 -
    // itemText 에서 queryText 를 찾는거라 보면됨
    ackFilter (item, queryText, itemText) {
      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.indexOf(query) > -1
    },
    // 구분: 자동완성 키워드 목록 패칭
    async getKeywords () {
      try {
        // !! 현재 페이지의 키워드 요청: this.keyworkd 의 key 배열만 fields 변수에 넣어서 요청
        const { data } = await this.$axios.post('lawork/lwc/getKeywords', { fields: Object.keys(this.keyword) })
        if (!data.success) throw new Error(`자동완성 키워드를 가져오지 못했습니다: ${data.message}`)
        // console.log(data)
        return data.keyword
      } catch (e) {

      }
    }
  }
}
</script>
