// isNumber.js
// 단순히 숫자만 입력받는게 아니라
// 금액 입력에 사용되기 위해
// '00' 이나 '01' 처럼 의미없는 입력도 제한한다!
export default (val) => {
  return !val.replace(/[^0-9]/g, '') || // 1) 숫자만 남기고 제거한 후 값이 없으면 true or
    !/^[0-9]*$/.test(val) || // 2) 숫자 이외의 값이 있으면 true
    (val.length === 2 && val === '00') || // 추가 1: 00 으로 시작되지 못하게 막는다.
    (val.length && /^0\d$/g.test(val)) // 추가 2: 0(\d) 01, 03 으로 시작되지 못하게 막는다.
}
