<template>
<v-footer padless dark>
    <v-card
      flat
      tile
      class="flex white--text"
      style="background-color: #4c5459;"
    >
      <v-card-title class="primary">
        <v-spacer class="hidden-sm-and-down"></v-spacer>
        <v-btn
          small depressed color="grey lighten-5 grey--text text--darken-3"
          style="background-color: #4c5459;cursor: pointer;"
          @click="goTermsOfUse"
        >
          이용약관
        </v-btn>
        <v-btn
          small depressed color="grey lighten-5 grey--text text--darken-3"
          class="mx-2"
          style="background-color: #4c5459;cursor: pointer;"
          @click="goPrivacy"
        >
          개인정보취급방침
        </v-btn>
        <span style="font-size: 0.8rem; font-weight: 400; letter-spacing: .03333em;">
          T.02-521-7505 / F.02-6008-7532 / 개인정보정책 책임자:서지원
        </span><br>
      </v-card-title>
      <v-card-text class="py-2" :class="$vuetify.breakpoint.sm ? 'text-left' : 'text-right'">
        <span style="font-size: 0.8rem; font-weight: 400; letter-spacing: .03333em;">
          (주)현우엘앤씨 / 대표:서지원 / 사업자등록번호:206-86-21363 / 통신판매업신고번호:12-서울강남-20946 서울 서초구 서초대로50길 19, 321(서초동)<br>
          (주)현우열앤씨의 모든 프로그램, 상호, 서비스 등은 특허법, 프로그램보호법 등에 의해 보호받고 있습니다.
        </span>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  methods: {
    dummy () {
      console.log('dummy test')
    },
    // 구분: 개인정보처리방침
    goPrivacy () {
      // 1) 다른 탭에서 열기
      // let route = this.$router.resolve({ path: '/privacy' })
      // window.open(route.href, '_blank')
      // 현재 path 가 /privacy 가 아니면 이동
      if (this.$router.currentRoute.path !== '/privacy') {
        this.$router.push('/privacy')
      }
    },
    // 구분: 이용약관
    goTermsOfUse () {
      // 현재 path 가 /termsofuse 가 아니면 이동
      if (this.$router.currentRoute.path !== '/termsofuse') {
        this.$router.push('/termsofuse')
      }
    }
  }
}
</script>
