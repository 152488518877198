<template>
  <v-card
    tile
    :elevation="0"
  >
    <v-row no-gutters>
      <!-- 좌측 컨텐츠 시작 -->
      <v-col cols="12" md="6" lg="6" xl="6" class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-cog-refresh</v-icon> 계산조건
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              class="mr-2"
              @click.stop="doCalc"
            >
              <v-icon small>mdi-led-on</v-icon>
              계산
            </v-btn>
            <v-btn
              small
              @click.stop="doInit"
            >
              <v-icon small>mdi-refresh</v-icon>
              초기화
            </v-btn>
          </v-toolbar>
          <v-card
            tile
            outlined
            elevation="0"
            class="pa-2"
            min-height="568"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" class="py-0">
                <v-radio-group v-model="rbChk" row>
                  <v-radio label="초일불산입" value="초일불산입"></v-radio>
                  <v-radio label="초일산입" value="초일산입"></v-radio>
                </v-radio-group>
                <!-- <v-btn-toggle
                  v-model="rbChk"
                  color="primary"
                  dense
                  group
                  class="ma-2"
                >
                  <v-btn text value="초일불산입">
                    <v-icon small>
                      {{ rbChk === '초일불산입' ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                    </v-icon>
                    초일불산입
                  </v-btn>
                  <v-btn text value="초일산입">
                    <v-icon small>
                      {{ rbChk !== '초일불산입' ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                    </v-icon>
                    초일산입
                  </v-btn>
                </v-btn-toggle> -->
              </v-col>
              <v-col cols="12" class="pb-2">
                <span class="text-body-2 primary--text">기준일</span>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <v-col cols="12">
                    <v-menu
                      ref="date1Selector"
                      v-model="date1Selector"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model.trim="sDay"
                          placeholder="예) 2020-01-01"
                          label=""
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          dense
                          outlined
                          filled
                          style="max-width: 180px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="sDay"
                        locale="ko"
                        @change="saveDate1"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="pb-2">
                <span class="text-body-2 primary--text">기준일로 부터</span>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <div class="font-weight-bold mt-2 mr-2">1.</div>
                    <v-menu
                      ref="date2Selector"
                      v-model="date2Selector"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model.trim="cDay1"
                          placeholder="예) 2020-01-01"
                          label=""
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          dense
                          outlined
                          filled
                          style="max-width: 180px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker2"
                        v-model="cDay1"
                        locale="ko"
                        @change="saveDate2"
                        :min="cDay1MinDate"
                      ></v-date-picker>
                    </v-menu>
                  <div class="font-weight-bold mt-2 ml-2">까지의 기간은?</div>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <div class="font-weight-bold mt-2 mr-2">2.</div>
                  <v-text-field
                    ref="numCday2"
                    v-model.trim="cDay2"
                    placeholder="예) 14"
                    maxlength="4"
                    label=""
                    dense
                    outlined
                    filled
                    style="max-width: 200px;"
                  ></v-text-field>
                  <div class="font-weight-bold mt-2 ml-2">일째 되는 날은?</div>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <div class="font-weight-bold mt-2 mr-2">3. D-</div>
                  <v-text-field
                    ref="numCday3"
                    v-model.trim="cDay3"
                    placeholder="예) 7"
                    maxlength="5"
                    label=""
                    dense
                    outlined
                    filled
                    style="max-width: 150px;"
                  ></v-text-field>
                  <div class="font-weight-bold mt-2 ml-2">일째 되는 날은?</div>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
      <!-- <v-divider vertical class="ma-0 pa-0"></v-divider> -->
      <!-- 우측 컨텐츠 시작 -->
      <v-col class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-cube-send</v-icon> 계산결과
            <v-spacer></v-spacer>
            <v-btn
              small
              @click="doCopy"
              dark
            >
              <v-icon small>mdi-content-copy</v-icon>
              클립보드복사
            </v-btn>
          </v-toolbar>
          <v-card
            id="copyArea"
            tile
            outlined
            elevation="0"
            class="pa-2"
            min-height="568"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12">
                <v-chip
                  label
                  outlined
                  :color="rbChk === '초일불산입' ? 'primary' : 'warning'"
                  class="font-weight-bold mb-3"
                >
                  <!-- <v-icon
                    small
                    class="mr-2"
                  >
                    {{ rbChk === '초일불산입' ? 'mdi-calendar-remove' : 'mdi-calendar-plus' }}
                  </v-icon> ◐ -->
                  {{ rbChk === '초일불산입' ? '◐' : '◑' }}
                  {{ rbChk }}
                </v-chip>
                <div
                  v-show="res1msg1 && res1msg2"
                  class="mt-2 mb-8 mr-2"
                >
                  1. {{ res1msg1 }}<br>
                  <span class="text-body-2 font-weight-bold ma-1 error--text">{{ res1msg2 }}일</span>
                </div>
                <div
                  v-show="res2msg1 && res2msg2"
                  class="mt-2 mb-8 mr-2"
                >
                  2. {{ res2msg1 }}<br>
                  <span class="text-body-2 font-weight-bold ma-1 error--text">{{ res2msg2 }}</span>
                </div>
                <div
                  v-show="res3msg1 && res3msg2"
                  class="mt-2 mb-8 mr-2"
                >
                  3. {{ res3msg1 }}<br>
                  <span class="text-body-2 font-weight-bold ma-1 error--text">{{ res3msg2 }}</span>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import copy from 'copy-to-clipboard' // !! 클립보드 복사 플러그인
import ko from 'vee-validate/dist/locale/ko'
import isNumber from '@/filters/isNumber'

export default {
  components: {
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    rbChk: '초일불산입', // 초일불산입/초일산입 라디오 버튼 초기값 - 문자열임을 주의
    sDay: '', // 기준일
    cDay1: '', // 1번째 적용일자
    cDay2: '',
    cDay3: '',
    date1Selector: false, // 기준일 셀렉터
    date2Selector: false, // 적용일 셀렉터
    cDay1MinDate: '', // cDay1 의 미니멈일자 > 기준일에 의해서 적용되어진다.
    res1msg1: '',
    res1msg2: '',
    res2msg1: '',
    res2msg2: '',
    res3msg1: '',
    res3msg2: '',
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        sDay: '기준일'
      }
    }
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    this.doInit()
  },

  watch: {
    'cDay2': { // 2. 번째 입력 날자 - 숫자만 입력
      handler: function (val, oldVal) {
        if (val.length > 0) { // 이게 없으면 무한루프 걸릴 수 있다.
          // 1) 정상적인 숫자값이 아닌 경우는 isNumber() 로 처리
          // 2) 0으로 시작할 수 없다. 첫번째 값이 0이면 안된다
          if (isNumber(val) || val[0] === '0') {
            this.cDay2 = oldVal
            this.$refs.numCday2.lazyValue = oldVal
          }
        }
      }
    },
    'cDay3': { // 디-데이 - 숫자만 입력
      handler: function (val, oldVal) {
        if (val.length > 0) { // 이게 없으면 무한루프 걸릴 수 있다.
          // 1) 정상적인 숫자값이 아닌 경우는 isNumber() 로 처리
          // 2) 0으로 시작할 수 없다. 첫번째 값이 0이면 안된다
          if (isNumber(val) || val[0] === '0') {
            this.cDay3 = oldVal
            this.$refs.numCday3.lazyValue = oldVal
          }
        }
      }
    }
  },

  methods: {
    isNumber, // 숫자만 인지 체크하는 필터
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 구분: 초기화
    async doInit () {
      try {
        // !! form validation 초기화
        this.$validator.reset()

        this.rbChk = '초일불산입' // 초일불산입/초일산입 라디오 버튼 초기값 - 문자열임을 주의
        this.sDay = this.$moment().format('YYYY-MM-DD') // 기준일 초기값(오늘)
        this.cDay1 = ''
        this.cDay2 = ''
        this.cDay3 = ''

        // !! 기준일이 정해지면 1번 적용일의 미니엄 일자가 자동으로 지정된다!
        this.cDay1MinDate = new Date(this.sDay).toISOString().slice(0, 10)

        this.res1msg1 = ''
        this.res1msg2 = ''
        this.res2msg1 = ''
        this.res2msg2 = ''
        this.res3msg1 = ''
        this.res3msg2 = ''
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 계산 실행
    async doCalc () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 초일불산입(true), 초일산입(false)
        let isChoil = (this.rbChk === '초일불산입')

        const sd = this.$moment(this.sDay) // 기준일 날짜변환(뷰를 위한)

        // !! 계산의 기준이 되는 날(초일불산입이 기준임)
        let csd = this.$moment(this.sDay)
        if (!isChoil) { // !! 초일 산입
          csd = csd.add(-1, 'days') // 초일산입때는 1일을 더 뺀다.
        }
        const sdYoil = this.$moment.weekdaysShort(sd.day())

        // !! 1) 번 계산
        if (this.cDay1) { // 1번 항목이 비어있지 않은 경우 계산한다
          const ed = this.$moment(this.cDay1)
          const edYoil = this.$moment.weekdaysShort(ed.day())

          this.res1msg1 = `${sd.format('YYYY.MM.DD')}(${sdYoil}) ~ ${ed.format('YYYY.MM.DD')}(${edYoil})`
          this.res1msg2 = `: ${ed.diff(csd, 'days')}`
        }

        // !! 2) 번 계산
        if (this.cDay2) {
          const addDays = parseInt(this.cDay2, 10)
          this.res2msg1 = `${sd.format('YYYY.MM.DD')}(${sdYoil}) + ${addDays}일`

          const addedDay = csd.add(addDays, 'days')
          const yoil = this.$moment.weekdaysShort(addedDay.day())
          this.res2msg2 = `: ${addedDay.format('YYYY.MM.DD')}(${yoil})`
        }

        // !! 3) 번 계산
        if (this.cDay3) {
          const dDayNumber = parseInt(this.cDay3, 10)
          this.res3msg1 = `${sd.format('YYYY.MM.DD')}(${sdYoil}) - ${dDayNumber}일`

          // 주의: D-day 계산의 경우 초일불산입시 기준일-1 로 해야한다.
          let csd2 = this.$moment(this.sDay)
          if (isChoil) {
            csd2 = csd2.add(-1, 'days') // 초일산입인 경우 -1
          }

          const dDay = csd2.add(-dDayNumber, 'days')
          const yoil = this.$moment.weekdaysShort(dDay.day())
          this.res3msg2 = `: ${dDay.format('YYYY.MM.DD')}(${yoil})`
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 기준일 셀렉트 선택시 저장 함수
    saveDate1 (date) {
      this.$refs.date1Selector.save(date)
      // !! 기준일이 정해지면 1번 적용일의 미니엄 일자가 자동으로 지정된다!
      this.cDay1MinDate = new Date(this.sDay).toISOString().slice(0, 10)
    },
    // !! 적용일 셀렉트 선택시 저장 함수
    saveDate2 (date) {
      this.$refs.date2Selector.save(date)
    },
    // 구분: 클립보드에 복사하기
    // 주의: async 를 붙여선 안된다. 매뉴얼 참고
    async doCopy () {
      try {
        const elem = document.querySelector('#copyArea')
        if (!elem) throw new Error(`요청하는 엘리먼트가 없습니다.`)

        const headerTitle = '▣ 일반기간\n'
        await copy(`${headerTitle}${elem.innerText}`)
        this.$store.commit('SB_POP', { msg: '클립보드에 복사되었습니다.', color: 'success' })
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
