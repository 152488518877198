<template>
  <v-card
    tile
    :elevation="0"
  >
    <v-row no-gutters>
      <!-- 좌측 컨텐츠 시작 -->
      <v-col cols="12" md="6" lg="6" xl="6" class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-cog-refresh</v-icon> 계산조건
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              class="mr-2"
              @click.stop="doCalc"
            >
              <v-icon small>mdi-led-on</v-icon>
              계산
            </v-btn>
            <v-btn
              small
              @click.stop="doInit"
            >
              <v-icon small>mdi-refresh</v-icon>
              초기화
            </v-btn>
          </v-toolbar>
          <v-card
            tile
            outlined
            elevation="0"
            class="pa-2"
            min-height="568"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" class="pb-2">
                <span class="text-body-2 primary--text">정(등)본 도달일</span>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  align="center"
                  justify="start"
                >
                  <v-col cols="5">
                    <v-menu
                      ref="date1Selector"
                      v-model="date1Selector"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model.trim="dodalDay"
                          placeholder="예) 2020-01-01"
                          label=""
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          dense
                          outlined
                          filled
                          style="max-width: 180px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="dodalDay"
                        locale="ko"
                        @change="saveDate1"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="7">
                    <v-checkbox
                      v-model="zeroHour"
                      label="0시 도달"
                      dense
                      class="mt-0"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="pb-2">
                <span class="text-body-2 primary--text">상소기간</span>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <v-select
                    v-model="selDate"
                    :items="selDateOptions"
                    item-text="text"
                    item-value="value"
                    label=""
                    menu-props="auto"
                    class="ma-0"
                    dense
                    outlined
                    filled
                    style="max-width:200px;"
                  ></v-select>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-card
                  elevation="0"
                  min-height="50"
                  class="error--text mt-0 px-0"
                >
                  <v-alert
                    v-show="zeroHour"
                    dense
                    text
                    color="error"
                    class="text-caption"
                  >
                    <!-- <v-icon small color="error" class="mr-0">mdi-information-outline</v-icon> -->
                    ※ 공시송달의 경우, 추완항소가 이루어질 수 있습니다.
                  </v-alert>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
      <!-- <v-divider vertical class="ma-0 pa-0"></v-divider> -->
      <!-- 우측 컨텐츠 시작 -->
      <v-col class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-cube-send</v-icon> 계산결과
            <v-spacer></v-spacer>
            <v-btn
              small
              @click="doCopy"
              dark
            >
              <v-icon small>mdi-content-copy</v-icon>
              클립보드복사
            </v-btn>
          </v-toolbar>
          <v-card
            id="copyArea"
            tile
            outlined
            elevation="0"
            class="pa-2"
            min-height="568"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12">
                <v-alert
                  dense
                  text
                  color="info"
                  class="text-caption"
                >
                  <!-- <v-icon small color="info" class="mr-0">mdi-information-outline</v-icon> -->
                  ※ 상소기간만료일은 토.일.공휴일을 건너뛴 평일로 계산됩니다
                </v-alert>
              </v-col>
              <v-col cols="12">
                <div
                  v-show="res1msg1 && res1msg2"
                  class="mt-2 mb-2 mr-2"
                >
                  ● {{ res1msg1 }}<span class="text-body-2 font-weight-bold ma-1">{{ res1msg2 }}</span>
                </div>
                <div
                  v-show="res2msg1 && res2msg2"
                  class="mt-2 mb-8 mr-2"
                >
                  ● {{ res2msg1 }}<span class="text-body-2 font-weight-bold ma-1">{{ res2msg2 }}</span>
                </div>
                <div
                  v-show="res3msg1 && res3msg2"
                  class="mt-2 mb-8 mr-2"
                >
                  {{ res3msg1 }}<span class="text-body-2 font-weight-bold ma-1 error--text">{{ res3msg2 }}</span>
                </div>
                <div
                  v-show="res4msg1 && res4msg2"
                  class="mt-2 mb-8 mr-2"
                >
                  {{ res4msg1 }}<span class="text-body-2 font-weight-bold ma-1 error--text">{{ res4msg2 }}</span>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import copy from 'copy-to-clipboard' // !! 클립보드 복사 플러그인
// @: 공휴일 저장 파일
import holyday from '@/lib/holyday'

import ko from 'vee-validate/dist/locale/ko'

export default {
  components: {
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 공휴일 객체 - import 한 holyday 객체를 이변수에 매칭해야 한다!
    holyDay: {},
    dodalDay: '', // 도달일
    zeroHour: false, // 0시 도달 체크박스
    selDate: '14', // 선택일
    selDateOptions: [
      { text: '14일(2주일)', value: '14', type: 'd' },
      { text: '7일(1주일)', value: '7', type: 'd' },
      { text: '5일', value: '5', type: 'd' },
      { text: '3일', value: '3', type: 'd' },
      { text: '10일', value: '10', type: 'd' },
      { text: '20일', value: '20', type: 'd' },
      { text: '30일', value: '30', type: 'd' },
      { text: '1개월', value: '1m', type: 'M' },
      { text: '60일', value: '60', type: 'd' },
      { text: '90일', value: '90', type: 'd' },
      { text: '180일', value: '180', type: 'd' },
      { text: '6개월', value: '6m', type: 'M' },
      { text: '1년', value: '1y', type: 'y' },
      { text: '3년', value: '3y', type: 'y' },
      { text: '5년', value: '5y', type: 'y' }
    ],
    res1msg1: '정(등)본 도달일 : ',
    res1msg2: '',
    res2msg1: '',
    res2msg2: '',
    res3msg1: '1. 상소기간만료일 : ',
    res3msg2: '',
    res4msg1: '2. 재판확정일 : ',
    res4msg2: '',
    date1Selector: false, // 도달일 셀렉터
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        dodalDay: '도달일'
      }
    }
  }),

  created () {
    // 중요: import 한 holyday(공휴일) 객체를 지역변수에 담아야 한다
    this.holyDay = holyday
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    this.doInit()
  },

  watch: {
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 구분: 초기화
    async doInit () {
      try {
        // !! form validation 초기화
        this.$validator.reset()

        this.dodalDay = this.$moment().format('YYYY-MM-DD') // 도달일 초기값(오늘)
        this.date1Selector = false
        this.zeroHour = false
        this.selDate = '14'

        this.res1msg1 = '정(등)본 도달일 : '
        this.res1msg2 = ''
        this.res2msg1 = ''
        this.res2msg2 = ''
        this.res3msg1 = '1. 상소기간만료일 : '
        this.res3msg2 = ''
        this.res4msg1 = '2. 재판확정일 : '
        this.res4msg2 = ''
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 토.일.공휴일 여부를 따져서 재귀하는 함수
    calcWeekendHolyday (date) {
      try {
        const dfstr = date.format('YYYY-MM-DD')
        // !! 토.일.공휴일인 경우 1일 더해서 재귀
        if (date.day() === 0 || date.day() === 6 || this.holyDay[dfstr]) {
          this.calcWeekendHolyday(date.add(1, 'd'))
        }
        return date
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 계산 실행
    async doCalc () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        const sdate = this.$moment(this.dodalDay) // 기준일(도달일을 날자형 객체로 변환)
        const sdYoil = this.$moment.weekdaysShort(sdate.day()) // 기준일 요일
        this.res1msg2 = `${sdate.format('YYYY.MM.DD')}(${sdYoil})`

        // 선택 상소기간의 객체를 통째로 찾는다.
        const selDateObj = this.selDateOptions.find(s => s.value === this.selDate)

        // 선택한 상소기간의 값을 정수로 변환(selDateObj.value 를 변환해도 된다.)
        let selDateNum = parseInt(this.selDate, 10)

        // 1) 도달일로부터 날짜계산 - 주의: 토.일.공휴일 계산없이 순수 날짜만 합산한 것
        let tDate = sdate.add(selDateNum, selDateObj.type) // 날짜객체

        // 1-1) 0시 도달인 경우 -1 일
        if (this.zeroHour) {
          tDate = tDate.add(-1, 'd')
        }

        let tDay = tDate.format('YYYY.MM.DD') // 날짜 문자열 변수
        let tYoil = this.$moment.weekdaysShort(tDate.day()) // 날짜 요일

        // 2) 토.일.공휴일까지 계산한 날짜
        // https://momentjscom.readthedocs.io/en/latest/moment/01-parsing/12-moment-clone/
        let t2Date = this.calcWeekendHolyday(tDate.clone()) // 중요: 이렇게 복사하면 두 객체는 변경해도 독립적이 된다.
        let t2Day = t2Date.format('YYYY.MM.DD')
        let t2Yoil = this.$moment.weekdaysShort(t2Date.day()) // 날짜 요일

        // 3) 재판확정일
        let t3Date = t2Date.clone()
        t3Date = t3Date.add(1, 'd')
        let t3Day = t3Date.format('YYYY.MM.DD')
        let t3Yoil = this.$moment.weekdaysShort(t3Date.day()) // 날짜 요일

        // set view str
        // this.res2msg1 = `상소기간(${selDateObj.text})`
        this.res2msg1 = `상소기간: ${selDateObj.text} `
        this.res2msg2 = `= ${tDay}(${tYoil})`

        this.res3msg2 = `${t2Day}(${t2Yoil})` // 상소기간 만료일
        this.res4msg2 = `${t3Day}(${t3Yoil})` // 재판확정일
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 도달일 셀렉트 선택시 저장 함수
    saveDate1 (date) {
      this.$refs.date1Selector.save(date)
    },
    // 구분: 클립보드에 복사하기
    // 주의: async 를 붙여선 안된다. 매뉴얼 참고
    async doCopy () {
      try {
        const elem = document.querySelector('#copyArea')
        if (!elem) throw new Error(`요청하는 엘리먼트가 없습니다.`)

        const headerTitle = '▣ 상소기간\n'
        await copy(`${headerTitle}${elem.innerText}`)
        this.$store.commit('SB_POP', { msg: '클립보드에 복사되었습니다.', color: 'success' })
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
