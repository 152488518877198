// store/modules/todo.js
export default {
  namespaced: true,

  state: () => ({
    todos: []
  }),

  getters: {
    todoLength: state => {
      return state.todos.length
    },
    doneTodos: state => {
      return state.todos.filter(todo => todo.done)
    },
    doneTodosCount: (state, getters) => {
      return getters.doneTodos.length
    },
    getTodoById: (state) => (id) => {
      return state.todos.find(todo => todo.id === id)
    },
    // 모든 todo 를 리턴한다
    getAllTodos: state => {
      return state.todos
    }
  },

  mutations: {
    // order 순서 재지정
    setOrder (state) {
      state.todos.forEach((todo, i) => {
        todo.order = i + 1
      })
    },
    // 순서를 +1 한다
    orderPlusOne (state) {
      state.todos.forEach(todo => {
        todo.order++
      })
    },
    // 할일목록 앞에 추가한다.
    addTodo (state, payload) {
      state.todos.unshift(payload)
    },
    // 편집하기
    // 넘어온 payload 는 todo 객체다. 인덱스를 구해서 통째로 바꾼다.
    editTodo (state, payload) {
      const index = state.todos.findIndex(t => t.id === payload.id)
      // splice() 를 이용한 교체
      // index 위치를 삭제하고 그 자리에 객체를 넣는것
      state.todos.splice(index, 1, payload)
    },
    // 한일체크 처리 - 편집하기와 다른점이 있어서 따로 뺌
    // 순서를 변경하는 것만 아니면 필요없음
    chkDone (state, payload) {
      const index = state.todos.findIndex(t => t.id === payload.id)
      state.todos.splice(index, 1)
      if (payload.done) {
        state.todos.push(payload) // 맨밑에 삽입
      } else {
        state.todos.unshift(payload) // 맨위에 삽입
      }
    },
    // 넘어온 payload.index 에 해당하는 리스트를 삭제한다
    deleteTodo (state, payload) {
      const index = state.todos.findIndex(t => t.id === payload.id)
      state.todos.splice(index, 1)
    },
    // todos 배열을 모두 비운다
    emptyTodos (state) {
      state.todos = []
    },
    // 넘어온 객체로 todos를 만든다
    setTodos (state, payload) {
      state.todos = payload.todos
    }
  },

  actions: {
    // 순서를 +1 한다
    orderPlusOne ({ commit }) {
      commit('orderPlusOne')
    },
    // order 순서 재지정
    setOrder ({ commit }) {
      commit('setOrder')
    },
    // 할일목록에 추가한다.
    addTodo ({ commit }, payload) {
      commit('addTodo', payload) // 추가하고
      commit('setOrder') // 순서를 재지정한다
    },
    // 수정하기
    editTodo ({ commit }, payload) {
      commit('editTodo', payload)
    },
    // 한일체크 처리
    chkDone ({ commit }, payload) {
      commit('chkDone', payload) // 처리하고
      commit('setOrder') // 순서를 재지정한다
    },
    // 할일삭제 액션
    removeTodo ({ commit }, payload) {
      commit('deleteTodo', payload) // 추가하고
      commit('setOrder') // 순서를 재지정한다
    },
    // todos 배열 모두 비우기
    emptyTodos ({ commit }) {
      commit('emptyTodos')
    },
    // todos 배열을 넘어온 객체로 채운다
    setTodos ({ commit }, payload) {
      commit('emptyTodos') // 먼저 todos 를 비우고
      commit('setTodos', payload) // todos 를 채운다
    }
  }
}
