<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="confirm"
    v-bind:style="{ zIndex: options.zIndex}"
  >
      <!-- !![2021.8.4]다른곳과 다르게 보이기 위해 pa-1 를 주었다. 필요없으면 제거할 것 -->
      <v-card class="pa-1">

        <v-toolbar id="movePopArea" dense flat dark class="ma-0 pa-0" color="primary">

          <v-toolbar-items class="__move__pop">
            <v-btn
              dark text class="orange--text text-h6"
              @click.native="confirm"
            >
              확인
            </v-btn>
            <v-btn text icon
              @click="initVals"
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar-items>

          <v-spacer></v-spacer>

          <v-toolbar-title class="white--text">
            <v-icon
              style="font-size: 1rem;font-weight: 550;"
              class="mb-1"
            >mdi-sitemap</v-icon>
            조직도
          </v-toolbar-title>
          <!-- <div style="width: 130px" class="mx-3 hidden-xs-only">
            <v-select
              v-model="search.sf"
              :items="select.sf"
              item-text="text"
              item-value="value"
              dense
              class="mt-0"
              rounded
              flat
              hide-details
              solo-inverted
              style="font-size: 0.785rem !important"
            ></v-select>
          </div>
          <v-text-field
            v-model="search.sw"
            label="검색"
            append-icon="mdi-magnify"
            clearble
            class="hidden-xs-only"
            dense
            flat
            hide-details
            rounded
            solo-inverted
          ></v-text-field> -->

          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="warning"
          ></v-progress-linear>
        </v-toolbar>

        <v-card-text class="ma-0 pa-0">
          <v-container fluid class="ma-0 pa-0">

            <v-row no-gutters>
              <v-col cols="12" xs="12" class="mt-0 pb-4">
                <!-- !! list start -->
                <v-card
                  outlined
                  :elevation="0"
                  max-height="500"
                  min-height="500"
                  class="overflow-auto text-body-2 pa-1"
                >
                  <template
                    v-for="item in staffs"
                  >
                    <v-hover :key="item.id" v-slot:default="{ hover }">
                      <v-card
                        :elevation="hover ? 8 : 0"
                        class="my-1"
                      >
                        <v-list-item>
                          <v-list-item-icon class="mr-4">
                            <v-avatar
                              v-if="item.picture"
                              tile size="84"
                            >
                              <!-- <img :src="item.picture"> -->
                              <v-img
                                :lazy-src="lazyImg"
                                :src="item.picture"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>
                            <v-avatar
                              v-else
                              tile size="84" color="grey"
                            >
                              <v-icon>mdi-account-box</v-icon>
                            </v-avatar>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="text-body-2">
                              <div>
                                <span
                                  class="primary--text mr-2"
                                  :class="hover ? 'font-weight-bold' : ''"
                                  style="font-size: 0.975rem;font-weight: 500;"
                                >{{ item.name }}</span>
                                <span class="text-body-2 mr-1">
                                  {{ (item.rank && item.position) ? `${item.rank}|${item.position}` : item.rank || item.position }}
                                </span>
                              </div>
                              <div class="text-body-2 mt-1">
                                <span v-show="item.interphone" class="text-body-2 grey--text mr-1">T</span>
                                <span v-show="item.interphone" class="mr-2">{{ item.interphone }}</span>
                                <span v-show="item.hp" class="text-body-2 grey--text mr-1">M</span>
                                <span v-show="item.hp" class="mr-2">{{ item.hp }}</span>
                                <span v-show="item.email" class="text-body-2 grey--text mr-1">E</span>
                                <span v-show="item.email">{{ item.email }}</span>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      v-show="item.email"
                                      v-on="on"
                                      class="ml-1"
                                      :style="hover ? 'font-size: 1.2rem;' : 'font-size: 0.9rem;'"
                                      color="primary"
                                      @click="writeEmail(item.email)"
                                    >
                                      {{ hover ? 'mdi-email' : 'mdi-email-outline' }}
                                    </v-icon>
                                  </template>
                                  <span>메일보내기</span>
                                </v-tooltip>
                              </div>
                              <div class="mt-1">
                                <span class="text-body-2 mx-1" v-html="item.view1"></span>
                              </div>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-hover>
                  </template>
                </v-card>
                <!-- !! list end -->
              </v-col>
              <!-- <v-col cols="12">
                <div class="mb-8"></div>
              </v-col> -->
            </v-row>

          </v-container>
        </v-card-text>

      </v-card>

  </v-dialog>
</template>

<script>
import sleep from '@/lib/sleep'

export default {
  // 조직도
  name: 'OrganChart',

  components: {
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    options: {
      color: 'primary',
      width: 400,
      height: 500,
      zIndex: 200
    },
    // 구분: 꼼수 - 헤더스에 별로 의미없는 1개만 매칭시킨다.
    headers: [
      {
        text: '',
        value: 'id',
        align: 'start', // !! left 가 아니라 start?
        sortable: false,
        width: '100%'
      }
    ],
    // 구분: 조직원 배열
    staffs: [],
    // 구분: 로딩
    loading: false,
    // 구분: v-model 과 params 로 백앤드로 전송되는 객체
    params: { // 검색용 인자 객체
      draw: 0,
      where: {},
      // * 다른 리스트와 달리 아래 변수는 사용하지 않지만 백앤드에 보내기위해 넣는다.
      sort: ['name'], // 주의: 정렬의 갯수만큼 초기값 지정해야 함
      order: ['ASC'],
      offset: 0,
      limit: 1000 // * 기본 리미트 1000
    },
    // 구분: 검색에 필요한 변수들 - 초기화 필요
    search: {
      sf: 1, // 검색어 검색 select 의 선택된 필드값
      sw: '' // 검색어 검색 text box input 값
    },
    // 구분: 셀렉트 객체들
    select: {
      sf: [ // 검색어 검색 필드 1 셀렉트
        { text: '이름', value: 1 },
        { text: '업무부서', value: 2 },
        { text: '직위', value: 3 },
        { text: '자격', value: 4 }
      ]
    },
    // 구분: 기타 변수들
    timeout: null, // delay 시 필요
    // 구분: 이미지 로딩시 기다릴때 이미지
    lazyImg: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZS8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiBmaWxsPSIjNDY0NjQ2IiByPSI0Ii8+PHBhdGggZD0iTTIwLDE5djFhMSwxLDAsMCwxLTEsMUg1YTEsMSwwLDAsMS0xLTFWMTlhNiw2LDAsMCwxLDYtNmg0QTYsNiwwLDAsMSwyMCwxOVoiIGZpbGw9IiM0NjQ2NDYiLz48L3N2Zz4='
  }),

  watch: {
    'search.sw': { // 검색어 자동 감지
      handler () {
        this.searchWordDelay()
      }
    }
  },

  created () {
  },

  mounted () {
    // this.initialize()
  },

  methods: {
    dummy () {
      console.log('dummy dummy ~ ')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message, color: 'error', timeout: 5000, shaped: true })
    },
    // 중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (const key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    initialize () {
      this.list()
    },
    // 참고: watch 로 검색시 약간의 딜레이를 줘야 한다.
    delay (ms = 800) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.list()
      }, ms)
    },
    // 검색어 검색시 딜레이 주는 함수
    searchWordDelay (ms = 800) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.searchWord()
      }, ms)
    },
    // 중요: 등록시 검색등에 쓰이는 변수 초기화하고 리스트 재패칭
    async initVals () {
      this.doInit().then(() => {
        this.delay(100)
      })
    },
    // 변수 초기화 실행
    doInit () {
      return new Promise((resolve, reject) => {
        // 구분: params 로 백앤드로 전송되는 값
        this.params.draw = 0
        this.params.where = {}
        this.params.sort = ['name'] // *[2022.5.25] 이름 가나다순 정렬
        this.params.order = ['ASC']
        this.params.offset = 0
        this.params.limit = 1000 // 기본 리미트 1000개 row 패칭

        // 구분: 검색용(v-model) 변수 - 초기값 설정이 필요
        this.search.sf = 1 // 검색어 검색 의 선택된 필드값
        this.search.sw = '' // 검색어 검색의 text box input 값

        // 구분: 기타 초기값 설정이 필요한 변수들
        this.staffs = [] // 조직원 배열

        this.loading = false

        resolve(true)
      })
    },
    // 구분: 열기 함수
    async open (options) {
      this.dialog = true
      this.options = Object.assign(this.options, options)

      // list
      await this.list()

      // 프로미스를 리턴하면서 resolve를 매칭하고있다.
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    // 구분: 리스트
    async list () {
      try {
        if (this.loading) return
        this.loading = true

        this.params.draw++

        // * 검색용 객체 만들기 - where 의 값이 없으면 삭제한다.
        const ws = this.params.where
        for (const key in ws) {
          if (!ws[key]) {
            delete ws[key]
          }
        }
        // console.log(this.params.where)

        // * 부드러운 로딩을 위해 ... 임의의 시간 딜레이를 두고 실행
        await sleep(700 - Math.floor(Math.random() * 300))

        const { data } = await this.$axios.get('admin/staff/list', { params: this.params })
        if (!data.success) throw new Error(`list error: ${data.message}`)

        // 총 검색 갯수(이게 주어져야 페이징이 된다)
        this.totalItems = data.totalItems

        // 참고: 데이터 리스트 순회하며 보여줄 데이터 생성
        await data.list.map(d => {
          d.view1 = ''
          if (d.teams && d.teams.length > 0) {
            let teamView = []
            d.teams.forEach(team => {
              if (team.name !== '회의실관리팀') { // !![2021.7.27] 회의실관리팀 제거(차후 복구)
                const colors = team.color.split(' ')
                const color2 = colors.length === 2 ? ` text--${colors[1]}` : ''
                const txtColor = `${colors[0]}--text${color2}`
                teamView.push(`<span class="${txtColor}">${team.name}</span>`)
              }
            })
            d.view1 = teamView.join(' | ')
          }
        })

        // 참고: 데이터 반영
        this.staffs = data.list

        this.loading = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:[검색] - 검색어 검색 처리 함수
    async searchWord () {
      try {
        if (this.search.sw.length > 0) { // 검색어가 있으면 파라미터에 넣고
          this.params.where.sf = this.search.sf
          this.params.where.sw = this.search.sw
        } else { // 없어도 일단 넣지만 값을 비운다. list() 에서 알아서 삭제된다.
          this.params.where.sf = ''
          this.params.where.sw = ''
        }

        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 확인 - 단순 닫기
    async confirm () {
      this.doInit() // 초기화
      this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
      this.dialog = false
    },
    // 구분: 이메일 쓰기(지메일) 바로가기
    async writeEmail (email) {
      try {
        const gmailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=${email}` // 쓰기로 바로간다.
        // const gmailUrl = `https://mail.google.com/mail/u/0/#inbox?compose=new` // 쓰기로 바로간다.
        const link = document.createElement('a')
        link.href = gmailUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
/* 2020.11.28 체크박스정렬이 깨져서 주석처리 */
/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
} */
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  구분: vue2editor css
*/
.vue2editorViewer p {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  /* padding-top: 2px !important; */
  /* padding-bottom: 2px !important; */
}
</style>
