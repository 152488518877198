<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{ zIndex: options.zIndex}"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-title class="title font-weight-regular">
        <v-icon class="mr-1">mdi-message-text</v-icon>{{ subtitle }}
      </v-card-title>
      <v-card-text>
        <!-- <v-textarea
          v-model="message"
          outlined
          label=""
          rows="5"
        ></v-textarea> -->
        <!-- vue2editor -->
        <vue-editor
          v-model="message"
          :editor-toolbar="customToolbar"
          style="height:200px;"
          class="mb-10"
        ></vue-editor>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="agree">확인</v-btn>
        <v-btn color="grey" text @click.native="cancel">취소(esc)</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// !! vue2editor
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    subtitle: null,
    options: {
      color: 'primary',
      width: 290,
      // height: 600,
      zIndex: 200
    },
    // 참고: 에디터 용
    customToolbar: [
      [ { 'header': [ false, 1, 2, 3, 4, 5, 6 ] } ],
      [ 'bold', 'italic', 'underline', 'strike' ],
      [ { 'header': 1 }, { 'header': 2 } ],
      [ { 'list': 'ordered' }, { 'list': 'bullet' } ],
      [ { 'color': [] }, { 'background': [] } ],
      [ 'link' ]
    ]
  }),

  methods: {
    open (title, subtitle, options) {
      this.dialog = true
      this.title = title
      this.subtitle = subtitle
      this.message = ''
      this.options = Object.assign(this.options, options)
      // 프로미스를 리턴하면서 resolve를 매칭하고있다.
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree () {
      this.resolve({
        result: true,
        msg: this.message
      })
      this.dialog = false
    },
    cancel () {
      this.resolve({
        result: false,
        msg: ''
      })
      this.dialog = false
    }
  }
}
</script>
