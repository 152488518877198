/**
 * !! key event 관련 공통함수 모음
 */

// keypress 이벤트에서 특수문자 입력을 막는 함수
const rmSpKeys = (e) => {
  // 특수문자 키코드 배열 -
  // 허용 : '_'(95) '-'(45) ','(44)
  const specialKeys = [
    32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 46, 47, 58, 59,
    60, 61, 62, 63, 64, 91, 92, 93, 94, 96, 123, 124, 125, 126
  ]
  if (specialKeys.includes(e.charCode)) {
    e.preventDefault()
  }
}

const rmSpChars = (value) => {
  const regExp = /[\s{}[\]/?.;:|)*~`!^+<>@#$%&\\=('"]/gi
  return value.replace(regExp, '')
}

export {
  rmSpKeys,
  rmSpChars
}
