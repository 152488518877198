<template>
  <v-app>
    <!-- 로그인 된 이후에 메뉴가 보이도록 v-if="$store.state.token" -->
    <v-navigation-drawer
      app
      v-if="$store.state.token"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      disable-route-watcher
    >
      <v-list dense>
        <v-row
          align="center"
          justify="center"
          no-gutters
          class="pl-5 pt-3 pb-3"
        >
          <!-- 구분: 알림 -->
          <v-col cols="3" xs="3">
            <v-btn small text icon
              @click="goToAlarmList"
            >
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-badge
                    v-model="notifyOn"
                    color="red"
                    overlap
                  >
                    <template v-slot:badge>
                      <span>!</span>
                    </template>
                    <v-icon
                      v-on="on"
                      xs-small
                      :color="notifyOn ? 'primary' : ''"
                    >
                      {{ notifyOn ? 'mdi-bell-ring' : 'mdi-bell-outline' }}
                    </v-icon>
                  </v-badge>
                </template>
                <span>알림</span>
              </v-tooltip>
            </v-btn>
          </v-col>
          <!-- 구분: 공지사항 -->
          <v-col cols="3" xs="3">
            <v-btn small text icon
              @click="goNoticePage"
            >
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-badge
                    v-model="noticeOn"
                    color="red"
                    overlap
                  >
                    <template v-slot:badge>
                      <span>!</span>
                    </template>
                    <v-icon
                      v-on="on"
                      xs-small
                      :color="noticeOn ? 'light-blue darken-2' : ''"
                    >
                      {{ noticeOn ? 'mdi-bullhorn' : 'mdi-bullhorn-outline' }}
                    </v-icon>
                  </v-badge>
                </template>
                <span>LAWORK공지</span>
              </v-tooltip>
            </v-btn>
          </v-col>
          <!-- 구분: 배터리 아이콘(남은 기간 표시) : @click.stop="dummy" 을 없애서 버튼기능 제거 -->
          <v-col cols="3" xs="3">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  class="ml-1"
                  :color="batteryColor"
                >
                  {{ batteryStat }}
                </v-icon>
              </template>
              <span>{{ batteryMsg }}</span>
            </v-tooltip>
          </v-col>
          <!-- 구분: 실무계산기 -->
          <v-col cols="3" xs="3">
            <v-btn small text icon
              @click="goCalcPop"
            >
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="light-blue darken-2"
                  >mdi-calculator</v-icon>
                </template>
                <span>실무계산기</span>
              </v-tooltip>
            </v-btn>
          </v-col>
          <!-- 사용안함:[2021.8.2] 판례/법령 -->
          <!-- <v-col cols="3" xs="3">
            <v-btn small text icon
              @click="goPrecedentSite"
            >
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="light-blue darken-2"
                  >mdi-bank-outline</v-icon>
                </template>
                <span>판례/법령</span>
              </v-tooltip>
            </v-btn>
          </v-col> -->
          <!-- 사용안함:[2021.8.2] 이메일 아이콘(지메일 바로가기) -->
          <!-- <v-col cols="3" xs="3">
            <v-btn small text icon @click="goGmail">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="primary">mdi-gmail</v-icon>
                </template>
                <span>G메일</span>
              </v-tooltip>
            </v-btn>
          </v-col> -->
          <!-- 구분: 개인정보 -->
          <v-col cols="3" xs="3">
            <v-btn small text icon
              @click="goMyInfo"
            >
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="indigo lighten-1"
                  >mdi-badge-account-horizontal-outline</v-icon>
                </template>
                <span>개인정보</span>
              </v-tooltip>
            </v-btn>
          </v-col>
          <!-- 구분:[2021.8.2 신설] 조직도 -->
          <v-col cols="3" xs="3">
            <v-btn small text icon
              @click="organChartPop"
            >
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="green darken-2"
                  >mdi-account-box-multiple-outline</v-icon>
                </template>
                <span>조직도</span>
              </v-tooltip>
            </v-btn>
          </v-col>
          <!-- 구분: 결제 아이콘: 관리(대행)자만 보임 -->
          <v-col cols="3" xs="3">
            <v-btn
              v-show="showPaymentIcon"
              small text icon
              @click="goPayment"
            >
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="ml-1"
                    color="orange darken-4"
                  >mdi-credit-card</v-icon>
                </template>
                <span>결제</span>
              </v-tooltip>
            </v-btn>
          </v-col>
          <!-- 구분: 비어있는 column: 차후 채워 넣던가.. -->
          <v-col cols="3" xs="3">
            <div></div>
          </v-col>
          <!-- !! 더보기 아이콘 -->
          <!-- <v-col cols="3" xs="3">
            <v-btn small text icon @click="dummy">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="primary"
                  >mdi-plus</v-icon>
                </template>
                <span>더보기</span>
              </v-tooltip>
            </v-btn>
          </v-col> -->
        </v-row>

        <v-divider class="mx-2"></v-divider>

        <!-- 구분: 할일목록 -->
        <v-hover v-slot="{ hover }">
          <v-list dense class="py-1">
            <v-list-item
              v-if="todoLength <= 0"
              class="px-2"
            >
              <v-list-item-title
                class="text-body-1" :class="hover ? 'amber--text text--darken-2' : 'primary--text'"
                style="font-size: 0.875rem;font-weight: 500;letter-spacing: 0.03333em"
              >
                <v-icon
                  small class="mb-1" :color="hover ? 'amber darken-2' : 'primary'"
                >mdi-text-box-check</v-icon>
                할일 목록을 작성하세요
              </v-list-item-title>
              <v-btn small icon :color="hover ? 'amber darken-2' : 'primary'"
                @click.stop="btnTodoClick"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </v-list-item>
            <v-list-item
              v-else
              class="px-2"
            >
              <v-list-item-title
                style="font-size: 0.875rem;font-weight: 500;letter-spacing: 0.03333em"
              >
                <v-icon
                  small
                  class="pb-1 mr-1"
                  :class="todos[0].done ? 'grey--text' : 'primary--text'"
                >
                  {{ todos[0].done ? 'mdi-checkbox-marked-circle-outline' : 'mdi-checkbox-blank-circle-outline' }}
                </v-icon>
                <span
                  @click.stop="btnTodoClick"
                  style="cursor: pointer;"
                  :class="todos[0].done ? 'grey--text' : 'primary--text'"
                >{{ todos[0].text }}</span>
              </v-list-item-title>
              <v-btn small icon :color="hover ? 'amber darken-2' : 'primary'"
                @click.stop="btnTodoClick"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-hover>

        <v-divider class="mx-2"></v-divider>

        <!-- 참고: 좌측 메뉴 순회 시작하는 부분 -->
        <template v-for="item in items">
          <v-row
            v-if="item.heading"
            :key="item.heading"
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="6" xs="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" xs="6" class="text-center">
              <a href="#!" class="text-body-2 black--text">EDIT</a>
            </v-col>
          </v-row>

          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.active"
            :prepend-icon="item.active ? item.icon : item['icon-alt']"
            append-icon=""
            no-action
            :disabled="item.hideMobile && $vuetify.breakpoint.smAndDown"
          >
            <template v-slot:activator>
              <v-list-item
                :disabled="item.hideMobile && $vuetify.breakpoint.smAndDown"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <!-- 중요: 여기부터 -->
            <template v-for="(child, n) in item.children">
              <!-- 참고: child.subChildren 이 없는 넘들. 6글자가 넘으면 text-caption 적용 -->
              <v-list-item
                v-if="!child.subChildren"
                :key="child.text"
                :to="child.to"
              >
                <v-list-item-content>
                  <v-list-item-title
                    :class="child.text.length > 6 ? 'text-caption' : ''"
                  >
                    <v-icon
                      v-show="child.preIcon"
                      small class="ml-1 mb-1"
                    >
                      {{ child.preIcon }}
                    </v-icon>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <!-- !! 등록버튼이 있는 경우 -->
                  <v-tooltip
                    v-if="child.icon"
                    bottom
                    color="primary"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        v-on="on"
                        @click.stop.prevent="child.addContent"
                        @mouseover.stop="showByIndex=n"
                        @mouseout.stop="showByIndex=null"
                        :color="showByIndex === n ? 'red' : ''"
                      >{{ child.icon }}</v-icon>
                    </template>
                    <span>등록</span>
                  </v-tooltip>
                  <!-- !![2021.7.23] 툴팁이 있는 경우 -->
                  <v-tooltip
                    v-if="child.tooltip"
                    right color="green"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        v-on="on"
                      >mdi-help-circle-outline</v-icon>
                    </template>
                    <span>{{ child.tooltip }}</span>
                  </v-tooltip>
                </v-list-item-action>
                <v-list-item-action>
                  <v-icon color="red" v-if="child.newIcon">mdi-new-box</v-icon>
                </v-list-item-action>
              </v-list-item>

              <!-- 참고: 3depth 메뉴: child.subChildren 이 있는 것들은 리스트 그룹으로 다시 묶는다. -->
              <v-list-group
                v-else
                no-action
                sub-group
                v-model="child.active"
                :key="child.text"
              >
                <template v-slot:activator>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ child.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list-item
                  v-for="subChild in child.subChildren"
                  :key="subChild.text"
                  :to="subChild.to"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ subChild.text }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-tooltip
                      right
                      color="green"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-if="subChild.tooltip"
                          small
                          v-on="on"
                        >mdi-help-circle-outline</v-icon>
                      </template>
                      <span>{{ subChild.tooltip }}</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list-group>
            </template>
            <!-- 중요: 여기까지 -->
          </v-list-group>

          <v-list-item
            v-else
            :key="item.text"
             @click="item.doExec ? item.doExec() : dummy()"
            :to="item.to"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      fixed
      flat
      clipped-left
      color="primary"
    >
      <!-- !! 좌측메뉴 접기/펼치기 삼선 버튼 -->
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon
            v-if="$store.state.token"
            v-on="on"
            @click.stop="drawer = !drawer"
            color="white"
          ></v-app-bar-nav-icon>
        </template>
        <span>{{ drawer ? '메뉴접기' : '메뉴펼치기' }}</span>
      </v-tooltip>
      <!-- !! 사무실명 -->
      <!-- <v-avatar size="24">
        <img alt="lawork logo" src="@/assets/logo-black.png">
      </v-avatar> -->
      <span
        v-if="$store.state.token && $store.state.ui"
        class="white--text text--lighten-1"
      >
        {{ $store.state.ui.officeName }}
      </span>
      <!-- 사용안함: 펼침메뉴
      <v-menu
        :nudge-bottom="20"
        bottom
        open-on-click
      >
        <template v-slot:activator="{ on }" v-if="$store.state.ui">
          <v-toolbar-title
            class="text-subtitle-1" v-on="on"
            style="cursor: pointer"
          >
            <span class="white--text text--lighten-1">{{ $store.state.ui.officeName }}</span>
            <v-icon dark>arrow_drop_down</v-icon>
          </v-toolbar-title>
        </template>
        <v-list>
          <v-list-item
            v-if="$store.state.token"
            @click="logOut"
          >
            <v-list-item-title>로그아웃</v-list-item-title>
          </v-list-item>
          <v-list-item v-else @click="logIn">
            <v-list-item-title>로그인</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-spacer></v-spacer>
      <!-- <span class="body-2 white--text">{{ this.$moment($store.state.ui.expDate).format('YYYY.MM.DD') }} 종료</span> -->
      <!-- !! 바로가기 버튼 -->
      <v-btn
        text
        icon
        @click.stop="btnShortcutClick"
      >
        <v-tooltip bottom color="success">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="white"
            >mdi-apps</v-icon>
          </template>
          <span>바로가기</span>
        </v-tooltip>
      </v-btn>
      <!-- !! 로그아웃 버튼 -->
      <!-- <v-btn
        text
        icon
        @click.stop="btnLogOutClick"
      >
        <v-tooltip bottom color="orange darken-2">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="orange darken-1"
            >mdi-power</v-icon>
          </template>
          <span>로그아웃</span>
        </v-tooltip>
      </v-btn> -->
      <v-hover v-slot:default="{ hover }">
        <v-btn
          fab dark small depressed
          :color="hover ? 'orange' : 'error'"
          @click.stop="btnLogOutClick"
        >
          <v-tooltip bottom color="orange darken-2">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="white"
              >mdi-power</v-icon>
            </template>
            <span>로그아웃</span>
          </v-tooltip>
        </v-btn>
      </v-hover>
      <!-- <v-btn text icon>
        <v-icon color="white">mdi-cog</v-icon>
      </v-btn> -->
    </v-app-bar>

    <!-- <v-content style="background-color:#ffffff">
      <router-view/>
    </v-content> -->
    <!-- <router-view :key="$route.fullPath"></router-view> -->
    <!-- 중요: vuetify 2.3.0 부터 v-content 대신 v-main 을 사용한다 -->
    <!-- 중요: keep-alive 로 감싸면 이전 페이지의 상태가 저장된다! -->
    <v-main style="background-color:#ffffff">
      <keep-alive>
        <router-view v-if="$route.meta.KeepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.KeepAlive"></router-view>
    </v-main>

    <app-footer></app-footer>

    <!-- !! 확인 다이얼로그 : 이곳이 root 기 때문에 하위 어디서건 참조가 가능하다! -->
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
    <!-- !! 취소없는 확인 다이얼로그 : 이곳이 root 기 때문에 하위 어디서건 참조가 가능하다! -->
    <confirm-dialog-no-cancel ref="ConfirmDialogNoCancel"></confirm-dialog-no-cancel>
    <!-- !! 메시지를 쓸 수 있는 확인 다이얼로그 : 이곳이 root 기 때문에 하위 어디서건 참조가 가능하다! -->
    <confirm-with-msg-dialog ref="confirmWithMsgDialog"></confirm-with-msg-dialog>

    <!-- !! 소송 등록 다이얼로그 -->
    <suit-add-dialog ref="suitAddDialog"></suit-add-dialog>
    <!-- !! 자문 등록 다이얼로그 -->
    <advice-add-dialog ref="adviceAddDialog"></advice-add-dialog>
    <!-- !! 인명부 등록 다이얼로그 -->
    <client-add-dialog ref="clientAddDialog"></client-add-dialog>
    <!-- !! 독립메뉴 업무 등록 다이얼로그 -->
    <upmu-add-dialog ref="upmuAddDialog"></upmu-add-dialog>
    <!-- !! 게시판 등록 다이얼로그 -->
    <bbs-add-dialog ref="bbsAddDialog"></bbs-add-dialog>
    <!-- !! 관리그룹 팝업 : 소송/자문, 인명부 등록창에서 사용 -->
    <add-mgroup-dialog ref="addMgroupDialog"></add-mgroup-dialog>
    <!-- !! 물품구매신청 다이얼로그 -->
    <supply-request-dialog ref="supplyRequestDialog"></supply-request-dialog>
    <!-- !! 거래처 등록 다이얼로그 -->
    <shop-add-dialog ref="shopAddDialog"></shop-add-dialog>
    <!-- !! 전자결재 등록 다이얼로그 -->
    <ea-add-dialog ref="eaAddDialog"></ea-add-dialog>
    <!-- !! 이용문의 등록 다이얼로그 -->
    <qna-add-dialog ref="qnaAddDialog"></qna-add-dialog>

    <!-- !! 실무계산기 다이얼로그 -->
    <calc-main ref="calcMain"></calc-main>

    <!-- !! 바로가기 팝업 -->
    <shortcut-pop ref="shortcutPop"></shortcut-pop>

    <!-- !![2021.8.2 신설]조직도 팝업 -->
    <organ-chart-pop ref="organChartPop"></organ-chart-pop>

    <!-- !! 긴급공지사항 전체 팝업 -->
    <notice-all-pop ref="noticeAllPop"></notice-all-pop>
    <!-- !! 긴급공지사항 특정유저 팝업 -->
    <notice-user-pop ref="noticeUserPop"></notice-user-pop>
    <!-- !! 긴급공지사항 특정 lawork 팝업 -->
    <notice-lawork-pop ref="noticeLaworkPop"></notice-lawork-pop>

    <!-- !! 알림 다이얼로그: router.js 에서 주로 사용된다. 백엔드에서 에러가 넘어오는 경우 처리하고 / 로 보낸다. -->
    <v-dialog
      v-model="$store.state.dlAlert.active"
      persistent
      max-width="350"
    >
      <v-card>
        <v-toolbar dark color="error" dense flat>
          <v-toolbar-title class="white--text">알림</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-html="$store.state.dlAlert.msg"></v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click.native="confirmAlert">확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- !! 에러알림 다이얼로그: 에러메시지를 좀더 강조하기 위해 중간에 띄운다. -->
    <v-dialog
      v-model="$store.state.dlAlert.active"
      persistent
      max-width="350"
    >
      <v-card>
        <v-toolbar dark color="error" dense flat>
          <v-toolbar-title class="white--text">알림</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-html="$store.state.dlAlert.msg"></v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click.native="confirmAlertErrMsg">확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- !! 결제알림 다이얼로그 : 결제창으로 보낸다. -->
    <v-dialog
      v-model="$store.state.pyAlert.active"
      persistent
      max-width="350"
    >
      <v-card>
        <v-toolbar dark color="error" dense flat>
          <v-toolbar-title class="white--text">알림</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-html="$store.state.pyAlert.msg"></v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click.native="confirmAlertPay">확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 중요: 에러 알림 스낵바 -->
    <v-snackbar
      v-model="$store.state.sbAlert.active"
      :color="$store.state.sbAlert.color || 'error'"
      :timeout="$store.state.sbAlert.timeout || 3000"
      :shaped="$store.state.sbAlert.shaped"
    >
      {{ $store.state.sbAlert.msg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click.native="$store.commit('SB_POP', { active: false })">
          닫기
        </v-btn>
      </template>
    </v-snackbar>

    <!-- 중요: 알림 스낵바 -->
    <v-snackbar
      v-model="msgSnackbar"
      :timeout="-1"
      vertical
      bottom
      right
      shaped
      elevation="0"
      transition="fab-transition"
    >
      <!-- @: 알림 -->
      <v-alert
        v-show="isAlarm"
        dense
        border="left"
        text
        icon="mdi-bell-ring-outline"
        color="success"
        class="text-body-2 my-0 px-8 pt-1 pb-0"
      >
        확인하지 않은 알림이 있습니다.
        <v-icon
          color="warning"
          @click="goToAlarmList"
          class="ml-1 mb-1 py-1"
        >mdi-arrow-right-circle</v-icon>
      </v-alert>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="msgSnackbar = false"
        >
          닫기
        </v-btn>
      </template>
    </v-snackbar>

    <!-- @: 할일목록 팝업 -->
    <todos-pop ref="todosPop"></todos-pop>

  </v-app>
</template>

<script>
import sleep from '@/lib/sleep'

import appFooter from '@/components/common/AppFooter'
import suitAddDialog from '@/components/suit/SuitAddDialog' // 소송 등록창
import adviceAddDialog from '@/components/advice/AdviceAddDialog' // 자문 등록창
import clientAddDialog from '@/components/client/ClientAddDialog' // 인명부 등록창
import upmuAddDialog from '@/components/UpmuAddDialog' // 독립메뉴 업무 등록창
import bbsAddDialog from '@/components/bbs/AddDialog' // 게시판 등록창
import supplyRequestDialog from '@/components/supply/requestDialog' // 물품구매신청 등록창
import shopAddDialog from '@/components/supply/shopAddDialog' // 거래처 등록창
import eaAddDialog from '@/components/eawriting/eaAddDialog' // 전자결재 등록창
import qnaAddDialog from '@/components/qna/AddDialog' // 이용문의 등록창

// !! 타이머: vue-timers 의 믹스인 사용(글로벌로 사용하지 않는다)
import { mixin as VueTimers } from 'vue-timers'

import confirmDialog from '@/components/ConfirmDialog' // 확인 팝업
import ConfirmDialogNoCancel from '@/components/ConfirmDialogNoCancel' // 취소없는 확인 팝업
import confirmWithMsgDialog from '@/components/confirmWithMsgDialog' // 메시지 확인 팝업
import addMgroupDialog from '@/components/AddMgroupDialog' // 관리그룹 등록 팝업

// !! 실무계산기 팝업
import calcMain from '@/components/calc/calcMain'

// !! 바로가기 팝업
import shortcutPop from '@/components/shortcutPop'

// !![2021.8.2 신설] 조직도 팝업
import organChartPop from '@/components/organChartPop'

// !! 긴급 공지사항 팝업 1 - 전체
import noticeAllPop from '@/components/member/noticeAllPop'
// !! 긴급 공지사항 팝업 2 - 유저
import noticeUserPop from '@/components/member/noticeUserPop'
// !! 긴급 공지사항 팝업 3 - 로워크
import noticeLaworkPop from '@/components/member/noticeLaworkPop'

// !! 할일목록 팝업
import todosPop from '@/components/todosPop'

// !! get todo vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters } = createNamespacedHelpers('todo')

export default {
  name: 'App',

  components: {
    appFooter,
    confirmDialog, // 확인 팝업
    ConfirmDialogNoCancel, // 취소없는 확인 팝업
    confirmWithMsgDialog,
    suitAddDialog,
    adviceAddDialog,
    clientAddDialog,
    upmuAddDialog,
    bbsAddDialog,
    addMgroupDialog,
    supplyRequestDialog,
    qnaAddDialog,
    shopAddDialog,
    eaAddDialog,
    calcMain,
    shortcutPop,
    organChartPop,
    noticeAllPop,
    noticeUserPop,
    noticeLaworkPop,
    todosPop
  },

  data () {
    return {
      drawer: true, // 좌측메뉴 초기값은 펼침으로
      showByIndex: null,
      notifyOn: false, // 알림 아이콘
      noticeOn: false, // 공지사항 아이콘
      // emailOn: false, // 이메일 알림 아이콘
      msgSnackbar: false, // !! 메시지 스낵바
      // !! 관리권한여부 - 순서대로 관리자/물품구매/회의실관리팀
      auths: {
        t1: false, t2: false, t3: false
      },
      isAlarm: false, // !! 알림이 왔는지 여부
      batteryStat: 'mdi-battery', // !! 배터리 상태(기본 풀)
      batteryColor: 'success', // !! 배터리 컬러
      batteryMsg: '만료일', // !! 만료일 표시 툴팁
      showPaymentIcon: false, // 구분: 결제아이콘을 보이지 여부
      items: [
        // 메뉴가 많아지면 따로 모듈로 빼자
        // { heading: '필요없지만' },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: '사건관리',
          active: false,
          hideMobile: false, // !! 모바일에서 사라지거나 사용불가인지
          children: [
            {
              icon: '',
              text: '일정',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/schedule' }
            },
            {
              icon: 'add',
              text: '소송',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/case' },
              addContent: () => {
                this.suitAdd()
              }
            },
            { // * 2021.7.20 추가
              icon: '',
              text: '기일･기한',
              newIcon: false,
              preIcon: 'mdi-subdirectory-arrow-right',
              tooltip: '소송의 기일/기한 목록을 보여줍니다.',
              to: { path: '/duedate' }
            },
            { // * 2022.6.27 추가
              icon: '',
              text: '송달',
              newIcon: false,
              preIcon: 'mdi-subdirectory-arrow-right',
              tooltip: '소송의 송달 목록을 보여줍니다.',
              to: { path: '/songdal' }
            },
            {
              icon: 'add',
              text: '자문',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/advice' },
              addContent: () => {
                this.adviceAdd()
              }
            },
            {
              icon: 'add',
              text: '인명부',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/client' },
              addContent: () => {
                this.clientAdd()
              }
            },
            {
              icon: 'add',
              text: '업무',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/work' },
              addContent: () => {
                this.umpuAdd()
              }
            },
            {
              icon: '',
              text: '타임시트',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/timesheet' }
            },
            {
              icon: '',
              text: '비용',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/cost' }
            },
            {
              icon: '',
              text: '수금',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/sugum' }
            },
            {
              icon: '',
              text: '계약',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/contract' }
            },
            {
              icon: '',
              text: '첨부파일',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/attach' }
            }
          ]
        },
        // 사용안함: 이메일, SMS 폐지
        // {
        //   icon: 'keyboard_arrow_up',
        //   'icon-alt': 'keyboard_arrow_down',
        //   text: 'SMS',
        //   active: false,
        //   hideMobile: false,
        //   children: [
        //     { icon: '', text: '메뉴', newIcon: true, to: { path: '/help' } }
        //   ]
        // },
        // {
        //   icon: 'keyboard_arrow_up',
        //   'icon-alt': 'keyboard_arrow_down',
        //   text: 'eMAIL',
        //   active: false,
        //   hideMobile: false,
        //   children: [
        //     { icon: '', text: '메뉴', newIcon: true, to: { path: '/help' } }
        //   ]
        // },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: '게시판',
          active: false,
          hideMobile: false,
          children: [
            {
              icon: 'add',
              text: '목록',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/bbs' },
              addContent: () => {
                this.bbsAdd()
              }
            }
          ]
        },
        // 사용안함: 버전 2로
        // {
        //   icon: 'keyboard_arrow_up',
        //   'icon-alt': 'keyboard_arrow_down',
        //   text: '판례/법령',
        //   active: false,
        //   hideMobile: false,
        //   children: [
        //     { icon: '', text: '메뉴', newIcon: true, to: { path: '/help' } }
        //   ]
        // },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: '전자결재',
          active: false,
          hideMobile: false,
          children: [
            {
              icon: '',
              text: '결재함',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/ealist' }
            },
            {
              icon: 'add',
              text: '작성함',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/eawriting' },
              addContent: () => {
                this.eawrite()
              }
            }
          ]
        },
        // 사용안함: 회의실은 버전 2로
        // {
        //   icon: 'keyboard_arrow_up',
        //   'icon-alt': 'keyboard_arrow_down',
        //   text: '회의실',
        //   active: false,
        //   hideMobile: false,
        //   children: [
        //     { icon: '', text: '메뉴', newIcon: false, to: { path: '/help' } }
        //   ]
        // },
        // 사용안함: 도서대장 폐지
        // {
        //   icon: 'keyboard_arrow_up',
        //   'icon-alt': 'keyboard_arrow_down',
        //   text: '도서대장',
        //   active: false,
        //   hideMobile: false,
        //   children: [
        //     { icon: '', text: '메뉴', newIcon: false, to: { path: '/help' } }
        //   ]
        // },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: '물품구매',
          active: false,
          hideMobile: false,
          children: [
            {
              icon: 'add',
              text: '구매신청',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/requestList' },
              addContent: () => {
                this.supplyRequest()
              }
            },
            {
              icon: 'add',
              text: '거래처',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/shop' }, // !! 거래처 폴더로 이동
              addContent: () => {
                if (!this.auths.t2) {
                  // 물품관리 권한이 없으면 쓸 수 없다!
                  this.sbpop(new Error('물품관리권한이 없습니다. 관리(대행)자에게 문의하십시오.'))
                } else {
                  this.addShop()
                }
              }
            },
            {
              icon: '',
              text: '구매내역',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/buyList' }
            },
            {
              icon: '',
              text: '정산내역',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/settleList' }
            }
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: '회의실예약',
          active: false,
          hideMobile: false,
          children: [
            {
              icon: '',
              text: '일정',
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/meeting' },
              addContent: () => {
                this.bbsAdd()
              }
            }
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: '관리자',
          active: false,
          hideMobile: true, // !! [2020.7.24] 관리자 메뉴는 모바일에서 disabled 된다.
          children: [
            { icon: '', text: '조직관리', active: false, newIcon: false, preIcon: '', tooltip: '', to: { path: '/admin/firmManage' } },
            { icon: '', text: '공유관리', active: false, newIcon: false, preIcon: '', tooltip: '', to: { path: '/admin/shareManage' } },
            { icon: '', text: '업무부서관리', active: false, newIcon: false, preIcon: '', tooltip: '', to: { path: '/admin/teamManage' } },
            {
              icon: '',
              text: '인사관리',
              active: true,
              newIcon: false,
              preIcon: '',
              tooltip: '',
              subChildren: [ // 중요: 3 depth 메뉴
                // { text: '공유관리', tooltip: '공유를 위해서는 대상자의 구글계정이 필요합니다.', to: { path: '/admin/shareManage' } },
                { text: '인사정보', active: false, tooltip: '공유자에 한해 인사정보페이지가 생성됩니다.', to: { path: '/admin/staffProfile' } },
                { text: '발령내역', active: false, tooltip: '발령내역의 등록은 해당자의 [인사정보]에서 가능합니다.', to: { path: '/admin/balList' } },
                { text: '휴가|근태', active: false, tooltip: '휴가|근태의 등록은 해당자의 [인사정보]에서 가능합니다.', to: { path: '/admin/huList' } },
                { text: '인사평가', active: false, tooltip: '인사평가의 등록은 해당자의 [인사정보]에서 가능합니다.', to: { path: '/admin/insaList' } }
              ]
            },
            { icon: '', text: '카테고리관리', active: false, newIcon: false, preIcon: '', tooltip: '', to: { path: '/admin/cateManage/cate01' } },
            { icon: '', text: '자동완성키워드', active: false, newIcon: false, preIcon: '', tooltip: '', to: { path: '/admin/keywordManage' } },
            { icon: '', text: '회의실구성', active: false, newIcon: false, preIcon: '', tooltip: '', to: { path: '/admin/cabinetManage' } },
            { icon: '', text: '결제내역', active: false, newIcon: false, preIcon: '', tooltip: '', to: { path: '/admin/paymentList' } }
            // { icon: '', text: '회의실구성', active: false, newIcon: false, to: { path: '/help' } }, // * 버전 2 로 넘긴다
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: '서비스안내',
          active: false,
          hideMobile: true, // !! [2020.12.22] 서비스안내 메뉴는 모바일에서 disabled 된다.
          children: [
            {
              icon: 'add',
              text: '이용문의',
              active: false,
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/qna' },
              addContent: () => {
                this.qnaAdd()
              }
            },
            {
              icon: '',
              text: '이용가이드',
              active: false,
              newIcon: false,
              preIcon: '',
              tooltip: '',
              to: { path: '/guide' }
            }
          ]
        }
        // ,
        // { icon: 'mdi-settings', text: 'help', to: { path: '/help' } }
      ]
    }
  },

  watch: {
    // 참고: 페이지 이동 감시
    // 감시 라우트를 타는 시점은 페이지 이동시임.
    // 최초 로딩시에는 root path ('/')가 감지 안됨
    // 최초 로딩시에는 mounted() 에서 처리하고 이후에는 여기서 처리함.

    '$route' (to, from) {
      // console.log('to: ', to, 'from: ', from)
      // console.log(this.drawer)

      // @: 1) /auth/ 로 들어오는 경로는 좌측 메뉴가 안보이게 처리함.
      // 사용안함: 주석처리 [2020.12.22]
      // if (/\/auth\//.test(to.path)) { // 주의: 이 부분은 회원가입시 필요하기 때문에 없애면 안된다.
      //   this.drawer = false
      // }

      // 중요: 현재 메뉴 펼치기[2020.6.11]
      // @: 사건관리: /schedule - 사건관리 메뉴의 path 에 해당하는 배열로 메뉴펼침 구현
      // * [2021.7.20] 기일.기한 독립메뉴 - /duedate 추가
      // * [2022.6.27] 송달 독립메뉴 - /songdal 추가
      const caseManageMenus = [
        '/schedule', '/case', '/advice', '/client', '/work', 'attach', '/timesheet', '/cost', '/sugum', '/contract', '/duedate', '/songdal'
      ]
      // 위 배열에 속한(includes(to.path)=true) 이거나 '/case/12' 와 같은 path는 정규표현식 검사로 검사해서 잡는다.
      if (caseManageMenus.includes(to.path) || caseManageMenus.find(m => RegExp(m).test(to.path))) {
        this.items.find(c => c.text === '사건관리').active = true
      }

      // @: 게시판: /bbs 으로 들어온 경우 메뉴 펼치기(새로고침해도)
      if (/\/bbs/.test(to.path)) {
        this.items.find(c => c.text === '게시판').active = true
      }

      // @: 관리자: /admin/ 으로 들어온 경우 관리자 메뉴 펼치기(새로고침해도)
      if (/\/admin\//.test(to.path)) {
        const admMenu = this.items.find(c => c.text === '관리자')
        admMenu.active = true
        // viewStaffInfo 페이지에 들어간 경우 관리자 > 인사관리 하위메뉴가 펼쳐진다(새로고침해도)
        if (/\/viewStaffInfo\//.test(to.path)) {
          admMenu.children[1].active = true
        }
      }

      // @: 물품구매 - 메뉴 펼침
      const supplyManageMenus = [
        '/requestList', '/shop', '/buyList', '/settleList'
      ]
      if (supplyManageMenus.includes(to.path) || supplyManageMenus.find(m => RegExp(m).test(to.path))) {
        this.items.find(c => c.text === '물품구매').active = true
      }

      // @: 전자결재 - 메뉴 펼침
      const eaMenus = [
        '/eawriting', '/ealist'
      ]
      if (eaMenus.includes(to.path) || eaMenus.find(m => RegExp(m).test(to.path))) {
        this.items.find(c => c.text === '전자결재').active = true
      }

      // @: 회의실예약 - 메뉴 펼침
      const meetingMenus = [
        '/meeting'
      ]
      if (meetingMenus.includes(to.path) || meetingMenus.find(m => RegExp(m).test(to.path))) {
        this.items.find(c => c.text === '회의실예약').active = true
      }

      // @: 서비스안내 - 메뉴 펼침
      const serviceMenus = [
        '/qna', '/guide'
      ]
      if (serviceMenus.includes(to.path) || serviceMenus.find(m => RegExp(m).test(to.path))) {
        this.items.find(c => c.text === '서비스안내').active = true
      }
    }
  },

  created () {
  },

  mounted () {
    // 사용안함: 초기화면을 풀스크린으로 처리해서 필요없어짐
    // root path ('/') 인 경우 좌측 메뉴를 닫는다.
    // 문제는 이게 초기에 단 1번 만 적용된다.
    // this.$router.history.current.path 로 root path('/')를 감지하자
    // 로딩시 약간의 딜레이를 주자
    // sleep(200).then(() => {
    //   // console.log(this.$router.history.current.path)
    //   if (this.$router.history.current.path === '/') {
    //     // root path ('/') 면 좌측 메뉴바를 없애자.
    //     this.drawer = false
    //   } else {
    //     this.drawer = true
    //   }
    // })
    // console.log(this.$route.path) // 이렇게 해도 되지만 역시 초기 1번만 캐치됨

    // ! 만료일 처리 - mounted 에서 호출하기 때문에 첫 로딩시 1번만 실행된다.
    // * 기존 local storage, vuex 에 남아있는 만료일을 기준으로 실행한다.
    // * 기간이 지난경우 DB에 실제 만료일이 갱신된건지 조회한다.
    if (this.$store.state.ui && this.$store.state.ui.expDate) {
      // 중요: 종료일 처리 - 아래 계산 결과 종료일이 오늘이면 0이 나온다. 즉, 0 이면 오늘이고 오늘이 종료일이다. 만약 종료일이 지난 경우 결제창으로 빠진다.
      // 주의: diff() 주의사항 - days 옵션을 주면 정확히 24시간의 시간차로 날짜를 계산한다. 그러면 종료일이 전날인데 아직 24시간이 지나지 않아 하루가 지나지 않은게 된다.
      //      때문에 endOf('day') 를 사용해 시작을 23:59:59 로 맞춰준다.
      const extDateEndOf = this.$moment(this.$store.state.ui.expDate).endOf('day') // * DB에서 가져온 종료일을 23:59:59로 맞춘것
      const nowDateEndOf = this.$moment().endOf('day') // * 오늘의 날자객체를 23:59:59로 맞춘것
      const diffDays = extDateEndOf.diff(nowDateEndOf, 'days') // * 날자차이 계산 -> 결과는 정수형 데이터임

      // 배터리 아이콘 툴팁 메시지
      this.batteryMsg = `${this.$moment(this.$store.state.ui.expDate).format('YYYY.MM.DD')} 만료`
      //
      if (diffDays < 0) {
        // * 만료일이 지난경우
        // TODO 중요: 백엔드에 현재 유저사무실의 만료기간조회 - 갱신여부 조사
        this.checkExpDate(this.$store.state.ui.dbcode)
        //
      } else if (diffDays >= 10) {
        // * 10일 넘게 남아있다
        this.batteryStat = 'mdi-battery'
        this.batteryColor = 'success'
        //
      } else if (diffDays < 10 && diffDays > 0) { // * 9 ~ 1 일 남은 경우
        //
        this.batteryStat = `mdi-battery-${diffDays}0`
        if (diffDays >= 7) {
          //
          this.batteryColor = 'amber darken-3'
          //
        } else if (diffDays < 7 && diffDays >= 4) {
          //
          this.batteryColor = 'orange darken-3'
          //
        } else {
          //
          this.batteryColor = 'red accent-3'
          //
        }
      } else if (diffDays === 0) { // * 0일남았다 = 종료일 당일
        this.batteryStat = `mdi-battery-outline`
        this.batteryColor = 'error'
        //
      }
    }

    // 중요: 루프실행 > 마운트 후 2초 지난뒤 1번 실행 이후에는 타이머에서 실행된다.
    sleep(2000).then(() => { this.loopWork() })

    // 다이얼로그를 움직이게 만든다.
    sleep(1000).then(() => { this.movePopupWindow() })

    // test vue-browser-detect-plugin
    // console.log('here is chrome ? ', this.$browserDetect.isChrome)
    // console.log(this.$browserDetect.meta)
  },

  // 중요: 타이머 믹스인
  mixins: [
    VueTimers
  ],

  // 중요: 타이머 객체 - created 를 후킹해서 실행된다.
  timers: {
    loopWork: {
      time: 300000, // 5분마다. 10초는 10000
      autostart: true, // 자동시작
      // immediate: true, // 시작과 동시에 실행
      repeat: true // 반복 true: setInterval(), false: setTimeout()
    }
  },

  computed: {
    // get vuex mapState & mapGetters Helper
    ...mapState([
      'todos'
    ]),
    ...mapGetters([
      'todoLength'
    ])
  },

  methods: {
    dummy () {
      console.log('dummy dummy ~ ')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message, color: 'error', timeout: 5000, shaped: true })
    },
    // 구분: 로그인 - views/member/login.vue 로
    logIn () {
      this.$router.push('member/login')
    },
    // 구분: 실제 로그아웃 처리함수
    logOut () {
      this.$store.dispatch('LOGOUT')
        .then(() => {
          // [2021.9.1]알림 팝업을 닫아주는 처리 추가
          this.msgSnackbar = false
          this.notifyOn = false

          this.$router.push('/')
        })
    },
    // 구분: 로그아웃 버튼 클릭 이벤트 핸들러
    async btnLogOutClick () {
      try {
        const msg = '로그아웃 하시겠습니까?<br>로워크의 로그아웃은<br><span class="primary--text">구글계정의 자동 로그아웃</span>을의미하는 것은 아닙니다.<br>(불필요시 구글계정도 로그아웃하십시오.)'
        if (await this.$refs.confirmDialog.open('로그아웃', msg, { color: 'error', width: 350 })) {
          this.logOut()
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 숏컷(바로가기) 버튼 클릭 이벤트 핸들러
    async btnShortcutClick (evt) {
      try {
        // 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.shortcutPop.showMenu) {
          await this.$refs.shortcutPop.show(evt) // 일단 열고

          // 나머지 인자를 보낸다
          // const params = { teamId: this.article.teamId, pId: this.article.id }
          // const result = await this.$refs.addCommentPop.setData(params)
          // if (result) {
          //   await this.getSubContents(37) // 댓글 목록 리프레시
          // }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 로딩시 1번만 호출된다!
    //  * dbcode로 해당 사무실의 실시간 만료일 정보를 DB에서 가져온다.
    //  * 정말로 지났다면 결제페이지로, 연장되었으면 다시 로그인 시킨다..
    async checkExpDate (dbcode) {
      try {
        // console.log('LOG: checkExpDate() 에서 auth/getLaworkInfoByDbcode/dbcode 호출')
        const { data } = await this.$axios.get(`auth/getLaworkInfoByDbcode/${dbcode}`)
        // console.log(data.laworkInfo)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        // 만료일이 지났는지 체크
        if (this.$moment(data.laworkInfo.expiredAt).startOf('day').diff(this.$moment(), 'days') < 0) {
          // ? 지났다 -> 결제페이지로 이동
          // ? [2022.3.16]isOk='N'인 경우다. 로그아웃 시키고 승인대기 화면으로 뺀다.
          if (data.laworkInfo.isOk === 'N') {
            //
            this.$store.dispatch('LOGOUT').then(() => this.$router.push('/member/waitAdmit'))
            //
          } else {
            // * isOk='Y'인 경우다(관리자가 승인함)
            // ![2022.4.5]입금대기중인 무통장입금이 있는 경우 payRequest.vue 로 뺀다.
            if (data.laworkInfo.order_status === 'R') {
              // * 입금대기 페이지로 GO!
              if (!RegExp('/member/payRequest').test(this.$router.history.current.path)) {
                sleep(500).then(() => {
                  this.$router.push('/member/payRequest')
                })
              }
            } else {
              // * 결제페이지로 GO! => 결제페이지가 아닌 경우만 이동시킴.. 결제페이지에선 그냥 놔둠..
              if (!RegExp('/member/payment').test(this.$router.history.current.path)) {
                sleep(500).then(() => {
                  this.$router.push('/member/payment')
                })
                // sleep(500).then(() => {
                //   const msg = '서비스기간이 만료되었습니다.<br>결제페이지로 이동합니다.'
                //   this.$refs.ConfirmDialogNoCancel.open('결제', msg, { color: 'warning', width: 350 }).then(() => {
                //     this.$router.push('/member/payment')
                //   })
                // })
              }
            }
          }
        } else {
          // ? 안지났다 - 로그아웃 시키고 다시 로그인 시켜야 한다.
          const msg = '결제정보가 변경되었습니다.<br>보안을 위해 다시 로그인하셔야 합니다.'
          this.$refs.confirmDialog.open('자동로그아웃', msg, { color: 'error', width: 350 }).then(() => {
            // 리턴값이 true 던 false 던 관계없이 로그아웃 시킨다.
            this.logOut()
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 실무계산기 팝업
    async goCalcPop () {
      try {
        // await this.$refs.calcMain.open('실무계산기', { width: 1180, height: 580 })
        await this.$refs.calcMain.open('실무계산기', { width: 960, height: 670 })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 지메일 받은편지 바로가기
    async goGmail () {
      try {
        const gmailUrl = `https://mail.google.com/mail/u/0/#inbox`
        const link = document.createElement('a')
        link.href = gmailUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 대법원 판례/법령 검색 사이트 바로가기
    async goPrecedentSite () {
      try {
        const siteUrl = `https://glaw.scourt.go.kr/wsjo/intesrch/sjo022.do`
        const link = document.createElement('a')
        link.href = siteUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 루프를 돌면서 정기적으로 하는 일
    // 1) 메시지 체크
    //   물품신청/접수 메시지 체크
    async loopWork () {
      try {
        // 첫화면이면 스킵
        if (this.$router.history.current.path === '/') return

        // 로그인 되어있지 않으면 스킵
        if (!this.$store.state.token) return
        if (!this.$store.state.ui) return
        if (!this.$store.state.ui.dbcode) return
        if (!this.$store.state.ui.expDate) return
        if (this.$store.state.ui.isAgree !== 'Y') return

        // 위를 다 통과해도 this.$store.state.ui.gexp(토큰 만료시각)이 지났으면 스킵
        if (this.$moment(this.$store.state.ui.gexp * 1000).diff(this.$moment(), 'seconds') <= 0) return

        // * [2021.1.27] 로워크 생성시 에러가 나서 이쪽으로 옮김. 관리(대행)자는 결제 아이콘이 보이도록 처리
        if (!this.showPaymentIcon) { // 안보일 때 1번만 실행하도록 한다.
          if (this.$store.state.ui.lv === 'M' || this.$store.state.ui.lv === 'G') {
            this.showPaymentIcon = true
          }
        }

        // console.log('LOG: go go loop ~')

        // 권한여부와 알림을 한번에 처리
        await this.checkAuthAndAlarms()

        // 2초 후 공지사항 확인
        sleep(2000).then(() => { this.chkNotice() })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:중요: 권한여부 + 알림여부
    async checkAuthAndAlarms () {
      try {
        // 좌측상단 알림아이콘이나 메시지 스낵바가 활성화 된 경우 스킵
        // 중요: 알람 메시지가 활성화 되어있으면 스낵바는 5분마다 뜨지 않음
        if (this.msgSnackbar || this.notifyOn) return

        // 권한체킹
        const { data } = await this.$axios.get(`lawork/lwc/getAuthToAlarm`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        this.auths = data.auths // 관리자팀,물품구매팀,회의실관리팀의 권한 여부
        this.isAlarm = data.isAlarm // 알림이 왔는지 여부

        if (this.isAlarm) { // 알림이 왔으면!
          this.msgSnackbar = true // 메시지 스낵바 활성
          this.notifyOn = true // 알림 아이콘 활성
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 공지사항 확인
    async chkNotice () {
      try {
        // 확인하지 않은 공지사항의 id 를 리턴하는 함수들을 호출한다.

        // 1) 일반공지
        await this.chkCommonNotice()

        // 2) 긴급공지
        await this.chkEmergeNotice()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 일반공지 체크
    async chkCommonNotice () {
      try {
        // this.noticeOn 이 활성화 되어있으면 스킵
        if (!this.noticeOn) {
          // 리턴값이 true 면 확인하지 않은 일반공지가 있는것!
          const { data } = await this.$axios.get('lawork/member/chkCommonNotice')
          if (!data.success) throw new Error(`[ERR-1] ${data.message}`)
          if (data.returnValue) {
            // 확인하지 않은 공지가 있으면 data.returnValue = true 가 넘어온다!
            this.noticeOn = true // 공지사항 아이콘 활성
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 긴급공지 체크
    async chkEmergeNotice () {
      try {
        const { data } = await this.$axios.get('lawork/member/chkEmergeNotice')
        if (!data.success) throw new Error(`[ERR-1] ${data.message}`)
        if (data.returnValue) {
          // 전체, 유저, 로워크 순으로 배열에 담는다.
          let nproc = []

          if (data.returnValue.all) {
            nproc.push({ obj: this.$refs.noticeAllPop, id: data.returnValue.all })
          }
          if (data.returnValue.user) {
            nproc.push({ obj: this.$refs.noticeUserPop, id: data.returnValue.user })
          }
          if (data.returnValue.lawork) {
            nproc.push({ obj: this.$refs.noticeLaworkPop, id: data.returnValue.lawork })
          }
          // 순서대로 실행시킨다.
          for (const p of nproc) {
            await p.obj.open('[긴급]공지사항', { width: 1000, height: 670 }, p.id)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 사용안함: 물품관리권한 체크
    // async requestSupplyAuth () {
    //   try {
    //     const { data } = await this.$axios.get(`lawork/lwc/getAllTeamIds`)
    //     if (!data.success) this.sbpop(`오류가 발생하였습니다: ${data.message}`)
    //     return data.auths.t2
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 구분: 알림 page 바로가기
    async goToAlarmList () {
      try {
        this.msgSnackbar = false // 스낵바 닫기
        this.notifyOn = false // 알림 아이콘도 끄기

        if (this.$router.history.current.path.indexOf('/member/alarm') === -1) {
          this.$router.push('/member/alarm')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 공지사항 page 바로가기
    async goNoticePage () {
      try {
        this.noticeOn = false // 공지사항 아이콘 끄기

        if (this.$router.history.current.path.indexOf('/notice') === -1) {
          this.$router.push('/notice')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 개인정보 page 바로가기
    async goMyInfo () {
      try {
        if (this.$router.history.current.path.indexOf('/member/myInfo') === -1) {
          this.$router.push('/member/myInfo')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 결제페이지로 바로가기
    async goPayment () {
      try {
        // * 기간이 남아있지만 입금대기중인 결제건이 있는지 확인
        const { data } = await this.$axios.get(`auth/getLaworkInfoByDbcode/${this.$store.state.ui.dbcode}`)
        if (data.laworkInfo.order_status === 'R') {
          // 입금대기중인 무통장입금건이 있으니 입금대기 페이지로 이동시킨다.
          const msg = '입금대기중인 결제건이 있습니다. 확인 하시겠습니까?'
          if (await this.$refs.confirmDialog.open('입금대기 확인', msg, { color: 'warning', width: 350 })) {
            if (this.$router.history.current.path.indexOf('/member/payRequest') === -1) {
              this.$router.push('/member/payRequest')
            }
          }
        } else {
          // 입금대기건이 없으니 주문/결제 페이지로 이동시킨다
          const msg = '주문/결제 페이지로 이동하시겠습니까?'
          if (await this.$refs.confirmDialog.open('주문/결제', msg, { color: 'primary', width: 350 })) {
            if (this.$router.history.current.path.indexOf('/member/payment') === -1) {
              this.$router.push('/member/payment')
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 소송 등록 다이얼로그 호출 메서드
    async suitAdd () {
      try {
        const result = await this.$refs.suitAddDialog.open('소송', { width: 700, height: 670 })
        if (result) {
          // 중요: 등록/수정시엔 리프레시를 의미하는 '-R' 을 붙여서 보낸다.
          const insertId = `${result.affectedId}-R`
          this.$router.push(`/case/${insertId}`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 자문 등록 다이얼로그 호출 메서드
    async adviceAdd () {
      try {
        const result = await this.$refs.adviceAddDialog.open('자문', { width: 700, height: 670 })
        if (result) {
          // 중요: 등록/수정시엔 리프레시를 의미하는 '-R' 을 붙여서 보낸다.
          const insertId = `${result.affectedId}-R`
          this.$router.push(`/advice/${insertId}`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 인명부 등록 다이얼로그 호출 메서드
    async clientAdd () {
      try {
        const result = await this.$refs.clientAddDialog.open('인명부', { width: 650, height: 680 })
        if (result) {
          // 중요: 등록/수정시엔 리프레시를 의미하는 '-R' 을 붙여서 보낸다.
          const insertId = `${result.affectedId}-R`
          this.$router.push(`/client/${insertId}`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 독립메뉴 업무 등록 다이얼로그 호출 메서드
    async umpuAdd () {
      try {
        const result = await this.$refs.upmuAddDialog.open('업무', { width: 650 }, -1, null)
        if (result) {
          // 리프레시 > 업무메뉴가 아닌 경우만, 독립메뉴 업무 리스트로 보낸다
          // 중요: 등록/수정시엔 리프레시를 의미하는 '-R' 을 붙여서 보낸다.
          this.$router.push(`/work/${result}-R`)
          // if (this.$router.history.current.path !== '/work/list') {
          //   this.$router.push(`/work/list`)
          // }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 게시판 등록 다이얼로그 호출 메서드
    async bbsAdd () {
      try {
        const result = await this.$refs.bbsAddDialog.open('게시판')
        if (result) {
          // 중요: 등록/수정시엔 리프레시를 의미하는 '-R' 을 붙여서 보낸다.
          const insertId = `${result}-R`
          this.$router.push(`/bbs/${insertId}`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 이용문의 등록 다이얼로그 호출 메서드
    async qnaAdd () {
      try {
        const result = await this.$refs.qnaAddDialog.open('이용문의')
        if (result) {
          // 중요: 등록/수정시엔 리프레시를 의미하는 '-R' 을 붙여서 보낸다.
          const insertId = `${result}-R`
          this.$router.push(`/qna/${insertId}`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 물품구매신청 등록
    async supplyRequest () {
      try {
        if (await this.$refs.supplyRequestDialog.open('구매신청', { width: 650, height: 600 }, -1, null)) {
          this.$router.push(`/requestList/true-R`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 거래처 등록
    async addShop () {
      try {
        const result = await this.$refs.shopAddDialog.open('거래처', { width: 650, height: 600 }, -1, null)
        if (result) {
          const insertId = `${result}-R`
          this.$router.push(`/shop/${insertId}`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 전자결재 작성
    async eawrite () {
      try {
        // 주의: eaAddDialog 는 등록 팝업이다. 하지만 방식을 바꿔서 view.vue 에서 작성하도록 변경(다음메일 작성 처럼)
        // 고쳐: 추후 완전히 확정된다면 eaAddDialog.vue 는 삭제해야 한다.

        // await this.$refs.eaAddDialog.open('전자결재', -1, null)
        // const result = await this.$refs.eaAddDialog.open('전자결재', -1, null)
        // if (result) {
        //   const insertId = `${result}-R`
        //   this.$router.push(`/shop/${insertId}`)
        // }
        // this.$router.push(`/eawriting/new`) // 새로작성

        // 주의: 현재 작성페이지면 이동시키지 않는다.
        if (!RegExp('/eawriting/new').test(this.$router.history.current.path)) {
          this.$router.push('/eawriting/new')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 알림 다이얼로그 확인 버튼 이벤트 핸들러
    confirmAlert () {
      this.$store.commit('MD_POP', { active: false })
      this.$router.push('/')
    },
    // 구분: 에러메시지 처리 - 그냥 메시지 보인 후 닫으면 된다
    confirmAlertErrMsg () {
      this.$store.commit('MD_POP', { active: false })
    },
    // 구분: 기간만료 - 알림 다이얼로그 확인 버튼 이벤트 핸들러
    confirmAlertPay () {
      this.$store.commit('PY_POP', { active: false })
      this.$router.push('/member/payment')
    },
    // 구분: 할일목록 버튼 클릭 이벤트 핸들러
    async btnTodoClick (evt) {
      try {
        // 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.todosPop.showMenu) {
          await this.$refs.todosPop.show(evt)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: [2021.8.2 신설] 조직도 팝업 띄우기
    async organChartPop () {
      try {
        //
        await this.$refs.organChartPop.open({ width: 700, height: 750 })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: v-dialog, v-menu 를 움직이게 한다.
    // 모든 팝업,메뉴가 움직이면 오히려 곤란하므로 특정하게 지정된 것만 움직일 수 있게 했다.
    //
    // 일단, v-dialog, v-menu 모두 v-toolbar__content 가 있어야 한다.
    //
    // v-dialog 의 경우 한가지 더 필요한건 .__move__pop 인데.. components/common/BaseModal.vue 에
    // <v-toolbar-items class="__move__pop"> 를 지정했고, 이 BaseModal을 확장한 것만 움직일 수 있다.
    //
    // v-menu 의 경우 absolute 프로퍼티가 활성화된 상태여야 한다. 즉, 가변위치 메뉴인게 조건의 하나이다.
    // v-dialog 처럼 .__move__pop을 지정해도 되지만, 어디에 .__move__pop 을 할지 위치지정이 애매하고
    // v-toolbar 가 있으면 움직이도록 하는게 더 의미가 있을것 같아서 .__move__pop 는 뺐다.
    movePopupWindow () {
      const d = {}
      document.addEventListener('mousedown', e => {
        if (e.target.children) { // 자식 요소가 있어야 한다.
          // #1) 모든 다이얼로그 움직이기
          const closestDialog = e.target.closest('.v-dialog.v-dialog--active')
          // #2) 메뉴창 움직이기
          const closestMenu = e.target.closest('.v-menu__content.menuable__content__active')
          if (e.button === 0 && closestDialog != null && e.target.classList.contains('v-toolbar__content')) {
            // 중요: 첫번째 엘리먼트에 __move__pop 클래스가 있어야 움직이게 한다!
            const firstChildItem = e.target.firstElementChild // v-toolbar-items 엘리먼트임
            if (firstChildItem.classList.contains('__move__pop')) {
              d.el = closestDialog // element which should be moved
              d.mouseStartX = e.clientX
              d.mouseStartY = e.clientY
              d.elStartX = d.el.getBoundingClientRect().left
              d.elStartY = d.el.getBoundingClientRect().top
              d.el.style.position = 'fixed'
              d.el.style.margin = 0
              d.oldTransition = d.el.style.transition
              d.el.style.transition = 'none'
            }
          } else if (e.button === 0 && closestMenu != null && e.target.classList.contains('v-toolbar__content')) {
            // [폐지] 첫번째 엘리먼트에 __move__pop 이 있어야 움직인다.
            // const firstChildItem = e.target.firstElementChild // v-icon 엘리먼트임
            // if (firstChildItem.classList.contains('__move__pop')) {}
            d.el = closestMenu // element which should be moved
            d.mouseStartX = e.clientX
            d.mouseStartY = e.clientY
            d.elStartX = d.el.getBoundingClientRect().left
            d.elStartY = d.el.getBoundingClientRect().top
            d.el.style.position = 'fixed'
            d.el.style.margin = 0
            d.oldTransition = d.el.style.transition
            d.el.style.transition = 'none'
          }
        }
      })

      document.addEventListener('mousemove', e => {
        if (d.el === undefined) return
        d.el.style.left = Math.min(
          Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
          window.innerWidth - d.el.getBoundingClientRect().width
        ) + 'px'
        d.el.style.top = Math.min(
          Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
          window.innerHeight - d.el.getBoundingClientRect().height
        ) + 'px'
      })

      document.addEventListener('mouseup', () => {
        if (d.el === undefined) return
        d.el.style.transition = d.oldTransition
        d.el = undefined
      })

      setInterval(() => { // prevent out of bounds
        const dialog = document.querySelector('.v-dialog.v-dialog--active')
        if (dialog === null) return
        dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + 'px'
        dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + 'px'
      }, 100)
    }
  }
}
</script>

<style>
/* !! v-toolbar 중 movePopArea 아이디만 손 아이콘으로 변경 */
#movePopArea {
  cursor: grab;
}

/* !! scrollbar 디자인 변경: mac os 는 상관없지만 윈도때문 */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #C1C1c1;
  -webkit-border-radius: 1ex;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}

::-webkit-scrollbar-track {
  background-color: #eeeeee;
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
}
</style>
