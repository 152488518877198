<template>
  <v-card
    tile
    :elevation="0"
  >
    <v-row no-gutters>
      <!-- 좌측 컨텐츠 시작 -->
      <v-col cols="12" md="6" lg="6" xl="6" class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-cog-refresh</v-icon> 계산조건
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              class="mr-2"
              @click.stop="doCalc"
            >
              <v-icon small>mdi-led-on</v-icon>
              계산
            </v-btn>
            <v-btn
              small
              @click.stop="doInit"
            >
              <v-icon small>mdi-refresh</v-icon>
              초기화
            </v-btn>
          </v-toolbar>
          <v-card
            tile
            outlined
            elevation="0"
            class="pa-2"
            min-height="568"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" class="pb-2">
                <span class="text-body-2 primary--text">소송유형</span>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="suitType1"
                  :items="suitType1Options"
                  item-text="text"
                  item-value="value"
                  label=""
                  menu-props="auto"
                  class="mr-1"
                  dense
                  outlined
                  filled
                  @change="changeSuitType1"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="suitType2"
                  :items="suitType2Options[`s${suitType1}`]"
                  item-text="text"
                  item-value="value"
                  label=""
                  menu-props="auto"
                  class="ml-1"
                  dense
                  outlined
                  filled
                  @change="changeSuitType2"
                ></v-select>
              </v-col>
              <v-col cols="12" class="pb-2">
                <span class="text-body-2 primary--text">소송방식</span>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <v-select
                    v-model="suitMethod"
                    :items="suitMethodOptions"
                    item-text="text"
                    item-value="value"
                    label=""
                    menu-props="auto"
                    class="ma-0"
                    dense
                    outlined
                    filled
                    style="max-width:200px;"
                    @change="changeSuitMethod"
                  ></v-select>
                </v-row>
              </v-col>
              <v-col cols="12">
                <div class="my-2">&nbsp;</div>
              </v-col>
              <v-col cols="12" class="pb-2">
                <span class="text-body-2 primary--text">1.인지액</span>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <v-text-field
                    ref="numSoPrice"
                    v-model.trim="soPrice"
                    v-validate="'required|max:15|is_not:0'"
                    :error-messages="errors.collect('soPrice')"
                    data-vv-name="soPrice"
                    required
                    maxlength="15"
                    label="*소가(원)"
                    dense
                    outlined
                    filled
                    style="max-width: 250px;"
                    @keypress="preventComma"
                  ></v-text-field>
                  <v-chip
                    v-show="isSuitMethod2"
                    label
                    color="white"
                    class="error--text mt-1 ml-2"
                  >
                    <v-icon small left color="error">mdi-information</v-icon> 인지액 10% 감면
                  </v-chip>
                </v-row>
              </v-col>
              <v-col cols="12">
                <div class="my-2">&nbsp;</div>
              </v-col>
              <v-col cols="12" class="pb-2">
                <span class="text-body-2 primary--text">2.송달료</span>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <v-text-field
                    ref="numDangCnt"
                    v-model.trim="dangCnt"
                    v-validate="'required|max:8|is_not:0'"
                    :error-messages="errors.collect('dangCnt')"
                    data-vv-name="dangCnt"
                    required
                    maxlength="8"
                    :label="dangCntTitle"
                    dense
                    outlined
                    filled
                    style="max-width: 250px;"
                    @blur="dangCnt = strToNumber(dangCnt) === 0 ? '2' : dangCnt"
                    @keypress="preventComma"
                  ></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <v-text-field
                    ref="numSongdalFee"
                    v-model.trim="songdalFee"
                    v-validate="'required|max:8|is_not:0'"
                    :error-messages="errors.collect('songdalFee')"
                    data-vv-name="songdalFee"
                    required
                    maxlength="8"
                    label="*1회송달료(원)"
                    dense
                    outlined
                    filled
                    style="max-width: 250px;"
                    @keypress="preventComma"
                  ></v-text-field>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
      <!-- <v-divider vertical class="ma-0 pa-0"></v-divider> -->
      <!-- 우측 컨텐츠 시작 -->
      <v-col class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-cube-send</v-icon> 계산결과
            <v-spacer></v-spacer>
            <v-btn
              small
              @click="doCopy"
              dark
            >
              <v-icon small>mdi-content-copy</v-icon>
              클립보드복사
            </v-btn>
            <!-- 사용안함: 클립보드 복사 아이콘 -->
            <!-- <v-tooltip left color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                  color="primary"
                  @click="doCopy"
                >mdi-content-copy</v-icon>
              </template>
              <span>클립보드복사</span>
            </v-tooltip> -->
          </v-toolbar>
          <v-card
            id="copyArea"
            tile
            outlined
            elevation="0"
            class="pa-2"
            min-height="568"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12">
                <div class="mt-2 mb-0 font-weight-bold">
                  ● 소송유형: <span class="text-body-2 primary--text font-weight-bold">{{ spnResult1 }}</span>
                </div>
                <div class="mt-2 mb-0 font-weight-bold">
                  ● 소송방식: <span class="text-body-2 primary--text font-weight-bold">{{ spnResult2 }}</span>
                </div>
                <div class="my-3">&nbsp;</div>
                <div class="mt-0 mb-1">
                  <span class="text-body-2 font-weight-bold">◆ 인지액</span>
                </div>
                <div class="mb-2 pl-9">
                  소가: {{ numberFormat(cRes.soPrice) }}원
                </div>
                <div class="mb-2 pl-9">
                  인지액: <span class="error--text">{{ numberFormat(cRes.injiPrice) }}원</span>
                </div>
                <div class="mb-0 pl-9">
                  {{ spnWgc }}: {{ numberFormat(cRes.refundPrice) }}원
                </div>
                <div class="my-3">&nbsp;</div>
                <div class="mt-0 mb-1">
                  <span class="text-body-2 font-weight-bold">◆ 송달료</span>
                </div>
                <div class="mb-2 pl-9">
                  1인당 송달기준: {{ numberFormat(cRes.songdalStandard) }}회분
                </div>
                <div class="mb-2 pl-9">
                  당사자수: {{ numberFormat(cRes.dangCnt) }}명
                </div>
                <div class="mb-2 pl-9">
                  1회 송달료: {{ numberFormat(cRes.songdalFee) }}원
                </div>
                <div class="mb-2 pl-9">
                  송달료: <span class="error--text">{{ numberFormat(cRes.totalSongdalFee) }}</span>원
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import copy from 'copy-to-clipboard' // !! 클립보드 복사 플러그인
import ko from 'vee-validate/dist/locale/ko'

// 구분: filters
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import preventComma from '@/filters/preventComma' // keypress event 에서 쉼표막기
import strToNumber from '@/filters/strToNumber' // '10,000' => 를 10000 로 변환

/*
// 인지액 계산 요율
var A1 = 0.005; // 소가 < 1천만원
var A2 = 0.0045; // 1천만원 <= 소가 < 1억원
var A3 = 0.004; // 1억원 <= 소가 < 10억원
var A4 = 0.0035; // 10억원 <= 소가
var A1_c = 0; // A1에 합산하는 금액
var A2_c = 5000; // A2에 합산하는 금액
var A3_c = 55000; // A3에 합산하는 금액
var A4_c = 555000; // A4에 합산하는 금액

var B = 1.5;
var C = 2;
var D = 0.1;
var E = 0.2;
*/
// 중요: 1회 송달료는 매년 변결될 수 있다
// !![2021.3.16] 정수형에서 문자열 숫자로 변경했다
// [2021.11.12] 5100 에에서  5200 로 변경
const _songdalFee = '5200'

export default {
  components: {
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    suitType1: 1,
    suitType1Options: [
      { text: '민사', value: 1 },
      { text: '행정', value: 2 },
      { text: '가사소송(가/나류)', value: 3 },
      { text: '가사소송(다류)', value: 4 },
      { text: '가사조정', value: 5 }
    ],
    suitType2: null,
    // 중요: 소송유형2의 구체적인 배열
    // idx / 소송유형2 / 인지계산유형(ict) / 환급액타입(hct) / 최저가(lc) / 송달횟수(sdc)
    suitType2Options: {
      s1: [ // 민사
        { value: 1, text: '1심(가단/가합)', ict: 'A', htc: 'M1', lc: 1000, sdc: 15 },
        { value: 2, text: '소액(가소)', ict: 'A', htc: 'M1', lc: 1000, sdc: 10 },
        { value: 3, text: '항소(나)', ict: 'B', htc: 'M1', lc: 1000, sdc: 12 },
        { value: 4, text: '상고(다)', ict: 'C', htc: 'M1', lc: 1000, sdc: 8 },
        { value: 5, text: '독촉(차/차전)', ict: 'D', htc: 'M2', lc: 1000, sdc: 6 },
        { value: 6, text: '조정(머)', ict: 'E', htc: 'M2', lc: 1000, sdc: 5 },
        { value: 7, text: '제소전화해(자)', ict: 'E', htc: 'M2', lc: 1000, sdc: 4 }
      ],
      s2: [ // 행정
        { value: 1, text: '1심(구단/구합)', ict: 'A', htc: 'M1', lc: 1000, sdc: 10 },
        { value: 2, text: '항소(누)', ict: 'B', htc: 'M1', lc: 1000, sdc: 10 },
        { value: 3, text: '상고(두)', ict: 'C', htc: 'M1', lc: 1000, sdc: 8 }
      ],
      s3: [ // 가사소송(가/나류)
        { value: 1, text: '1심(드단/드합)', ict: '20000', htc: 'M3', lc: 1000, sdc: 15 },
        { value: 2, text: '항소(르)', ict: '30000', htc: 'M3', lc: 1000, sdc: 12 },
        { value: 3, text: '상고(므)', ict: '40000', htc: 'M3', lc: 1000, sdc: 8 }
      ],
      s4: [ // 가사소송(다류)
        { value: 1, text: '1심(드단/드합)', ict: 'A', htc: 'M1', lc: 1000, sdc: 15 },
        { value: 2, text: '항소(르)', ict: 'B', htc: 'M1', lc: 1000, sdc: 12 },
        { value: 3, text: '상고(므)', ict: 'C', htc: 'M1', lc: 1000, sdc: 8 }
      ],
      s5: [ // 가사조정
        { value: 1, text: '조정(너)', ict: '5000', htc: 'M3', lc: 1000, sdc: 5 }
      ]
    },
    suitMethod: 1,
    suitMethodOptions: [
      { text: '종이', value: 1 },
      { text: '전자', value: 2 }
    ],
    // 구분: 필수값 - 소가, 당사자수, 1회 송달료 - 숫자만 입력 가능하지만 형식은 문자열임
    soPrice: '0',
    dangCnt: '2',
    songdalFee: '0',
    // 구분: 결과 문자열
    spnResult1: '',
    spnResult2: '',
    // 구분: 결과 객체
    cRes: {
      soPrice: 0, // 소가
      injiPrice: 0, // 인지액
      refundPrice: 0, // 환급액
      songdalStandard: 0, // 1인당 송달 기준수
      dangCnt: 0, // 당사자수
      songdalFee: 0, // 1회 송달료
      totalSongdalFee: 0 // 계산된 총 송달료
    },
    // 구분: 소송방식이 전자인지 여부
    isSuitMethod2: false,
    // 구분: 당사자수 / 상대방수로 나뉜다.
    dangCntTitle: '*당사자수(명)',
    // 구분: 환급액 타이틀
    spnWgc: '환급액',
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        soPrice: '소가',
        dangCnt: '당사자수',
        songdalFee: '1회송달료'
      }
    }
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
    this.doInit()
  },

  watch: {
    // 구분: 소가 !![2021.3.17] 수정
    'soPrice': {
      handler: function (val, oldVal) {
        if (!val) {
          this.soPrice = '0'
          this.$refs.numSoPrice.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.soPrice = val.replace(/(^0+)/, '')
          }
          this.soPrice = numberFormatR(this.soPrice)
          this.soPrice = numberFormat(this.soPrice)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.soPrice)) {
            this.soPrice = oldVal
            this.$refs.numSoPrice.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 송달료
    'songdalFee': {
      handler: function (val, oldVal) {
        if (!val) {
          this.songdalFee = '0'
          this.$refs.numSongdalFee.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.songdalFee = val.replace(/(^0+)/, '')
          }
          this.songdalFee = numberFormatR(this.songdalFee)
          this.songdalFee = numberFormat(this.songdalFee)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.songdalFee)) {
            this.songdalFee = oldVal
            this.$refs.numSongdalFee.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 당사자수
    'dangCnt': {
      handler: function (val, oldVal) {
        if (!val) {
          this.dangCnt = '0'
          this.$refs.numDangCnt.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.dangCnt = val.replace(/(^0+)/, '')
          }
          this.dangCnt = numberFormatR(this.dangCnt)
          this.dangCnt = numberFormat(this.dangCnt)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.dangCnt)) {
            this.dangCnt = oldVal
            this.$refs.numDangCnt.lazyValue = oldVal
          }
        }
      }
    }
  },

  methods: {
    numberFormat,
    numberFormatR,
    preventComma,
    strToNumber,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 구분: 초기화
    async doInit () {
      try {
        // !! form validation 초기화
        this.$validator.reset()

        this.suitType1 = 1
        this.suitType2 = this.suitType2Options['s1'][0]
        this.suitMethod = 1

        this.soPrice = '0'
        this.dangCnt = '2'
        this.songdalFee = _songdalFee // 주의: 1회 송달료 - 지정된 상수로

        this.spnResult1 = ''
        this.spnResult2 = ''

        this.cRes.soPrice = 0 // 소가
        this.cRes.injiPrice = 0 // 인지액
        this.cRes.refundPrice = 0 // 환급액
        this.cRes.songdalStandard = 0 // 1인당 송달 기준수
        this.cRes.dangCnt = 0 // 당사자수
        this.cRes.songdalFee = 0 // 1회 송달료
        this.cRes.totalSongdalFee = 0 // 계산된 총 송달료

        this.isSuitMethod2 = false
        this.dangCntTitle = '*당사자수(명)'
        this.spnWgc = '환급액'

        this.setResultTitle()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 소송유형 1 변경 이벤트 핸들러 > 2가 변경 적용 된다!
    changeSuitType1 () {
      this.suitType2 = this.suitType2Options[`s${this.suitType1}`][0]
      this.setResultTitle()
    },
    // 구분: 소송유형 2 변경 이벤트 핸들러
    changeSuitType2 (value) { // value 는 선택한 option.value 임
      this.suitType2 = this.suitType2Options[`s${this.suitType1}`][value - 1]
      this.setResultTitle()
    },
    // 구분: 소송방식 변경 이벤트 핸들러
    changeSuitMethod () {
      // 소송방식이 전자인 경우 인지액 감면 메시지 view
      // 당사자수 - 종이인 경우 2, 전자인 경우 1
      if (this.suitMethod === 2) {
        this.isSuitMethod2 = true
        this.dangCnt = 1
        this.dangCntTitle = '*상대방수(명)'
      } else {
        this.isSuitMethod2 = false
        this.dangCnt = 2
        this.dangCntTitle = '*당사자수(명)'
      }
      this.setResultTitle()
    },
    // 구분: 상단 소송유형 / 소송방식 title 세팅
    setResultTitle () {
      try {
        let st1 = this.suitType1Options.find(o => o.value === this.suitType1).text
        let st2 = this.suitType2.text

        this.spnResult1 = `${st1} ${st2}`
        this.spnResult2 = this.suitMethodOptions.find(o => o.value === this.suitMethod).text
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 인지액 계산
    async calcIngiPrice () {
      try {
        const ingiType = this.suitType2.ict // 인지액 계산 유형
        let ingiFee = 0

        if (ingiType === 'A') {
          ingiFee = await this.calcIngiTypeA()
        } else if (ingiType === 'B') {
          ingiFee = await this.calcIngiTypeA()
          ingiFee = Math.floor((ingiFee * 15 / 10) / 100) // 10원단위 이하 절사
          ingiFee = ingiFee * 100
        } else if (ingiType === 'C') {
          ingiFee = await this.calcIngiTypeA()
          ingiFee = Math.floor((ingiFee * 2) / 100) // 10원단위 이하 절사
          ingiFee = ingiFee * 100
        } else if (ingiType === 'D') {
          ingiFee = await this.calcIngiTypeA()
          ingiFee = Math.floor((ingiFee * 1 / 10) / 100) // 10원단위 이하 절사
          ingiFee = ingiFee * 100
        } else if (ingiType === 'E') {
          ingiFee = await this.calcIngiTypeA()
          ingiFee = Math.floor((ingiFee * 2 / 10) / 100) // 10원단위 이하 절사
          ingiFee = ingiFee * 100
        }
        return ingiFee
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 인지액 계산유형 A
    async calcIngiTypeA () {
      try {
        let retVar = 0

        // 소숫점 계산 오류로 2개로 나눔
        // 0.0045 --> ingipercent1 = 45,  ingipercent2 = 10000 이렇게
        let ingipercent1 = 0
        let ingipercent2 = 0
        let bigo = 0

        let intSp = strToNumber(this.soPrice)

        if (intSp < 10000000) {
          ingipercent1 = 5
          ingipercent2 = 1000
          bigo = 0
        } else if (intSp >= 10000000 && intSp < 100000000) {
          ingipercent1 = 45
          ingipercent2 = 10000
          bigo = 5000
        } else if (intSp >= 100000000 && intSp < 1000000000) {
          ingipercent1 = 4
          ingipercent2 = 1000
          bigo = 55000
        } else if (intSp >= 1000000000) {
          ingipercent1 = 35
          ingipercent2 = 10000
          bigo = 555000
        }

        // 10원 단위 이하 절사
        retVar = Math.floor(((intSp * ingipercent1 / ingipercent2) + bigo) / 100)
        retVar = retVar * 100 // 10원단위 이하 절사한 것을 복구함.
        return retVar
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 계산 실행
    async doCalc () {
      try {
        if (strToNumber(this.soPrice) === 0) {
          this.soPrice = '0'
        }

        if (strToNumber(this.dangCnt) === 0) {
          this.dangCnt = '2'
        }

        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        if (!this.soPrice) throw new Error('소가를 입력해 주세요')
        if (!this.dangCnt) throw new Error('당사자수를 입력해 주세요')
        if (!this.songdalFee) throw new Error('송달료를 입력해 주세요')

        this.setResultTitle() // 소송유형, 소송방식 세팅

        // 주요 변수를 정수화
        const dangCnt = strToNumber(this.dangCnt)
        const songdalFee = strToNumber(this.songdalFee)

        // !! 1) 인지액 계산
        const ingiType = this.suitType2.ict // 인지액 계산 유형

        // 아래의 타입은 소가에 따라 인지액을 따로 계산
        if (ingiType === 'A' || ingiType === 'B' || ingiType === 'C' || ingiType === 'D' || ingiType === 'E') {
          this.cRes.injiPrice = await this.calcIngiPrice()
        } else { // 바로 20000 처럼 소가와 상관없이 인지액이 지정된 타입
          this.cRes.injiPrice = strToNumber(ingiType) // 정수값으로 변환
        }

        // [2016.7.20 추가] 소송유형이 '가사소송(다류)'인 경우 인지대는 x 0.5 (10원단위 절사)
        if (this.suitType1 === 4) {
          this.cRes.injiPrice = Math.floor((this.cRes.injiPrice * 1 / 2) / 100) // 10원단위 이하 절사
          this.cRes.injiPrice = this.cRes.injiPrice * 100 // 10원단위 이하 절사한 것 복구
        }

        // 최저가는 1000원
        if (this.cRes.injiPrice < 1000) {
          this.cRes.injiPrice = 1000
        }

        // 소송방식이 전자인 경우 인지액 * 0.9
        if (this.suitMethod === 2) {
          this.cRes.injiPrice = Math.floor((this.cRes.injiPrice * 9 / 10) / 100) // 10원단위 이하 절사
          this.cRes.injiPrice = this.cRes.injiPrice * 100 // 10원단위 이하 절사한 것 복구
        }

        // !! 2) 환급액 계산
        const hType = this.suitType2.htc // 환급타입

        if (hType === 'M1') {
          // 환급타입이 'M1'인 경우 (인지액 / 2 의 값이 100000이하일때를 기준으로)
          this.spnWgc = '환급액 : '
          let tmpHfee = this.cRes.injiPrice / 2

          if (tmpHfee < 100000) {
            this.cRes.refundPrice = this.cRes.injiPrice - 100000
          } else {
            this.cRes.refundPrice = this.cRes.injiPrice / 2
          }

          if (this.cRes.refundPrice < 0) {
            this.cRes.refundPrice = 0
          }
        } else if (hType === 'M2') {
          this.spnWgc = '본안소송추가액 : '

          // 이넘은 다시 A타입계산결과 - 인지대
          this.cRes.refundPrice = await this.calcIngiTypeA() - this.cRes.injiPrice
        } else if (hType === 'M3') {
          this.spnWgc = '환급액 : '
          this.cRes.refundPrice = 0
        }

        // !! 3) 총송달료 계산
        this.cRes.totalSongdalFee = this.suitType2.sdc * dangCnt * songdalFee // 총 송달료

        // !! 4) 결과변수에 주요변수들을 저장
        this.cRes.soPrice = this.soPrice // 소가
        this.cRes.songdalStandard = this.suitType2.sdc // 1인당 송달기준
        this.cRes.dangCnt = this.dangCnt // 당사자수
        this.cRes.songdalFee = this.songdalFee // 1회 송달료
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 클립보드에 복사하기
    // 주의: async 를 붙여선 안된다. 매뉴얼 참고
    async doCopy () {
      try {
        const elem = document.querySelector('#copyArea')
        if (!elem) throw new Error(`요청하는 엘리먼트가 없습니다.`)

        const headerTitle = '▣ 인지액.송달료\n'
        await copy(`${headerTitle}${elem.innerText}`)
        this.$store.commit('SB_POP', { msg: '클립보드에 복사되었습니다.', color: 'success' })
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
