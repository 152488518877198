import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import moment from 'moment'
import VueMomentJS from 'vue-momentjs'

// 유효성 검증 모듈 - 현재 2.2.x 버전 사용중 3.버전으로 업그레이드 하면 고칠데가 너무 많아서 보류 중
import VeeValidate from 'vee-validate' // yarn add vee-validate

// 훌륭한 모듈이지만 아직 사용은 안함. 더 자세히 알게 되면 설치
// import VuetifyDialog from 'vuetify-dialog' // yarn add vuetify-dialog

// 커스텀 함수 : src/lib/ 에 있는 파일들
// src/lib/ 에 있는것들 죄다 불러와야 하는가?
// 초기 로딩에 불리할려나?
// 주의: @/lib/holyday 에 있는 공휴일 정보도 초기로딩시 패칭으로 고칠지 결정 못했음..
import icons from '@/lib/icons' // icon 모음 => 고쳐: 이넘은 필요없을것 같다. 나중에 지워주자
import colors from '@/lib/colors' // color 모음

// vue-clipboard2
import VueClipboard from 'vue-clipboard2'

// * [2021.1.5] vue-browser-detect-plugin
import browserDetect from 'vue-browser-detect-plugin'

// 커스텀 필터 : src/filters/
// numberFormat.js 도 불러올까?

Vue.config.productionTip = false

moment.locale('ko') // 지역을 한국으로 세팅

// moment 사용 등록 - this.$moment() 로 사용하면 됨
Vue.use(VueMomentJS, moment)

// 폼 유효성 체크 모듈 사용 등록
Vue.use(VeeValidate)
// Vue.use(VuetifyDialog)

// use vue-clipboard
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

// * [2021.1.5] use vue-browser-detect-plugin
Vue.use(browserDetect)

Vue.prototype.$icons = icons // 공용 아이콘을 글로벌로 사용가능하게 한다
Vue.prototype.$colors = colors // 공용 선택색상을 글로벌로 사용가능하게 한다

// 참고: 달력 에러 메시지 안보이게 막는 꼼수. 언젠가는 삭제해야한다
// !! [2020.6.9] vuetify version 2.2.33 에서 해결됨
// const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.'
// Vue.config.warnHandler = function (msg, vm, trace) {
//   // `trace` is the component hierarchy trace
//   if (msg === ignoreWarnMessage) {
//     msg = null
//     vm = null
//     trace = null
//   }
// }

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
