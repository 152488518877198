<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        v-if="!isAutoInput"
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index === 1 && !isAutoInput"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>

            <!-- !! 담당부서 수정불가 코드 주석처리 [2021.1.15] -->
            <!-- <v-col v-if="index === 1" cols="12" xs="12" class="mt-0">
              <v-text-field
                v-model.trim="teamInfo.name"
                label="담당부서"
                readonly
              >
                <template v-slot:prepend>
                  <v-icon
                    :color="teamInfo.color"
                  >mdi-account-group</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col v-else cols="12" xs="12" class="mt-0">
              <v-select
                v-model="form.teamId"
                :items="teams"
                item-text="text"
                item-value="value"
                label="담당부서"
                v-validate="'required|decimal|greaterThanZero'"
                :error-messages="errors.collect('teamId')"
                data-vv-name="teamId"
                required
                dense
                menu-props="auto"
                class="mt-4"
              ></v-select>
            </v-col> -->
            <!-- <v-col cols="12" xs="12" class="pb-4">
              <v-alert v-show="index === 1" type="warning" dense text class="text-body-2" icon="mdi-information">
                담당부서의 변경은 관리자에게 문의하십시오.
              </v-alert>
            </v-col> -->
            <!-- <v-col v-else cols="12" xs="12" class="pb-4">
              <v-alert type="warning" dense text class="text-body-2" icon="mdi-information">
                담당부서를 선택하십시오. 최근에 생성된 담당부서가 상단에 위치합니다.
              </v-alert>
            </v-col> -->
            <!-- !! [2021.1] 담당부서를 수정가능하게 다시 변경 -->
            <v-col cols="12" xs="12" class="mt-0 pb-4">
              <v-select
                v-model="form.teamId"
                :items="teams"
                item-text="text"
                item-value="value"
                label="담당부서"
                v-validate="'required|decimal|greaterThanZero'"
                :error-messages="errors.collect('teamId')"
                data-vv-name="teamId"
                required
                dense
                menu-props="auto"
                class="mt-4"
              ></v-select>
              <v-alert v-show="index === 1" type="info" dense text class="text-body-2" icon="mdi-information">
                독립메뉴 업무는 담당부서를 수정할 수 있습니다.
              </v-alert>
            </v-col>
            <v-col cols="12" xs="12" sm="5">
              <v-select
                v-model="form.gubun1"
                :items="upmuCates"
                label="유형"
                dense
                menu-props="auto"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="2">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                    class="ml-3 pt-3"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>업무유형의 추가, 변경, 삭제는 [관리자>카테고리관리]에서 가능합니다.</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" xs="12" sm="5">
              <v-select
                v-model="form.status"
                :items="upmuStatus"
                label="관리상태"
                dense
                menu-props="auto"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-menu
                ref="date1Selector"
                v-model="date1Selector"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model.trim="form.date1"
                    label="일자"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    style="width: 200px;"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="form.date1"
                  locale="ko"
                  @change="saveDate1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="3"  class="text-right">
              <v-menu
                ref="timeSelector"
                v-model="timeSelector"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="time"
                    :disabled="noTime"
                    label="시간"
                    prepend-icon="access_time"
                    readonly
                    v-on="on"
                    style="width: 150px"
                    class="ml-5"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timeSelector"
                  v-model="time"
                  :disabled="noTime"
                  format="24hr"
                  @click:minute="$refs.timeSelector.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="5" class="text-left">
              <v-switch
                v-model="noTime"
                color="primary"
                :label="noTime ? '시간없음' : '시간있음'"
                class="mt-5 ml-10"
                @click.stop="chNoTime"
              ></v-switch>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.str3"
                label="장소"
                maxlength="50"
                counter="50"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.str2"
                label="업무자"
                maxlength="50"
                counter="50"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-textarea
                ref="txt1"
                v-model="form.str1"
                outlined
                label="내용"
                placeholder="입력하세요"
                no-resize
                rows="5"
                counter="200"
                :rules="[rules.txtCounter]"
                @update:error="chkValidate = false"
                class="mt-5"
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12">
              <v-alert
                border="left"
                outlined
                type="info"
                dense
                class="text-body-2"
              >
                독립메뉴 > 업무
                <v-tooltip color="info" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small color="primary lighten-2"
                      @click="gotoDetail"
                      class="ml-1"
                    >mdi-launch</v-icon>
                  </template>
                  <span>바로가기</span>
                </v-tooltip>
              </v-alert>
            </v-col>
            <!-- <v-col cols="12" v-show="index === 1" class="py-0">
              <v-card
                tile
                elevation="0"
                class="ma-0 pa-0"
              >
                <v-card-actions class="ma-0 pa-0">
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>print</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>get_app</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>phone_iphone</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>local_post_office</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <span class="text-caption mr-2">{{ viewWname }}</span><span class="text-caption">{{ viewUpdatedAt }}</span>
                </v-card-actions>
              </v-card>
            </v-col> -->
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import strDateFormat from '@/filters/strDateFormat'

export default {
  components: {
    baseModal
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      teamId: 0, // 중요: 초기는 선택으로 픽스
      type: 22, // * 유형 = 업무
      depth: 2,
      pId: 0,
      pType: 0, // * 부모타입이 없는 독립메뉴 업무는 부모타입이 0로 들어간다!
      pInfo: '', // 정보위치
      rId: 0,
      rType: 0,
      isStar: 0,
      isSchedule: 1,
      noTime: 1, // * [2021.4.7] 시간없음 추가
      subtype: 1, // 등록을 제외한 업무는 1로 세팅(등록은 자동입력데이터임)
      status: 1,
      gubun1: '업무',
      gubun2: '', // [2021.3.3] 업무관리상태 문자열 추가
      date1: '', // 업무일
      str1: '', // 내용
      str2: '', // 업무자
      str3: '' // 장소
    },
    // 구분:
    teams: [], // 소속팀
    // 구분: 업무유형 - 카테고리
    upmuCates: [],
    // 구분: 관리상태
    upmuStatus: [],
    // 구분:
    index: -1, // ![2022.8.31] index = 1 인 경우 수정 인덱스로도 쓰이지만 2는 다른곳에서 넘어온 추가 업무라는 의미로 쓰인다
    // 구분: 수정시 사용하는 팀정보
    teamInfo: {
      name: '',
      color: ''
    },
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        teamId: '담당부서' // !! [2021.1.26]담당부서 추가
      }
    },
    // 구분: 입력 검증
    rules: {
      txtCounter: v => v.length <= 200 || '200자 이하의 내용을 입력해 주세요'
    },
    // 구분: txt1 유효성 체크
    chkValidate: true,
    // 구분: 일자 - 시간
    date1Selector: false, // 일자 셀렉터
    timeSelector: false, // 시간 셀렉터
    time: '00:00', // 시간 초기값
    noTime: true, // 시간없음
    // 구분: 수정시 처리자와 최종업데이트 정보
    viewWname: '',
    viewUpdatedAt: '',
    // 구분: 자동입력 데이터인 경우
    isAutoInput: false
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  created () {
    this.$validator.extend(
      'greaterThanZero',
      {
        getMessage: field => field + ' 를 선택해야 합니다!',
        validate: (value) => {
          // value must be > zero
          if (value > 0) return true
          return false
        }
      })
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 자신이 속한 팀을 가져온다
    // 기본팀은 필수, (관리자팀,물품구매팀,회의실관리팀은 제외)
    async getTeams () {
      try {
        const { data } = await this.$axios.get('lawork/case/getMyBasicTeamInfo')
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        // 중요: 맨 앞에 '선택' 을 넣는다.
        data.teams.unshift({ text: '선택', value: 0 })
        // console.log(data.teams)

        this.form.teamId = data.teams[0].value

        return data.teams
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.form.id = 0
      this.form.teamId = 0 // 중요: 팀아이디 초기값
      this.form.pId = 0 // 부모 아이디
      this.form.pType = 0 // !! 부모타입이 없는 독립메뉴 업무는 부모타입이 0로 들어간다!
      this.form.pInfo = '' // 정보위치(부모의 정보 중 보여줄 정보)
      this.form.status = 1
      this.form.gubun1 = '업무'
      this.form.gubun2 = '' // [2021.3.3] 업무관리상태 문자열 추가
      this.form.isStar = 0
      this.form.isSchedule = 1 // 일정표시여부
      this.form.noTime = 1 // [2021.4.7 추가] 시간없음 여부
      this.form.date1 = this.$moment().format('YYYY-MM-DD') // 일시 초기값(오늘)
      this.form.str1 = '' // 내용
      this.form.str2 = '' // 업무자
      this.form.str3 = '' // 장소
      this.form.subtype = 1 // 이걸 해줘야 한다!

      this.time = '00:00' // 시간 초기값
      this.noTime = true

      this.isAutoInput = false

      this.index = -1 // 수정용 인덱스 초기화
    },
    async open (title, options, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 카테고리 : 업무유형 = 9
        this.setCates('9').then(({ cates }) => {
          if (cates && cates.length > 0) this.upmuCates = cates.map(c => c.gubun1)
          else this.upmuCates = []
        })

        // !! [2021.3.3 추가] 관리상태 카테고리 만들기
        this.setCates('22').then(({ cates }) => {
          if (cates && cates.length > 0) {
            this.upmuStatus.push(...cates.map(c => ({ text: c.gubun1, value: c.num })))
          }
        })

        // 초기화
        await this.initDialog()

        // 자신이 속한 팀리스트를 가져온다
        this.teams = await this.getTeams()

        // [2021.4.26] 등록시 업무자 초기값을 로그인 유저의 이름으로
        this.form.str2 = this.$store.state.ui.name

        // * [2022.8.31] 수정 모드인 경우 index = 1 로 넘어온다.
        if (index === 1) {
          // console.log(JSON.stringify(obj, null, 2))
          this.index = index

          this.form.id = obj.id

          // 중요: 매칭하지 않으면 사라져 버린다!
          this.form.teamId = obj.teamId

          this.form.status = obj.status
          this.form.gubun1 = obj.gubun1
          this.form.gubun2 = obj.gubun2 // [2021.3.3] 업무관리상태 문자열 추가
          this.form.subtype = obj.subtype
          this.form.isStar = obj.isStar

          // !! 등록과 같은 자동입력 데이터는 유형을 재조정 - 유형재조정은 포기
          if (obj.subtype === 0) {
            this.isAutoInput = true // 자동입력 데이터임을 표시
          }

          this.form.str1 = obj.str1 // 내용
          this.form.str2 = obj.str2 // 업무자
          this.form.str3 = obj.str3 // 장소

          // 일자-시간 처리
          // 중요:[2021.4.7] this.form.noTime 처리가 추가되었다
          this.form.noTime = obj.noTime
          this.form.date1 = obj.date1.substr(0, 10)
          if (this.form.noTime) { // 시간없음
            this.noTime = true
            this.time = '00:00'
          } else {
            this.noTime = false
            this.time = obj.date1.substr(11, 5)
          }

          // !! 팀명과 팀컬러 처리
          this.teamInfo.name = obj.teamName
          this.teamInfo.color = obj.teamColor

          // 참고: 편집일때는 처리자와 업데이트 일자를 보여준다.
          this.viewWname = obj.wname
          this.viewUpdatedAt = strDateFormat(obj.updatedAt) // 필터로 쓰진 않고 함수로 사용
          //
        } else if (index === 2) {
          // # index = 2 로 넘어온 경우 회의실 예약에서 '업무추가'로 넘어온 것이다.
          // console.log('*** In 업무 다이얼로그')
          // console.log(JSON.stringify(obj, null, 2))

          this.index = index

          this.form.teamId = obj.teamId
          this.form.type = 22
          this.form.depth = 2

          this.form.rId = obj.id // * obj.id 는 연계된 회의예약의 아이디임
          this.form.rType = 9 // * 역시 연계된 회의예약의 타입이다.

          // 무조건 시간을 등록해야 한다. 넘어온 시간으로 등록하면 된다.
          this.form.noTime = 0
          this.noTime = false
          this.time = obj.date1.substr(11, 5)

          this.form.gubun1 = '회의'
          this.form.gubun2 = '진행'

          this.form.date1 = obj.date1.substr(0, 10)

          this.form.str1 = '' // 내용
          this.form.str2 = obj.wname // 업무자

          // * 장소 패칭
          let jangSo = ''
          if (obj.rInfo) {
            const tmp = JSON.parse(obj.rInfo)
            jangSo = tmp.name
          }
          this.form.str3 = jangSo // 장소

          this.teamInfo.name = obj.teamName
          this.teamInfo.color = obj.teamColor
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 시간없음으로 바꾸는 경우 이벤트 핸들러
    async chNoTime () {
      try {
        this.$nextTick(() => {
          if (this.noTime) {
            this.form.noTime = 1
            this.time = '00:00'
          } else {
            this.form.noTime = 0
            this.time = '09:00'
          }
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 등록
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 중요: textarea 의 글자수가 200 이하로 조정되면 여기서 체크해서 처리한다.
        if (this.rules.txtCounter(this.$refs.txt1.value) === true) {
          this.chkValidate = true
        }
        if (!this.chkValidate) throw new Error('저장할 수 없습니다. 내용 항목의 글자수를 줄여주세요.')

        // !! 업무일시 문자열 만들기 - 년월일 + 시간 + 00초
        // 중요:[2021.4.7 수정] 시간이 없는 경우 00:20:01 로 들어가도록 했다!
        if (this.form.noTime) {
          this.form.date1 = `${this.form.date1.substr(0, 10)} 00:20:01`
        } else {
          this.form.date1 = `${this.form.date1.substr(0, 10)} ${this.time}:01`
        }

        // 중요: [2021.3.3] 업무관리상태의 변경으로 추가됨
        // 선택한 관리상태의 정수값(status)로 문자열값을 만들어준다
        this.form.gubun2 = this.upmuStatus.find(s => s.value === this.form.status).text

        // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        // !! DB 입력 처리 (등록/수정 동일하게 처리)
        const { data } = await this.$axios.post(`lawork/lwc/setUpmuContent`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        // 등록/수정 후
        await this.initDialog()
        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        await this.initDialog() // 초기화
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 삭제
    async remove () {
      try {
        if (!this.form.id) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // console.log(this.form)
          // !! 소송/자문의 삭제 함수를 그냥 이용한다!
          const { data } = await this.$axios.get(`/lawork/case/deleteLwcommon/${this.form.id}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          await this.initDialog() // 초기화
          this.resolve('removed')
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 일자 셀렉트 선택시 저장 함수
    saveDate1 (date) {
      this.$refs.date1Selector.save(date)
    },
    // !! 바로가기 링크 - 이 경우는 독립메뉴 리스트로
    gotoDetail () {
      this.dialog = false
      // !! 현재 경로가 work 디렉토리가 아닌 경우만 work/ 로 이동시킨다
      if (!(this.$router.history.current.path.indexOf('work') !== -1)) {
        this.$router.push(`/work/list`)
      }
    }
  }
}
</script>
