/**
 * @공휴일_객체
 */
const holyday = {
  // 2018년
  '2018-01-01': '신정',
  '2018-02-15': '설날',
  '2018-02-16': '설날',
  '2018-02-17': '설날',
  '2018-03-01': '삼일절',
  '2018-05-05': '어린이날',
  '2018-05-07': '어린이날(대체)',
  '2018-05-22': '석가탄신일',
  '2018-06-06': '현충일',
  '2018-06-13': '지방선거',
  '2018-08-15': '광복절',
  '2018-09-24': '추석',
  '2018-09-25': '추석',
  '2018-09-26': '추석(대체)',
  '2018-10-03': '개천절',
  '2018-10-09': '한글날',
  '2018-12-25': '성탄절',
  // 2019년
  '2019-01-01': '신정',
  '2019-02-04': '설날',
  '2019-02-05': '설날',
  '2019-02-06': '설날',
  '2019-03-01': '삼일절',
  '2019-05-05': '어린이날',
  '2019-05-06': '어린이날(대체)',
  '2019-05-12': '석가탄신일',
  '2019-06-06': '현충일',
  '2019-08-15': '광복절',
  '2019-09-12': '추석',
  '2019-09-13': '추석',
  '2019-09-14': '추석',
  '2019-10-03': '개천절',
  '2019-10-09': '한글날',
  '2019-12-25': '성탄절',
  // 2020년
  '2020-01-01': '신정',
  '2020-01-24': '설날',
  '2020-01-25': '설날',
  '2020-01-27': '설날(대체)',
  '2020-03-01': '삼일절',
  '2020-04-15': '국회의원선거',
  '2020-04-30': '석가탄신일',
  '2020-05-05': '어린이날',
  '2020-06-06': '현충일',
  '2020-08-15': '광복절',
  '2020-09-30': '추석',
  '2020-10-01': '추석',
  '2020-10-02': '추석',
  '2020-10-03': '개천절',
  '2020-10-09': '한글날',
  '2020-12-25': '성탄절',
  // 2021년
  '2021-01-01': '신정',
  '2021-02-11': '설날',
  '2021-02-12': '설날',
  '2021-02-13': '설날',
  '2021-03-01': '삼일절',
  '2021-05-05': '어린이날',
  '2021-05-19': '석가탄신일',
  '2021-06-06': '현충일',
  '2021-08-15': '광복절',
  '2021-09-20': '추석',
  '2021-09-21': '추석',
  '2021-09-22': '추석',
  '2021-10-03': '개천절',
  '2021-10-04': '대체휴일',
  '2021-10-09': '한글날',
  '2021-10-11': '대체휴일',
  '2021-12-25': '성탄절',
  // 2022년
  '2022-01-01': '신정',
  '2022-01-31': '설날',
  '2022-02-01': '설날',
  '2022-02-02': '설날',
  '2022-03-01': '삼일절',
  '2022-03-09': '대통령선거',
  '2022-05-05': '어린이날',
  '2022-05-08': '석가탄신일',
  '2022-06-01': '지방선거',
  '2022-06-06': '현충일',
  '2022-08-15': '광복절',
  '2022-09-09': '추석',
  '2022-09-10': '추석',
  '2022-09-11': '추석',
  '2022-09-12': '대체휴일',
  '2022-10-03': '개천절',
  '2022-10-09': '한글날',
  '2022-10-10': '대체휴일',
  '2022-12-25': '성탄절',
  // 2023년
  '2023-01-01': '신정',
  '2023-03-01': '삼일절',
  '2023-05-05': '어린이날',
  '2023-05-27': '석가탄신일',
  '2023-06-06': '현충일',
  '2023-08-15': '광복절',
  '2023-10-03': '개천절',
  '2023-10-09': '한글날',
  '2023-12-25': '성탄절',
  // 2024년
  '2024-01-01': '신정',
  '2024-03-01': '삼일절',
  '2024-05-05': '어린이날',
  '2024-05-27': '석가탄신일',
  '2024-06-06': '현충일',
  '2024-08-15': '광복절',
  '2024-10-03': '개천절',
  '2024-10-09': '한글날',
  '2024-12-25': '성탄절',
  // 2025년
  '2025-01-01': '신정',
  '2025-03-01': '삼일절',
  '2025-05-05': '어린이날',
  '2025-05-27': '석가탄신일',
  '2025-06-06': '현충일',
  '2025-08-15': '광복절',
  '2025-10-03': '개천절',
  '2025-10-09': '한글날',
  '2025-12-25': '성탄절',
  // 2026년
  '2026-01-01': '신정',
  '2026-03-01': '삼일절',
  '2026-05-05': '어린이날',
  '2026-05-27': '석가탄신일',
  '2026-06-06': '현충일',
  '2026-08-15': '광복절',
  '2026-10-03': '개천절',
  '2026-10-09': '한글날',
  '2026-12-25': '성탄절',
  // 2027년
  '2027-01-01': '신정',
  '2027-03-01': '삼일절',
  '2027-05-05': '어린이날',
  '2027-05-27': '석가탄신일',
  '2027-06-06': '현충일',
  '2027-08-15': '광복절',
  '2027-10-03': '개천절',
  '2027-10-09': '한글날',
  '2027-12-25': '성탄절',
  // 2028년
  '2028-01-01': '신정',
  '2028-03-01': '삼일절',
  '2028-05-05': '어린이날',
  '2028-05-27': '석가탄신일',
  '2028-06-06': '현충일',
  '2028-08-15': '광복절',
  '2028-10-03': '개천절',
  '2028-10-09': '한글날',
  '2028-12-25': '성탄절',
  // 2029년
  '2029-01-01': '신정',
  '2029-03-01': '삼일절',
  '2029-05-05': '어린이날',
  '2029-05-27': '석가탄신일',
  '2029-06-06': '현충일',
  '2029-08-15': '광복절',
  '2029-10-03': '개천절',
  '2029-10-09': '한글날',
  '2029-12-25': '성탄절'
}

export default holyday
