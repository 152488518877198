<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">확인</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->
          <v-row no-gutters>
            <v-col cols="12" xs="12">
              <v-card
                outlined
                class="pa-4"
              >
                <!-- <v-icon small class="mr-2">mdi-gamepad-circle-right</v-icon> -->
                <span class="text-h5">{{ article.subject }}</span>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12">
              <v-card
                outlined
                class="mt-2 pa-4"
              >
                <v-card-text>
                  <div
                    id="vue2editorViewer"
                    class="vue2editorViewer text-body-1"
                    v-html="article.content"
                  ></div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

export default {
  components: {
    baseModal
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: 공지사항 데이터
    articleId: 0,
    article: {}
  }),

  mounted () {
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      //
      this.articleId = 0 // 공지사항 아이디
    },
    // 구분:
    async open (title, options, id) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 초기화
        await this.initDialog()

        // 공지사항 아이디 매칭
        this.articleId = id

        await this.getNoticeById(this.articleId)

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 공지사항 내용 가져오기
    async getNoticeById (id) {
      try {
        if (!id) throw new Error('[ERR-0] 잘못된 인수형식 입니다.')

        const { data } = await this.$axios.get(`lawork/member/getNoticeById/${id}`)
        if (!data.success) throw new Error(`[ERR-1] ${data.message}`)
        this.article = data.article
        // console.log(this.article)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 확인
    async agree () {
      try {
        // !! DB 처리
        const form = {
          id: this.article.id
        }
        const { data } = await this.$axios.post(`lawork/member/confirmNotice`, form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        // 등록/수정 후
        await this.initDialog()
        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
.vue2editorViewer p {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  /* padding-top: 2px !important; */
  /* padding-bottom: 2px !important; */
}
iframe {
  width: 640px !important;
  height: 360px !important;
}
</style>
