// store/modules/lwc.js
// lawork common 모듈

// const USER_DATA = () => {
//   return {
//     id: void 0, // void 0은 undefined 로 지정된다.
//     password: void 0,
//     name: void 0,
//     age: 0,
//     job: 'programmer'
//   }
// }

export default {
  namespaced: true,

  state: () => ({
    // userData: USER_DATA(),
    isFlag: false,
    allTeams: [], // 모든 팀 목록
    myTeams: [], // 내가 속한 팀 목록
    myAuths: {} // 나의 권한목록
  }),

  // * 게터
  getters: {
    // getUserData: state => {
    //   return state.userData
    // },
    // ? 모든 팀목록
    getAllTeams: state => {
      return state.allTeams
    },
    // ? 내가 속한 팀 목록
    getMyTeams: state => {
      return state.myTeams
    },
    // ? 내가 속한 팀아이디만 배열로 리턴
    getMyTeamIds: state => {
      return state.myTeams.map(team => team.id)
    },
    // 중요: [커링]teamId를 넘기면 내가 속한팀인지 true/false로 리턴하는 동적게터
    // isMyTeam: state => tId => state.myTeams.find(team => team.id === tId)
    isMyTeam (state) {
      return (tId) => {
        // return state.myTeams.find(team => team.id === tId)
        return state.myTeams.map(team => team.id).includes(tId)
      }
    },
    // 팀 id 로 팀명을 가져옴 - 이를 위해서는 모든 팀의 정보가 필요한다
    getTeamNameByTid: state => tId => {
      return state.allTeams.find(team => team.id === tId).name
    },
    // 나의 권한을 가져온다
    // auth1: 수금,계약
    // auth2: 비용
    // auth3: 타임시트
    // auth4, 5 는 아직 어디에 사용될지 알 수 없다.
    getMyAuth: state => state.myAuths
  },

  mutations: {
    initData (state) {
      // state.userData = USER_DATA()
      state.myTeams = []
      state.isFlag = false
    },
    setUserData (state, payload) {
      // state.userData.id = payload.id
      // state.userData.password = payload.password
      // state.userData.name = payload.name
      // state.userData.age = payload.age
      // state.userData.job = payload.job
      //
      // or - 전개연산자를 활용한 얕은 복사
      // state.userData = { ...payload }
    },
    // 구분: 모든 팀정보 패칭
    setAllTeams (state, payload) {
      if (Array.isArray(payload)) {
        state.allTeams = payload
      }
    },
    // 구분: 내 소속팀 세팅하기
    setMyTeams (state, payload) {
      if (Array.isArray(payload)) {
        // state.myTeams = []
        state.myTeams = payload
      }
    },
    // 구분: 내 권한 세팅하기
    setMyAuths (state, payload) {
      state.myAuths = payload
    }
  },

  actions: {
    // 구분: 모든팀정보 세팅하기
    setAllTeams ({ commit }, payload) {
      commit('setAllTeams', payload)
    },
    // 구분: 내 소속팀 세팅하기
    setMyTeams ({ commit }, payload) {
      commit('setMyTeams', payload)
    },
    // 구분: 내 권한 세팅하기
    setMyAuths ({ commit }, payload) {
      commit('setMyAuths', payload)
    }
  }
}
