<template>
  <v-card
    tile
    :elevation="0"
  >
    <v-row no-gutters>
      <!-- 좌측 컨텐츠 시작 -->
      <v-col cols="12" md="6" lg="6" xl="6" class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-cog-refresh</v-icon> 계산조건
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              class="mr-2"
              @click.stop="doCalc"
            >
              <v-icon small>mdi-led-on</v-icon>
              계산
            </v-btn>
            <v-btn
              small
              @click.stop="doInit"
            >
              <v-icon small>mdi-refresh</v-icon>
              초기화
            </v-btn>
          </v-toolbar>
          <v-card
            tile
            outlined
            elevation="0"
            class="pa-2"
            min-height="568"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" class="pb-2">
                <span class="text-body-2 primary--text">*소송목적의 값(원)</span>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <v-text-field
                    ref="numSpPrice"
                    v-model.trim="spPrice"
                    v-validate="'required|max:15|is_not:0'"
                    :error-messages="errors.collect('spPrice')"
                    data-vv-name="spPrice"
                    required
                    maxlength="15"
                    dense
                    outlined
                    filled
                    style="max-width: 250px;"
                    @keypress="preventComma"
                  ></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="12" class="py-5">
                <!-- <span class="text-body-2 primary--text">접수일</span> -->
                <v-checkbox
                  v-model="ssr1"
                  dense
                  class="mt-0"
                  @click.stop="doCalc"
                >
                  <template v-slot:label>
                    <div>변론, 심문을 거친 가압류, 가처분 명령의 신청<br>그에 대한 이의, 취소의 신청</div>
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-model="ssr2"
                  label=""
                  dense
                  class="mt-0"
                  @click.stop="doCalc"
                >
                  <template v-slot:label>
                    <div>{{ ssr2Msg }}</div>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col cols="12" class="pb-2">
                <span class="text-body-2 primary--text">접수일</span>
              </v-col>
              <v-col cols="12">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <v-select
                    v-model="dor"
                    :items="dorOptions"
                    item-text="text"
                    item-value="value"
                    label=""
                    menu-props="auto"
                    class="ma-0"
                    dense
                    outlined
                    filled
                    style="max-width:300px;"
                    @change="changeDorValue"
                  ></v-select>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-card
                  elevation="0"
                  min-height="50"
                  class="error--text mt-0 px-0"
                >
                  <v-alert
                    dense
                    text
                    color="error"
                    class="text-caption"
                  >
                    <!-- <v-icon small color="error" class="mr-0">mdi-information-outline</v-icon> -->
                    ※ 재판부 재량에 의한 조정은 계산에서 제외합니다.
                  </v-alert>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
      <!-- <v-divider vertical class="ma-0 pa-0"></v-divider> -->
      <!-- 우측 컨텐츠 시작 -->
      <v-col class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-cube-send</v-icon> 계산결과
            <v-spacer></v-spacer>
            <v-btn
              small
              @click="doCopy"
              dark
            >
              <v-icon small>mdi-content-copy</v-icon>
              클립보드복사
            </v-btn>
          </v-toolbar>
          <v-card
            id="copyArea"
            tile
            outlined
            elevation="0"
            class="pa-2"
            min-height="568"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12">
                <div class="mt-2 mb-8 mr-2">
                  ● 소송목적의 값: <span class="text-body-2 font-weight-bold">{{ res1msg1 }}원</span>
                </div>
                <div class="mt-2 mb-8 mr-2">
                  ● 기본보수: <span class="text-body-2 font-weight-bold  primary--text">{{ res2msg1 }}원</span>
                </div>
                <div class="mt-2 mb-2 mr-2">
                  ◈ 최종 변호사 보수: <span class="text-body-2 font-weight-bold error--text">{{ res3msg1 }}원</span>
                </div>
                <v-chip
                  v-show="ssr1 && res1msg1 && res2msg1 && res3msg1"
                  outlined
                  color="primary"
                  class="mx-1"
                >
                  신청사건 감액 1/2
                </v-chip>
                <v-chip
                  v-show="ssr2 && res1msg1 && res2msg1 && res3msg1"
                  outlined
                  color="pink"
                  class="mx-1"
                >
                  자백 등 감액 1/2
                </v-chip>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import copy from 'copy-to-clipboard' // !! 클립보드 복사 플러그인
import ko from 'vee-validate/dist/locale/ko'

// 구분: filters
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import preventComma from '@/filters/preventComma' // keypress event 에서 쉼표막기
import strToNumber from '@/filters/strToNumber' // '10,000' => 를 10000 로 변환

export default {
  components: {
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    spPrice: '0', // 소송 목적의 값
    basicPay: 0, // 기본보수
    finalLawyerPay: 0, // 최종 변호사 보수
    ssr1: false, // 신청사건 감액여부
    ssr2: false, // 자백 등 감액여부
    dor: 1, // 접수일 초기값
    dorOptions: [
      { text: '2020.12.28 이후', value: 1 },
      { text: '2018.04.01 ~ 2020.12.27', value: 2 },
      { text: '2013.12.01~ 2018.03.31', value: 3 },
      { text: '2008.01.01~ 2013.11.30', value: 4 }
    ],
    res1msg1: '0',
    res2msg1: '0',
    res3msg1: '0',
    // 구분: 자백 등 감액 메시지
    ssr2Msg: '피고의 전부자백, 자백간주판결, 무변론판결, 이행권고결정',
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        spPrice: '금액'
      }
    }
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    this.doInit()
  },

  watch: {
    // 구분: 소송목적의 값 !![2021.3.17] 수정
    'spPrice': {
      handler: function (val, oldVal) {
        if (!val) {
          this.spPrice = '0'
          this.$refs.numSpPrice.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.spPrice = val.replace(/(^0+)/, '')
          }
          this.spPrice = numberFormatR(this.spPrice)
          this.spPrice = numberFormat(this.spPrice)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.spPrice)) {
            this.spPrice = oldVal
            this.$refs.numSpPrice.lazyValue = oldVal
          }
        }
      }
    }
  },

  methods: {
    numberFormat,
    numberFormatR,
    preventComma,
    strToNumber,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 구분: 초기화
    async doInit () {
      try {
        // !! form validation 초기화
        this.$validator.reset()

        this.spPrice = '0'
        this.basicPay = 0
        this.finalLawyerPay = 0

        this.dor = 1 // 접수일 초기값

        this.ssr1 = false // 신청사건 감액여부
        this.ssr2 = false // 자백 등 감액여부

        this.res1msg1 = '0'
        this.res2msg1 = '0'
        this.res3msg1 = '0'
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 금액에 number-fomat 적용
    spPriceNumberFormat () {

    },
    // 구분: 계산 실행
    async doCalc () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        if (this.spPrice) {
          // 1) 소송목적의 값
          this.res1msg1 = `${numberFormat(this.spPrice)}`

          // 2) 기본보수
          let bp = 10000000 // 기준값(천만원)
          let bp2 = 100000000 // 1억원

          // !![2021.3.17] 계산을 위해 정수형으로 변경한 소송목적의값
          const _sptp = strToNumber(this.spPrice)

          // ** [2020.12.28 새로운 보수표 적용] 기존과 다른점은 0 ~ 300 만원 구간이 신설되었음
          // ** [2018.4.1 새로운 보수표 적용]
          // 새로운 보수표가 나올 때마다 위쪽에 추가해 주어야 한다!
          if (this.dor === 1) {
            // 2020.12.28 ~

            if (_sptp > 0 && _sptp <= 3000000) { // 0 ~ 300만원까지 - 30만원
              this.basicPay = 300000 // 30 만원
              //
            } else if (_sptp > 3000000 && _sptp <= (bp * 2)) { // 300 ~ 2000만원 까지 - 10%
              this.basicPay = 300000 + (((_sptp - 3000000) * 10) / 100)
              //
            } else if (_sptp > (bp * 2) && _sptp <= (bp * 5)) { // 2000 ~ 5000만원 까지 - 8%
              this.basicPay = 2000000 + (((_sptp - (bp * 2)) * 8) / 100)
              //
            } else if (_sptp > (bp * 5) && _sptp <= (bp2 * 1)) { // 5000 ~ 1억원 까지 - 6%
              this.basicPay = 4400000 + (((_sptp - (bp * 5)) * 6) / 100)
              //
            } else if (_sptp > (bp2 * 1) && _sptp <= (bp2 * 1.5)) { // 1억원 ~ 1.5억원 까지 - 4%
              this.basicPay = 7400000 + (((_sptp - (bp2 * 1)) * 4) / 100)
              //
            } else if (_sptp > (bp2 * 1.5) && _sptp <= (bp2 * 2)) { // 1.5억원 ~ 2억원 까지 - 2%
              this.basicPay = 9400000 + (((_sptp - (bp2 * 1.5)) * 2) / 100)
              //
            } else if (_sptp > (bp2 * 2) && _sptp <= (bp2 * 5)) { // 2억원 ~ 5억원 까지 - 1%
              this.basicPay = 10400000 + (((_sptp - (bp2 * 2)) * 1) / 100)
              //
            } else if (_sptp > (bp2 * 5)) { // 5억원 초과 - 0.5%
              this.basicPay = 13400000 + (((_sptp - (bp2 * 5)) * 0.5) / 100)
              //
            }
          } else if (this.dor === 2) {
            // 2018.04.01 ~ 2020.12.27

            if (_sptp > 0 && _sptp <= (bp * 2)) { // 0 ~ 2000만원까지 - 10%
              this.basicPay = (_sptp * 10) / 100
              //
            } else if (_sptp > (bp * 2) && _sptp <= (bp * 5)) { // 2000 ~ 5000만원 까지 - 8%
              this.basicPay = 2000000 + (((_sptp - (bp * 2)) * 8) / 100)
              //
            } else if (_sptp > (bp * 5) && _sptp <= (bp2 * 1)) { // 5000 ~ 1억원 까지 - 6%
              this.basicPay = 4400000 + (((_sptp - (bp * 5)) * 6) / 100)
              //
            } else if (_sptp > (bp2 * 1) && _sptp <= (bp2 * 1.5)) { // 1억원 ~ 1.5억원 까지 - 4%
              this.basicPay = 7400000 + (((_sptp - (bp2 * 1)) * 4) / 100)
              //
            } else if (_sptp > (bp2 * 1.5) && _sptp <= (bp2 * 2)) { // 1.5억원 ~ 2억원 까지 - 2%
              this.basicPay = 9400000 + (((_sptp - (bp2 * 1.5)) * 2) / 100)
              //
            } else if (_sptp > (bp2 * 2) && _sptp <= (bp2 * 5)) { // 2억원 ~ 5억원 까지 - 1%
              this.basicPay = 10400000 + (((_sptp - (bp2 * 2)) * 1) / 100)
              //
            } else if (_sptp > (bp2 * 5)) { // 5억원 초과 - 0.5%
              this.basicPay = 13400000 + (((_sptp - (bp2 * 5)) * 0.5) / 100)
              //
            }
          } else if (this.dor === 3 || this.dor === 4) {
            // 2013.12.01 ~ 2018.03.31
            // 2008.01.01 ~ 2013.11.30
            // 위 2 기간의 보수 산정표는 동일하다.

            if (_sptp > 0 && _sptp <= (bp * 1)) { // 0 ~ 1000만원까지
              this.basicPay = (_sptp * 8) / 100
              //
            } else if (_sptp > (bp * 1) && _sptp <= (bp * 2)) { // 1000 ~ 2000만원 까지
              this.basicPay = 800000 + (((_sptp - (bp * 1)) * 7) / 100)
              //
            } else if (_sptp > (bp * 2) && _sptp <= (bp * 3)) { // 2000 ~ 3000만원 까지
              this.basicPay = 1500000 + (((_sptp - (bp * 2)) * 6) / 100)
              //
            } else if (_sptp > (bp * 3) && _sptp <= (bp * 5)) { // 3000 ~ 5000만원 까지
              this.basicPay = 2100000 + (((_sptp - (bp * 3)) * 5) / 100)
              //
            } else if (_sptp > (bp * 5) && _sptp <= (bp * 7)) { // 5000 ~ 7000만원 까지
              this.basicPay = 3100000 + (((_sptp - (bp * 5)) * 4) / 100)
              //
            } else if (_sptp > (bp * 7) && _sptp <= (bp2 * 1)) { // 7000 ~ 1억원 까지
              this.basicPay = 3900000 + (((_sptp - (bp * 7)) * 3) / 100)
              //
            } else if (_sptp > (bp2 * 1) && _sptp <= (bp2 * 2)) { // 1억원 ~ 2억원 까지
              this.basicPay = 4800000 + (((_sptp - (bp2 * 1)) * 2) / 100)
              //
            } else if (_sptp > (bp2 * 2) && _sptp <= (bp2 * 5)) { // 2억원 ~ 5억원 까지
              this.basicPay = 6800000 + (((_sptp - (bp2 * 2)) * 1) / 100)
              //
            } else if (_sptp > (bp2 * 5)) { // 5억원 초과 .. > 6억이면 10300000
              this.basicPay = 9800000 + (((_sptp - (bp2 * 5)) * 0.5) / 100)
              //
            }
          }
          this.res2msg1 = `${numberFormat(this.basicPay)}`

          // 3) 최종 변호사 보수
          // 신청사건 감액 & 자백 등 감액 계산
          if (this.ssr1 && this.ssr2) {
            // 둘 다 적용 1/4
            this.finalLawyerPay = Math.floor(this.basicPay * 0.25)
            //
          } else if (this.ssr1 && !this.ssr2) {
            // 신청사건만 감액적용
            this.finalLawyerPay = Math.floor(this.basicPay * 0.5)
            //
          } else if (!this.ssr1 && this.ssr2) {
            // 자백 등 감액적용
            this.finalLawyerPay = Math.floor(this.basicPay * 0.5)
            //
          } else {
            this.finalLawyerPay = Math.floor(this.basicPay)
            //
          }

          // 접수일 적용 [2020.12.28] 추가됨
          // 접수일 적용 [2018.04.01] 추가됨
          if (this.dor === 1) {
            // 2020.12.28 ~
            // 오히려 여기선 계산이 필요없다. 위의 계산식에서 0 ~ 300이면 30만원으로 고정시키므로 필요없다.
            //
          } else if (this.dor === 2) {
            // 2018.04.01 ~ 2020.12.27
            if (this.finalLawyerPay < 300000) {
              this.finalLawyerPay = 300000 // 30만원 미만이면 30만원
            }
            //
          } else if (this.dor === 3) {
            // 2013.12.01 ~ 2018.03.31
            if (this.finalLawyerPay < 300000) {
              this.finalLawyerPay = 300000 // 30만원 미만이면 30만원
            }
            //
          } else if (this.dor === 4) {
            // 2008.01.01 ~ 2013.11.30
            if (this.finalLawyerPay < 100000) {
              this.finalLawyerPay = 100000 // 10만원 미만이면 10만원
            }
            //
          }

          this.res3msg1 = `${numberFormat(this.finalLawyerPay)}`
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 접수일 셀렉트 변경시 이벤트 핸들러
    async changeDorValue () {
      try {
        // dor === 1 일때와 그외일때의 '자백 등 감액' 메시지가 다르다
        if (this.dor === 1) {
          this.ssr2Msg = '피고의 전부자백, 자백간주판결, 무변론판결, 이행권고결정'
        } else {
          this.ssr2Msg = '피고의 전부자백, 자백간주판결, 무변론판결'
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 클립보드에 복사하기
    async doCopy () {
      try {
        const elem = document.querySelector('#copyArea')
        if (!elem) throw new Error(`요청하는 엘리먼트가 없습니다.`)

        const headerTitle = '▣ 변호사보수산정\n'
        await copy(`${headerTitle}${elem.innerText}`)
        this.$store.commit('SB_POP', { msg: '클립보드에 복사되었습니다.', color: 'success' })
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
