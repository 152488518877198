<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <!-- <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn> -->

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0">

          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <!-- <v-col cols="12" xs="12" class="py-2 px-0">
              <v-toolbar dense flat class="pa-0">
                <v-select
                  v-model="form.gubun1"
                  :items="cates"
                  label="구분"
                  menu-props="auto"
                  hide-details
                  dense
                  style="max-width:150px; font-size: 0.785rem !important"
                ></v-select>

                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                      class="ml-0 pt-5"
                    >mdi-help-circle-outline</v-icon>
                  </template>
                  <span>게시판 구분의 추가, 변경, 삭제는 [관리자>카테고리관리]에서 가능합니다.</span>
                </v-tooltip>

                <v-select
                  v-model="form.teamId"
                  :items="teams"
                  label="공개범위"
                  menu-props="auto"
                  hide-details
                  dense
                  style="max-width:150px;font-size: 0.785rem !important"
                  :disabled="index > -1 ? true : false"
                ></v-select>

                <v-switch
                  v-model="form.isNotice"
                  color="primary"
                  label="공지사항"
                  dense
                  class="ml-5"
                ></v-switch>
              </v-toolbar>
            </v-col> -->
            <v-col cols="12" xs="12">
              <v-row
                no-gutters
                align="start"
              >
                <v-col cols="3">
                  <v-select
                    v-model="form.gubun1"
                    :items="cates"
                    label="구분"
                    menu-props="auto"
                    hide-details
                    dense
                    class="pt-3"
                    style="font-size: 0.785rem !important"
                  ></v-select>
                </v-col>
                <v-col cols="1" class="text-left ml-2">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        v-on="on"
                        class="ml-0 pt-5"
                      >mdi-help-circle-outline</v-icon>
                    </template>
                    <span>게시판 구분의 추가, 변경, 삭제는 [관리자>카테고리관리]에서 가능합니다.</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="form.teamId"
                    :items="teams"
                    label="공개범위"
                    menu-props="auto"
                    hide-details
                    dense
                    class="pt-3"
                    style="font-size: 0.785rem !important"
                    :disabled="index > -1"
                  ></v-select>
                </v-col>
                <v-col class="text-right ml-2">
                  <v-switch
                    v-model="form.isNotice"
                    color="primary"
                    label="공지사항"
                    dense
                    class="ml-5"
                    :disabled="index > -1"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                ref="txtSubject"
                v-model.trim="form.subject"
                v-validate="'required|max:50'"
                :error-messages="errors.collect('subject')"
                data-vv-name="subject"
                required
                placeholder="입력하세요"
                :maxlength="subjectLimit"
                label="제목"
                class="pa-0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <!-- vue2editor -->
              <vue-editor
                v-model="form.content"
                :editor-toolbar="customToolbar"
                style="height:400px;"
                class="mb-12"
              ></vue-editor>
            </v-col>
            <v-col cols="12" xs="12">
              <v-alert
                border="left"
                outlined
                type="info"
                dense
                class="mt-3 mb-0 py-2"
              >
                파일첨부는 상세페이지에서 가능합니다.
              </v-alert>
              <div
                v-show="index <= -1"
                class="mb-5"
              ></div>
            </v-col>
            <!-- <v-col cols="12" v-show="index > -1" class="pt-0">
              <v-card
                tile
                elevation="0"
              >
                <v-card-actions>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>print</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>get_app</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>phone_iphone</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>local_post_office</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <span class="text-caption mr-2">{{ viewWname }}</span><span class="text-caption">{{ viewUpdatedAt }}</span>
                </v-card-actions>
              </v-card>
            </v-col> -->
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

    </baseModal>
  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'
import strDateFormat2 from '@/filters/strDateFormat2'

// !! vue2editor
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    baseModal,
    VueEditor
  },

  props: ['article'],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 800,
      height: 600,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0, // 아이디
      wemail: '', // 작성자 이메일
      wname: '', // 작성자명
      gubun1: '', // 카테고리
      subject: '', // 제목
      content: '', // 내용
      isStar: 0, // 별 - 중요여부
      isNotice: 0, // 공지여부
      teamId: 1 // 연계팀 id - 초기값은 기본팀
    },
    dictionary: {
      messages: ko.messages,
      attributes: {
        subject: '제목'
      }
    },
    index: -1, // !! 수정 인덱스
    // viewWname: '', // 처리자를 보여주기만 하는 변수
    // viewUpdatedAt: '', // 업데이트 일자를 보여주기 위한 변수
    cates: [], // 게시판 카테고리
    teams: [], // 연계팀 셀렉트
    // 구분: 에디터 용
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }, { background: [] }],
      ['link']
    ],
    // 구분: 제목 글자수 제한
    subjectLimit: 50
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  watch: {
    'form.subject': {
      handler: function (val, oldVal) {
        // 글자수 제한을 넘지 못하게 깔끔하게 막는 방법
        if (val.length > this.subjectLimit) {
          this.form.subject = oldVal
          this.$refs.txtSubject.lazyValue = oldVal
        }
      }
    }
  },

  methods: {
    strDateFormat2,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 초기화 함수
    async initDialog () {
      // form validation 초기화
      this.$validator.reset()

      // 작성자 정보(로그인 유저)
      this.form.wemail = this.$store.state.ui.email
      this.form.wname = this.$store.state.ui.name

      this.form.gubun1 = ''
      this.form.subject = ''
      // this.form.content = ''

      this.form.id = 0
      this.form.teamId = 1 // 팀 초기값은 기본팀으로
      this.form.isStar = 0
      this.form.isNotice = 0

      this.index = -1 // !! 수정용 인덱스 초기화
    },
    async open (title, options, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 카테고리 구분 셀렉트 지정
        await this.setCates('13').then(({ cates }) => {
          if (cates && cates.length > 0) {
            this.cates = cates.map(c => c.gubun1)
          }
        })

        await this.initDialog()

        // 팀 카테고리 셀렉트 지정 - 소속팀 목록의 역순
        await this.getAllTeamInfoByEmail(this.$store.state.ui.email).then(({ teams }) => {
          if (teams && teams.length > 0) {
            // this.teams = teams.map(t => ({ text: t.name, value: t.id })).reverse() // 역순으로 처리. 기본팀 부터 나온다
            // !![2021.7.22] 역순으로 처리하지 않도록 한다. 가장 최근에 추가된 팀부터 나온다.
            // this.teams = teams.map(t => ({ text: t.name, value: t.id }))
            // !![2021.7.27] 회의실관리팀을 빼고 리스트를 만든다.(차후 되돌린다)
            teams.map(t => {
              if (t.id !== 4) this.teams.push({ text: t.name, value: t.id })
            })
            this.form.teamId = this.teams[0].value // !! 가장 최근 값을 초기값으로 매칭한다
          }
        })

        // * 편집 모드인 경우
        if (index > -1) {
          this.index = index

          // this.form = Object.assign(this.form, obj)
          // 참고: 사용하는 변수만 폼값에 매칭하자!
          this.form.id = obj.id
          this.form.teamId = obj.teamId

          this.form.gubun1 = obj.gubun1
          this.form.subject = obj.subject
          this.form.content = obj.content

          this.form.isStar = obj.isStar
          this.form.isNotice = obj.isNotice

          // * 아래 2줄 주석처리 - 수정시 작성자가 변경되지 않는 버그 유발함
          // this.form.wemail = obj.wemail
          // this.form.wname = obj.wname

          // 사용안함: 편집일때는 처리자와 업데이트 일자를 보여준다.
          // this.viewWname = obj.wname
          // this.viewUpdatedAt = strDateFormat2(obj.updatedAt) // 필터로 쓰진 않고 함수로 사용
        } else {
          // !! 입력모드인 경우
          // 구분 카테고리만 초기값을 지정해 주면 된다
          this.form.gubun1 = this.cates[0]
          this.form.content = ''
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.ddㅁㅁ
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 등록 버튼
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        if (!this.form.content) {
          throw new Error('내용을 입력하세요')
        }

        let affectedId = 0

        if (this.index > -1) {
          // !! 편집 모드인 경우
          // console.log(this.form)
          const { data } = await this.$axios.post('lawork/bbs/editArticle', this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
          affectedId = data.editedId

          // 중요: 부모에서 받은 내용을 업데이트 해줘야 수정창을 닫은 후에 반영된다!
          this.article.content = this.form.content
        } else {
          // !! 입력 모드인 경우
          const { data } = await this.$axios.post('lawork/bbs/addArticle', this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
          // !! 정상적으로 등록이 되면 App.vue bbsAdd() 함수로 등록된 아이디를 보낸다.
          affectedId = data.insertId
        }

        await this.initDialog()
        this.resolve(affectedId)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소 버튼
    async cancel () {
      try {
        await this.initDialog()
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 로그인 유저의 팀목록 패칭
    async getAllTeamInfoByEmail (email) {
      try {
        const { data } = await this.$axios.get(`admin/staff/getAllTeamInfoByEmail/${email}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)

        return { teams: data.teams }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style scoped>
/* .quillWrapper{
  height: 200px;
  max-height: 200px;
} */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
