const icons = {
  i01: { color: 'blue', textColor: 'white', caption: '기일', outlined: false },
  i02: { color: 'red', textColor: 'white', caption: '기한', outlined: false },
  i03: { color: 'brown', textColor: 'white', caption: '제출', outlined: false },
  i04: { color: 'orange accent-4', textColor: 'white', caption: '송달', outlined: false },
  i05: { color: 'green', textColor: 'white', caption: '명령', outlined: false },

  i06: { color: 'black', textColor: 'white', caption: '접수', outlined: false },
  i07: { color: 'black', textColor: 'white', caption: '공고', outlined: false },
  i08: { color: 'black', textColor: 'white', caption: '종국', outlined: false },
  i09: { color: 'black', textColor: 'white', caption: '경유', outlined: false },

  i10: { color: 'grey darken-1', textColor: 'white', caption: '등록', outlined: false },
  i11: { color: 'grey darken-1', textColor: 'white', caption: '관리', outlined: false },

  i12: { color: 'teal lighten-1', textColor: 'white', caption: '계약', outlined: false },
  i13: { color: 'teal lighten-1', textColor: 'white', caption: '수금', outlined: false },
  i14: { color: 'teal lighten-1', textColor: 'white', caption: '비용', outlined: false },
  i15: { color: 'teal lighten-1', textColor: 'white', caption: '타임', outlined: false },
  i16: { color: 'teal lighten-1', textColor: 'white', caption: '거래', outlined: false },
  i17: { color: 'teal lighten-1', textColor: 'white', caption: '정산', outlined: false },

  i18: { color: 'purple', textColor: 'white', caption: '업무', outlined: false },
  i19: { color: 'purple', textColor: 'white', caption: '결재', outlined: false },
  i20: { color: 'purple', textColor: 'white', caption: '상담', outlined: false },

  i21: { color: 'deep-orange darken-1', textColor: 'white', caption: 'SMS', outlined: false },
  i22: { color: 'deep-orange darken-1', textColor: 'white', caption: 'eMAIL', outlined: false },

  i23: { color: 'ime darken-3', textColor: 'white', caption: '의뢰인', outlined: false },
  i24: { color: 'ime darken-3', textColor: 'white', caption: '변호인', outlined: false },

  i25: { color: '', textColor: 'black', caption: '결재', outlined: true },
  i26: { color: '', textColor: 'black', caption: '쟁점', outlined: true },
  i27: { color: '', textColor: 'black', caption: '댓글', outlined: true },
  i28: { color: '', textColor: 'black', caption: '이관', outlined: true },

  i29: { color: 'grey lighten-1', textColor: 'grey darken-1', caption: '대기', outlined: true },
  i30: { color: 'deep-orange accent-3', textColor: 'deep-orange accent-3', caption: '반려', outlined: true },

  i31: { color: '', textColor: 'black', caption: '개별수임계약', outlined: true },
  i32: { color: 'light-blue darken-4', textColor: 'light-blue darken-4', caption: '고문계약', outlined: true },

  i33: { color: 'blue darken-4', textColor: 'white', caption: '진행', outlined: false },
  i34: { color: 'black', textColor: 'white', caption: '종결', outlined: false },
  i35: { color: 'red', textColor: 'white', caption: '상담', outlined: false },
  i36: { color: 'grey lighten-1', textColor: 'black', caption: '보류', outlined: false }
}

export default icons
