import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import store from './store'

import storage from '@/lib/storage'

import Home from './views/Home.vue'

Vue.use(Router)

Vue.prototype.$axios = axios

// * 백앤드로 보내는 루트 경로 - 개발중/실서비스 모드에 따라 적용이 달라진다.
const apiRootPath = process.env.NODE_ENV !== 'production' ? 'http://localhost:3000/v1/' : '/v1/'
Vue.prototype.$apiRootPath = apiRootPath
axios.defaults.baseURL = apiRootPath
// axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')

// NOTE: axios 요청 인터셉터 - Add a axios request interceptor
axios.interceptors.request.use(function (config) {
  // console.log('LOG: axios request')
  // * api 서버(백엔드)에 요청을 보낼때마다 헤더에 토큰을 보낸다. 인증을 위해 필수절차임
  // * local storage 에 token 이 있으면 무조건 백앤드로 요청을 보내는 구조임. 그러므로 새로 로워크를 만들어 테스트할때는 token 을 삭제해야 한다.
  config.headers.Authorization = storage.get('token')

  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

// NOTE: axios 응답 인터셉터 - Add a axios response interceptor
axios.interceptors.response.use(function (response) {
  // console.log('LOG: axios response')
  // console.log(response.data)
  // ? api 서버(백엔드)에서 응답을 받을 때마다 리프레시 토큰을 받고 확인한다.
  // ? 백엔드 서버의 jwt middleware 에서 리프레시된 토큰 갱신 -> 이후 실행되는 함수에서 리턴 받으면 실행되는 로직이다.
  // ? 리프레시 토큰이 있으면(=null 이 아니면) 토큰이 갱신된 것이다.
  // ?  - 갱신된 토큰은 vuex 저장소와 localStorage 에 저장된다.
  // ?  - 구글에서 받아온 refresh_token이 아님! 새로 갱신된 자체토큰임!
  const token = response.data.refreshToken
  if (token) {
    store.dispatch('LOGIN', { token })
  }

  // [일단폐기] 토큰 푼 정보를 백엔드에서 받으려고 하였으나 네트워크 용량 증가예상으로 폐기
  // console.log('userInfo:', response.data.userInfo)

  return response
}, function (error) {
  const errorMessage = `(${error.response.status}: ${error.response.data.message})`
  // console.log(errorMessage)

  switch (error.response.status) {
    case 400:
      // sbpop(`잘못된 요청입니다${errorMessage}`)
      store.commit('MD_POP', { active: true, msg: '잘못된 요청입니다.' })
      break
    case 401:
      store.dispatch('LOGOUT')
      // sbpop(`인증 오류입니다${errorMessage}`)
      store.commit('MD_POP', { active: true, msg: '인증 오류입니다.' })
      break
    case 403:
      // # be/middlewares/jwt.middleware.js 에서 기간이 지난 경우 발생시키는 403 에러
      if (errorMessage.indexOf('term expired') > 0) { // 이용기간이 만료되었을 때!
        // # 확인 버튼을 누르면 결제 페이지로 빼는 팝업을 호출한다. 로그아웃 시키지 않는게 키포인트
        // * 발동조건 - 사용중에 기간이 지난거야.. 새벽이겠지 우리 프로그램은..
        //    1시간 단위로 구글 억세스 토큰이 재발급될때 이 조건이 발동된다. 기간이 지났음을 인지하면 아래 팝업이 뜰거고 ..
        store.commit('PY_POP', { active: true, msg: '이용 기간이 만료되었습니다.<br>확인을 누르면 결제 페이지로 이동됩니다.' })
        // 로그아웃 되지 않은 상태임.. 결제창으로 어떻게 보내지?
        // 기존 토큰은 만료된 상태일 것임.
        // location.href = '/member/payment' // !! 무한루프 걸림..
      } else {
        sbpop(`이용 권한이 없습니다${errorMessage}`)
      }
      break
    default: // 그 외의 에러 500 서버 에러 등..
      if (errorMessage.indexOf('jwt expired') > 0 || errorMessage.indexOf('jwt malformed') > 0) {
        // # 토큰 만료 오류 혹은 잘못된 토큰 오류가 날때 -> 로그아웃 시키고 초기화면으로 넘긴다.
        store.dispatch('LOGOUT') // 로그아웃 처리
        store.commit('MD_POP', { active: true, msg: '[CHECK_SUM: 001]보안체크를 위해 로그아웃 됩니다.' })
        //
      } else if (errorMessage.indexOf('unauthorized_client') > 0) {
        // # jwt.middleware.js 152줄에서 에러 - 저장된 리프레시 토큰이 유효하지 않을때 나는 오류
        store.dispatch('LOGOUT')
        store.commit('MD_POP', { active: true, msg: '[CHECK_SUM: 002]보안체크를 위해 로그아웃 됩니다.' })
        //
      } else if (errorMessage.indexOf('refresh_token 정보가 없습니다') > 0) {
        // # [2022.5.31] 시간이 지나서 refresh_token 정보가 유실된 경우의 에러
        store.dispatch('LOGOUT')
        store.commit('MD_POP', { active: true, msg: '[CHECK_SUM: 003]보안체크를 위해 로그아웃 됩니다.' })
        //
      } else {
        // sbpop(`알수 없는 오류입니다${errorMessage}`)
        // # [2022.5.31] 토큰이 풀릴 시간이 아닌데 풀려서 서버로의 연결이 끊기는 현상이 벌어진다.
        // # 해서 위의 토큰 만료 오류와 동일하게 처리한다.
        store.dispatch('LOGOUT') // 로그아웃 처리
        store.commit('MD_POP', { active: true, msg: '서버연결이 종료되었습니다.<br>보안을 위해 자동로그아웃 됩니다.' })
      }
      break
  }
  return Promise.reject(error)
})

// 해당 컴포넌트로 이동시키는 메소드(https://codesandbox.io/s/y3504yr0l1?from-embed)
// const useComponent = component => () => import(`./views${component}.vue`) // 여기서 eslint 에러가 왜 날까?
const useComponent = component => () => import('./views' + component + '.vue')

// NOTE: (라우터 후킹)기본적인 로그인 감시 - 비정상적인 경우 무조건 로그아웃!
// * 이 함수가 먼저 실행되고, 그 후에 위의 axios.interceptors.response.error() 가 처리된다..
// * 메시지를 동일하게 맞춘다..
const userAuthCheck = () => {
  // localStorage 와 vuex 모두에 토큰이 있어야 한다!
  if (!storage.get('token') || !store.state.token || !store.state.ui.dbcode || store.state.ui.isAgree === 'N') {
    store.dispatch('LOGOUT') // 로그아웃 처리
    store.commit('MD_POP', { active: true, msg: '유효한 토큰정보가 아닙니다.<br>보안을 위해 로그아웃 됩니다.' })
  } else {
    // if (!store.state.ui.dbcode || store.state.ui.isAgree === 'N') {
    //   // !! 동의도 없고 dbcode(조직)도 없으면 무조건 로그아웃
    //   store.dispatch('LOGOUT') // 로그아웃 처리
    //   store.commit('MD_POP', { active: true, msg: '보안을 위해 자동로그아웃 됩니다.' })
    // }
  }
}

// @: 기본 로그인 감시만 적용하는 경우 사용
const requireAuth = () => (to, from, next) => {
  userAuthCheck()
  next()
}

// @: 기본 로그인 감시 + 관리자만 들어가게 하는 함수
const requireAdmin = () => async (to, from, next) => {
  userAuthCheck()

  // 관리(대행)자가 아니면 팅긴다
  // if (data.auths.t1 && (store.state.ui.lv === 'M' || store.state.ui.lv === 'G')) {

  // * [2020.7.29] 관리자 권한 실시간 체크 추가!
  const { data } = await axios.get(`lawork/lwc/getAllTeamIds`)
  if (!data.success) sbpop(`오류가 발생하였습니다: ${data.message}`)
  // data.auths.t1 조건만으로 실시간으로만 따지기로 함.
  // store.state.ui.lv 는 local storage에는 변경이 되는 시점이 언제인지 모르므로..
  if (data.auths.t1) {
    next()
  } else {
    sbpop('권한이 없습니다. 관리(대행)자 전용 메뉴입니다.')
  }
}

// @: 물품관리권한 실시간 체크: 기본 로그인 감시도 병행
// * lawork/lwc/getAllTeamIds 에 문의 후 돌아오는 auths.t2 가 물품관리권한임
const requireSupplyAuth = () => async (to, from, next) => {
  userAuthCheck()
  // console.log(`from: ${from.path}`)
  // console.log(`to: ${to.path}`)
  // 주의: 구매신청이 아닌 경우만 권한체크
  const { data } = await axios.get(`lawork/lwc/getAllTeamIds`)
  if (!data.success) sbpop(`오류가 발생하였습니다: ${data.message}`)
  if (!data.auths.t2) {
    sbpop('물품관리권한이 없습니다. 관리(대행)자에게 문의하십시오.')
  } else {
    next()
  }
}

// @: 수금/계약 관리권한 검사
const requireSugumContractAuth = () => async (to, from, next) => {
  userAuthCheck()
  const { data } = await axios.get(`lawork/lwc/getStaffAuths`)
  if (!data.success) sbpop(`오류가 발생하였습니다: ${data.message}`)
  if (!data.auths.auth1) {
    let pathName = to.name === 'sugumList' ? '수금' : '계약'
    sbpop(`${pathName}관리권한이 없습니다. 관리(대행)자에게 문의하십시오.`)
  } else {
    next()
  }
}

// @: 비용 관리권한 검사
const requireCostAuth = () => async (to, from, next) => {
  userAuthCheck()
  const { data } = await axios.get(`lawork/lwc/getStaffAuths`)
  if (!data.success) sbpop(`오류가 발생하였습니다: ${data.message}`)
  if (!data.auths.auth2) {
    sbpop('비용관리권한이 없습니다. 관리(대행)자에게 문의하십시오.')
  } else {
    next()
  }
}

// @: 타임시트 관리권한 검사
const requireTimesheetAuth = () => async (to, from, next) => {
  userAuthCheck()
  const { data } = await axios.get(`lawork/lwc/getStaffAuths`)
  if (!data.success) sbpop(`오류가 발생하였습니다: ${data.message}`)
  if (!data.auths.auth3) {
    sbpop('타임시트관리권한이 없습니다. 관리(대행)자에게 문의하십시오.')
  } else {
    next()
  }
}

// 구분: 스낵바 에러 메시지 알림을 띄우는 함수다
// * 2020.6.16] v-snackbar 에 shaped 속성 추가
const sbpop = msg => {
  store.commit('SB_POP', { msg, color: 'error', timeout: 5000, shaped: true })
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      // meta: { KeepAlive: true }, // * 메타태그에 변수 매칭(필요없어서 삭제)
      component: Home
    },
    { // 구분: 개인정보처리방침
      path: '/privacy',
      name: 'privacy',
      component: useComponent('/privacy')
    },
    { // 구분: 이용약관
      path: '/termsofuse',
      name: 'termsofuse',
      component: useComponent('/termsofuse')
    },
    { // 구분: 테스트용 - 로컬 8080 용 토큰 생성 페이지
      path: '/auth/copyToken',
      name: 'copyToken',
      component: useComponent('/auth/copyToken')
    },
    { // ! 로그인 페이지 - 구글 로그인으로 이동 (/auth/ 에서 /member/ 로 옮김(2020.4.6))
      path: '/member/login',
      name: 'login',
      component: useComponent('/member/login')
    },
    { // 구분: 첫 로그인 화면 -> /auth/ 에서 /member/ 로 옮김(2020.4.6)
      path: '/member/firstLogin',
      name: 'firstLogin',
      component: useComponent('/member/firstLogin')
    },
    { // 구분: 결제 화면
      path: '/member/payment',
      name: 'payment',
      component: useComponent('/member/payment')
    },
    { // 구분: 카드결제 성공 화면
      path: '/member/cardSuccess',
      name: 'cardSuccess',
      component: useComponent('/member/cardSuccess')
    },
    { // 구분: 카드결제 실패 화면
      path: '/member/cardFailed',
      name: 'cardFailed',
      component: useComponent('/member/cardFailed')
    },
    { // 구분: 결제대기 화면
      path: '/member/payRequest',
      name: 'payRequest',
      component: useComponent('/member/payRequest')
    },
    { // 구분: 승인대기 화면
      path: '/member/waitAdmit',
      name: 'waitAdmit',
      component: useComponent('/member/waitAdmit')
    },
    { // 구분: 알림리스트 화면
      path: '/member/alarm/:mode?',
      name: 'alarm',
      component: useComponent('/member/alarm')
    },
    { // 구분: 개인정보 화면
      path: '/member/myInfo',
      name: 'myInfo',
      component: useComponent('/member/myInfo')
    },
    { // # 구분: 회원가입 페이지 1 - 동의 팝업(이미 동의한 경우 자동으로 스킵됨)
      path: '/auth/regist1',
      name: 'regist1',
      component: useComponent('/auth/regist1')
    },
    { // 회원가입 페이지 2 - 조직생성 or 조직공유 or 탈퇴 선택
      path: '/auth/regist2',
      name: 'regist2',
      component: useComponent('/auth/regist2')
    },
    { // 구분: 관리자 신규 조직생성
      path: '/auth/setWorkGroupInfo',
      name: 'setWorkGroupInfo',
      component: useComponent('/auth/setWorkGroupInfo')
    },
    { // 구분: 일반유저 조직공유 요청
      path: '/auth/requestLawWorkShare',
      name: 'requestLawWorkShare',
      component: useComponent('/auth/requestLawWorkShare')
    },
    { // 구분: 일반유저 공유 요청중 페이지
      path: '/auth/requestShareIng',
      name: 'requestShareIng',
      component: useComponent('/auth/requestShareIng')
    },
    { // 구분: 탈퇴 페이지
      path: '/auth/requestLeave',
      name: 'requestLeave',
      component: useComponent('/auth/requestLeave')
    },
    { // # 구분: 관리자 > 조직관리 : requireAdmin() 사용
      path: '/admin/firmManage',
      name: 'firmManage',
      component: useComponent('/admin/firmManage'),
      beforeEnter: requireAdmin()
    },
    { // 구분: 관리자 > 인사|공유관리 > 인사정보 : requireAdmin() 사용
      path: '/admin/staffProfile',
      name: 'staffProfile',
      component: useComponent('/admin/staffProfile'),
      beforeEnter: requireAdmin()
    },
    { // 구분: 관리자 > 인사|공유관리 > 인사정보 > 상세보기: requireAdmin() 사용
      // path: '/admin/viewStaffInfo/:email',
      path: '/admin/viewStaffInfo/:id', // * id 로 변경
      name: 'viewStaffInfo',
      component: useComponent('/admin/viewStaffInfo'),
      beforeEnter: requireAdmin()
    },
    { // 구분: 관리자 > 인사|공유관리 > 공유관리 : requireAdmin() 사용
      path: '/admin/shareManage',
      name: 'shareManage',
      component: useComponent('/admin/shareManage'),
      beforeEnter: requireAdmin()
    },
    { // @: 관리자 > 인사|공유관리 > 발령내역 : requireAdmin() 사용
      path: '/admin/balList',
      name: 'balList',
      component: useComponent('/admin/balList'),
      beforeEnter: requireAdmin()
    },
    { // 구분: 관리자 > 인사|공유관리 > 휴가|근태 : requireAdmin() 사용
      path: '/admin/huList',
      name: 'huList',
      component: useComponent('/admin/huList'),
      beforeEnter: requireAdmin()
    },
    { // 구분: 관리자 > 인사|공유관리 > 인사평가 : requireAdmin() 사용
      path: '/admin/insaList',
      name: 'insaList',
      component: useComponent('/admin/insaList'),
      beforeEnter: requireAdmin()
    },
    { // 구분: 관리자 > 업무부서관리 : requireAdmin() 사용
      path: '/admin/teamManage',
      name: 'teamManage',
      component: useComponent('/admin/teamManage'),
      beforeEnter: requireAdmin()
    },
    { // 구분: 관리자 > 카테고리관리 : requireAdmin() 사용
      path: '/admin/cateManage',
      name: 'cateManage',
      component: useComponent('/admin/cateManage'),
      // meta: { KeepAlive: false },
      // 중요: 카테고리 관리의 서브 메뉴
      children: [
        {
          path: 'cate01', // 관리그룹
          component: useComponent('/admin/cate/cate01')
        },
        {
          path: 'cate02/:type', // 소송분야 & 자문분야
          component: useComponent('/admin/cate/cate02')
        },
        {
          path: 'cate03/:type', // 1depth 카테고리용
          component: useComponent('/admin/cate/cate03')
        },
        {
          path: 'cate04', // 1 depth 주체유형
          component: useComponent('/admin/cate/cate04')
        },
        {
          // 3유형(종국결과,직급구분,인사평가)
          path: 'cate05/:type1/:type2',
          component: useComponent('/admin/cate/cate05')
        },
        {
          path: 'cate06', // 1 depth 발령구분
          component: useComponent('/admin/cate/cate06')
        },
        {
          path: 'cate07', // 1 depth 업무유형
          component: useComponent('/admin/cate/cate07')
        },
        {
          path: 'cate08', // [2021.2.26 추가] 1 depth 사건관리상태
          component: useComponent('/admin/cate/cate08')
        },
        {
          path: 'cate09', // [2021.3.3 추가] 1 depth 업무관리상태
          component: useComponent('/admin/cate/cate09')
        },
        {
          path: 'cate10', // [2021.3.3 추가] 1 depth 수금관리상태
          component: useComponent('/admin/cate/cate10')
        },
        {
          path: 'cate11', // [2021.3.9 추가] 1 depth 첨부파일구분
          component: useComponent('/admin/cate/cate11')
        }
      ],
      beforeEnter: requireAdmin()
    },
    { // 구분: 관리자 > 자동완성키워드 : requireAdmin() 사용
      path: '/admin/keywordManage',
      name: 'keywordManage',
      component: useComponent('/admin/keywordManage'),
      beforeEnter: requireAdmin()
    },
    { // 구분: 관리자 > 회의실구성 : requireAdmin() 사용
      path: '/admin/cabinetManage',
      name: 'cabinetManage',
      component: useComponent('/admin/cabinetManage'),
      beforeEnter: requireAdmin()
    },
    { // 구분: 관리자 > 결제내역 : requireAdmin() 사용
      path: '/admin/paymentList',
      name: 'paymentList',
      component: useComponent('/admin/paymentList'),
      beforeEnter: requireAdmin()
    },
    { // 구분: 일정 - [2020.5.25]인터페이스 변경. keep-alive 사용안함
      path: '/schedule/:id?',
      name: 'scheduleList',
      meta: { KeepAlive: false },
      component: useComponent('/schedule/index'),
      beforeEnter: requireAuth()
    },
    { // 구분: 소송 - 리스트 + 뷰 페이지
      path: '/case/:id?', // * id가 없으면 리스트로, 있으면 뷰로
      name: 'case',
      meta: { KeepAlive: false },
      component: useComponent('/case/index'),
      beforeEnter: requireAuth()
    },
    { // 구분: 독립메뉴 기일/기한 리스트 - [2021.7.20 추가]
      path: '/duedate/:id?',
      name: 'duedateList',
      meta: { KeepAlive: false },
      component: useComponent('/duedate/index'),
      beforeEnter: requireAuth()
    },
    { // 구분: 독립메뉴 송달 리스트 - [2022.6.27 추가]
      path: '/songdal/:id?',
      name: 'songdalList',
      meta: { KeepAlive: false },
      component: useComponent('/songdal/index'),
      beforeEnter: requireAuth()
    },
    { // 구분: 자문 - 리스트 + 뷰 페이지
      path: '/advice/:id?', // * id가 없으면 리스트로, 있으면 뷰로
      name: 'advice',
      meta: { KeepAlive: false },
      component: useComponent('/advice/index'),
      beforeEnter: requireAuth()
    },
    { // 구분: 인명부 - 리스트 + 뷰 페이지
      path: '/client/:id?', // * id가 없으면 리스트로, 있으면 뷰로
      name: 'client',
      meta: { KeepAlive: false },
      component: useComponent('/client/index'),
      beforeEnter: requireAuth()
    },
    { // 구분: 독립메뉴 업무 리스트
      path: '/work/:id?', // 중요: 독립메뉴 업무는 상세페이지가 없지만 id 매개변수가 존재해야 한다
      name: 'workList',
      meta: { KeepAlive: false },
      component: useComponent('/work/index'),
      // beforeEnter: beforeWork()
      beforeEnter: requireAuth()
    },
    { // 구분: 독립메뉴 첨부파일 리스트
      path: '/attach/:id?',
      name: 'attachList',
      meta: { KeepAlive: false },
      component: useComponent('/attach/index'),
      beforeEnter: requireAuth()
    },
    { // 구분: 독립메뉴 타임시트 리스트
      path: '/timesheet/:id?',
      name: 'timesheetList',
      meta: { KeepAlive: false },
      component: useComponent('/timesheet/index'),
      beforeEnter: requireTimesheetAuth()
    },
    { // 구분: 독립메뉴 비용 리스트
      path: '/cost/:id?',
      name: 'costList',
      meta: { KeepAlive: false },
      component: useComponent('/cost/index'),
      beforeEnter: requireCostAuth()
    },
    { // 구분: 독립메뉴 수금 리스트
      path: '/sugum/:id?',
      name: 'sugumList',
      meta: { KeepAlive: false },
      component: useComponent('/sugum/index'),
      beforeEnter: requireSugumContractAuth()
    },
    { // 구분: 독립메뉴 계약 리스트
      path: '/contract/:id?',
      name: 'contractList',
      meta: { KeepAlive: false },
      component: useComponent('/contract/index'),
      beforeEnter: requireSugumContractAuth()
    },
    { // 구분: 게시판 - 리스트 + 뷰페이지
      // keepAlive 를 false 로 하자
      path: '/bbs/:id?', // * id가 없으면 리스트로, 있으면 뷰로
      name: 'bbs',
      meta: { KeepAlive: false },
      component: useComponent('/bbs/index'),
      beforeEnter: requireAuth()
    },
    { // 구분: 물품구매 > 구매신청
      path: '/requestList/:id?',
      name: 'supplyRequest',
      meta: { KeepAlive: false },
      component: useComponent('/supply/requestList'),
      beforeEnter: requireAuth()
    },
    { // 구분: 물품구매 > 거래처 - !! 거래처는 단독 폴더로 뺀다.
      path: '/shop/:id?',
      name: 'shop',
      meta: { KeepAlive: false },
      // component: useComponent('/supply/shopList'),
      component: useComponent('/shop/index'),
      beforeEnter: requireSupplyAuth() // 주의: 물품권한자만 접근가능
    },
    { // 구분: 물품구매 > 구매내역
      path: '/buyList/:id?',
      name: 'supplyBuy',
      meta: { KeepAlive: false },
      component: useComponent('/supply/buyList'),
      beforeEnter: requireSupplyAuth() // 주의: 물품권한자만 접근가능
    },
    { // 구분: 물품구매 > 정산내역
      path: '/settleList/:id?',
      name: 'supplySettle',
      meta: { KeepAlive: false },
      component: useComponent('/supply/settleList'),
      beforeEnter: requireSupplyAuth() // 주의: 물품권한자만 접근가능
    },
    { // 구분: 전자결재 > 결재함 - !! 단독 폴더로 뺀다.
      path: '/ealist/:id?',
      name: 'ealist',
      meta: { KeepAlive: false },
      component: useComponent('/ealist/index'),
      beforeEnter: requireAuth()
    },
    { // 구분: 전자결재 > 작성함 - !! 단독 폴더로 뺀다.
      path: '/eawriting/:id?',
      name: 'eawriting',
      meta: { KeepAlive: false },
      component: useComponent('/eawriting/index'),
      beforeEnter: requireAuth()
    },
    { // 구분: [2022.7.20]회의실예약
      path: '/meeting/:id?',
      name: 'meeting',
      meta: { KeepAlive: false },
      component: useComponent('/meeting/index'),
      beforeEnter: requireAuth()
    },
    { // 구분: 공지사항
      path: '/notice/:id?',
      name: 'notice',
      meta: { KeepAlive: false },
      component: useComponent('/notice/index')
    },
    { // 구분: 서비스안내 > 이용문의 작성 폼 페이지
      path: '/qna/:id?',
      name: 'qna',
      meta: { KeepAlive: false },
      component: useComponent('/qna/index'),
      beforeEnter: requireAuth()
    },
    { // 구분: 서비스안내 > 이용가이드
      path: '/guide/:id?',
      name: 'guide',
      meta: { KeepAlive: false },
      component: useComponent('/guide/index'),
      beforeEnter: requireAuth()
    },
    { // 구분: 테스트용 페이지..
      path: '/help',
      name: 'help',
      component: useComponent('/Help'),
      beforeEnter: requireAuth()
    },
    {
      path: '*',
      name: 'e404',
      component: useComponent('/e404')
    }
  ]
})
