<template>
  <v-card
    tile
    :elevation="0"
  >
    <v-row no-gutters>
      <!-- 좌측 컨텐츠 시작 -->
      <v-col cols="12" md="6" lg="6" xl="6" class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-cog-refresh</v-icon> 계산조건
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              class="mr-2"
              @click.stop="doCalc"
            >
              <v-icon small>mdi-led-on</v-icon>
              계산
            </v-btn>
            <v-btn
              small
              @click.stop="doInit"
            >
              <v-icon small>mdi-refresh</v-icon>
              초기화
            </v-btn>
          </v-toolbar>
          <v-card
            tile
            outlined
            elevation="0"
            class="pa-2"
            min-height="568"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" class="ma-0 pa-0">
                <v-row
                  no-gutters
                  align="center"
                  justify="start"
                  class="pt-2"
                >
                  <v-col cols="7">
                    <v-select
                      v-model="rbChk"
                      :items="rbChkOptions"
                      item-text="text"
                      item-value="value"
                      label="유형"
                      menu-props="auto"
                      dense
                      outlined
                      filled
                      style="max-width: 260px;"
                      @change="rbChkChanged"
                    ></v-select>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      ref="numSongdalFee"
                      v-model.trim="songdalFee"
                      v-validate="'required|max:8|is_not:0'"
                      :error-messages="errors.collect('songdalFee')"
                      data-vv-name="songdalFee"
                      required
                      maxlength="8"
                      label="*1회송달료(원)"
                      dense
                      outlined
                      filled
                      style="max-width: 120px;"
                      hint="예) 5100"
                      @keypress="preventComma"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row
                  no-gutters
                  align="center"
                  justify="start"
                >
                  <v-col cols="12">
                    <span class="text-body-2 primary--text">*채권금액(원)</span>
                  </v-col>
                  <v-col cols="12">
                    <!-- // 2022.9.14 채권금액(청구금액)에서 필수값 제거함
                     <v-text-field
                      ref="numReqPrice"
                      v-model.trim="reqPrice"
                      v-validate="'required|max:15|is_not:0'"
                      :error-messages="errors.collect('reqPrice')"
                      data-vv-name="reqPrice"
                      required
                      maxlength="15"
                      dense
                      outlined
                      filled
                      style="max-width: 250px;"
                      @keypress="preventComma"
                    ></v-text-field> -->
                    <v-text-field
                      ref="numReqPrice"
                      v-model.trim="reqPrice"
                      v-validate="'max:15'"
                      :error-messages="errors.collect('reqPrice')"
                      data-vv-name="reqPrice"
                      maxlength="15"
                      dense
                      outlined
                      filled
                      style="max-width: 250px;"
                      @keypress="preventComma"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row
                  no-gutters
                  align="center"
                  justify="start"
                >
                  <v-col cols="12">
                    <span class="text-body-2 primary--text">*부동산기준금액(원)</span>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      ref="numBasicPrice"
                      v-model.trim="basicPrice"
                      v-validate="'max:15'"
                      :error-messages="errors.collect('basicPrice')"
                      data-vv-name="basicPrice"
                      maxlength="15"
                      dense
                      outlined
                      filled
                      style="max-width: 290px;"
                      @keypress="preventComma"
                    >
                      <template v-slot:append-outer>
                        <v-tooltip right color="primary">
                          <template v-slot:activator="{ on }">
                            <!-- <v-btn
                              v-on="on"
                              fab
                              x-small
                              dark
                              class="mt-n1"
                              @click="goOutSite"
                            >
                              <v-icon color="white">mdi-magnify</v-icon>
                            </v-btn> -->
                            <v-btn
                              v-on="on"
                              fab
                              x-small
                              right
                              color="primary"
                              @click="goOutSite"
                              class="mt-n1"
                            >
                              <v-icon dark small>mdi-launch</v-icon>
                            </v-btn>
                          </template>
                          <span>개별공시지가,시가표준액 바로가기</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <span class="text-body-2 primary--text">부동산(개)</span>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row
                  no-gutters
                  justify="start"
                >
                  <v-text-field
                    ref="numRealEstate"
                    v-model.trim="realEstate"
                    v-validate="'required|max:6|is_not:0'"
                    :error-messages="errors.collect('realEstate')"
                    data-vv-name="realEstate"
                    required
                    maxlength="6"
                    dense
                    outlined
                    filled
                    style="max-width: 180px;"
                    placeholder="예) 1"
                    @blur="realEstate = strToNumber(realEstate) === 0 ? '1' : realEstate"
                    @keypress="preventComma"
                  ></v-text-field>
                  <v-chip
                    label
                    color="white"
                    class="text-caption error--text mt-1 ml-1"
                  >
                    <v-icon small left color="error">mdi-information</v-icon>
                      단독주택 - 토지 + 건물 = 2<br>아파트, 연립, 다세대 - 토지+건물 = 1
                  </v-chip>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row
                  no-gutters
                  align="center"
                  justify="start"
                >
                  <v-col cols="6">
                    <span class="text-body-2 primary--text">집행권원 또는 저당권(건)</span>
                  </v-col>
                  <v-col cols="6">
                    <span class="text-body-2 primary--text">현황조사건수</span>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      ref="numJiphengCnt"
                      v-model.trim="jiphengCnt"
                      v-validate="'required|max:6|is_not:0'"
                      :error-messages="errors.collect('jiphengCnt')"
                      data-vv-name="jiphengCnt"
                      required
                      maxlength="6"
                      dense
                      outlined
                      filled
                      style="max-width: 180px;"
                      placeholder="예) 1"
                      @blur="jiphengCnt = strToNumber(jiphengCnt) === 0 ? '1' : jiphengCnt"
                      @keypress="preventComma"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      ref="numResearchCnt"
                      v-model.trim="researchCnt"
                      v-validate="'required|max:6|is_not:0'"
                      :error-messages="errors.collect('researchCnt')"
                      data-vv-name="researchCnt"
                      required
                      maxlength="6"
                      dense
                      outlined
                      filled
                      style="max-width: 180px;"
                      placeholder="예) 1"
                      @blur="researchCnt = strToNumber(researchCnt) === 0 ? '1' : researchCnt"
                      @keypress="preventComma"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row
                  no-gutters
                  align="center"
                  justify="start"
                >
                  <v-col cols="12">
                    <span class="text-body-2 primary--text">신청서상의 이해관계인(명)</span>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      ref="numRelPersonCnt"
                      v-model.trim="relPersonCnt"
                      v-validate="'max:6'"
                      data-vv-name="relPersonCnt"
                      maxlength="6"
                      dense
                      outlined
                      filled
                      style="max-width: 180px;"
                      placeholder="예) 1"
                      @focus="relPersonCnt = strToNumber(relPersonCnt) === 0 ? '' : relPersonCnt"
                      @keyup="relPersonCnt = strToNumber(relPersonCnt) === 0 ? '' : relPersonCnt"
                      @blur="relPersonCnt = relPersonCnt !== '' ? relPersonCnt : '0'"
                      @keypress="preventComma"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
      <!-- <v-divider vertical class="ma-0 pa-0"></v-divider> -->
      <!-- 우측 컨텐츠 시작 -->
      <v-col class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-cube-send</v-icon> 계산결과
            <v-spacer></v-spacer>
            <v-btn
              small
              @click="doCopy"
              dark
            >
              <v-icon small>mdi-content-copy</v-icon>
              클립보드복사
            </v-btn>
          </v-toolbar>
          <v-card
            id="copyArea"
            tile
            outlined
            elevation="0"
            class="pa-2"
            min-height="568"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12">
                <div class="mb-1">
                  ● 유형: <span class="text-body-2 primary--text font-weight-bold">{{ rbChkTitle }}</span>
                </div>
                <div class="mb-1">
                  ⊙ 채권금액: <span class="text-body-2 primary--text font-weight-bold">{{ numberFormat(cRes.reqPrice) }}</span>원
                </div>
                <div class="mb-1">
                  ⊙ 부동산기준금액: <span class="text-body-2 primary--text font-weight-bold">{{ numberFormat(cRes.basicPrice) }}</span>원
                </div>

                <div class="mt-3 mb-1">
                  <!-- <v-icon small color="primary">mdi-play</v-icon> -->
                  <span class="text-body-2 primary--text font-weight-bold">◆ 경매등기비용</span>
                </div>
                <div class="mb-1 pl-4">
                  － 인지액: {{ numberFormat(cRes.injiTax) }}원
                </div>
                <div class="mb-1 pl-4">
                  － 송달료: {{ numberFormat(cRes.songdalFee) }}원
                </div>
                <div class="mb-1 pl-4">
                  － 등록세: {{ numberFormat(cRes.regTax) }}원
                </div>
                <div class="mb-1 pl-4">
                  － 교육세: {{ numberFormat(cRes.eduTax) }}원
                </div>
                <div class="mb-1 pl-4">
                  － 등기신청수수료: {{ numberFormat(cRes.stampFee) }}원
                </div>
                <div class="mb-1">
                  <span class="text-body-2 font-weight-bold">▶ 소계 ①: {{ numberFormat(cRes.sum01) }}원</span>
                </div>

                <div class="mt-3 mb-1">
                  <!-- <v-icon small color="primary">mdi-play</v-icon> -->
                  <span class="text-body-2 primary--text font-weight-bold">◆ 경매예납금</span>
                </div>
                <div class="mb-1 pl-4">
                  － 신문공고료: {{ numberFormat(cRes.noticeFee) }}원
                </div>
                <div class="mb-1 pl-4">
                  － 현황조사수수료: {{ numberFormat(cRes.researchFee) }}원
                </div>
                <div class="mb-1 pl-4">
                  － 감정료: {{ numberFormat(cRes.appraiseFee) }}원
                </div>
                <div class="mb-1 pl-4">
                  － 매각수수료: {{ numberFormat(cRes.sellFee) }}원
                </div>
                <div class="mb-1">
                  <span class="text-body-2 font-weight-bold">▶ 소계 ②: {{ numberFormat(cRes.sum02) }}원</span>
                </div>

                <div class="my-3">
                  <!-- <v-icon small color="error">mdi-play</v-icon> -->
                  <span class="text-body-2 error--text font-weight-bold">◈ 합계(① + ②): </span>
                  <span class="text-body-2 error--text font-weight-bold">{{ numberFormat(cRes.totalSum) }}원</span>
                </div>
              </v-col>
              <v-col cols="12">
                <v-card
                  elevation="0"
                  min-height="50"
                  class="error--text mt-0 px-0"
                >
                  <v-alert
                    dense
                    text
                    color="error"
                    class="text-caption"
                  >
                    <!-- <v-icon small color="error" class="mr-0">mdi-information-outline</v-icon> -->
                    ※ 위 계산결과는 대법원 안내에 따라 구성된 참고용이므로 실제 비용과 상이할 수 있습니다.
                  </v-alert>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import copy from 'copy-to-clipboard' // !! 클립보드 복사 플러그인
import ko from 'vee-validate/dist/locale/ko'

// 구분: filters
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import preventComma from '@/filters/preventComma' // keypress event 에서 쉼표막기
import strToNumber from '@/filters/strToNumber' // '10,000' => 를 10000 로 변환

// 중요: 초기값 - 고정값이고 년마다 변경될 수 있다!
// !! 1회송달료 (2018.8.13 4500에서 4700원으로 수정)
//            (2019.5.1 4800원으로 수정)
//            (2020.7.1 5100원으로 수정)
// !![2021.3.16] 정수형에서 문자열 숫자로 변경했다
// [2021.11.12] 5100 에에서  5200 로 변경
const _songdalFee = '5200'

export default {
  components: {
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 유형
    rbChk: 1,
    rbChkOptions: [
      { text: '토지,일반건물(아파트제외)', value: 1 },
      { text: '아파트', value: 2 }
    ],
    rbChkTitle: '토지,일반건물(아파트제외)',
    // 구분:
    reqPrice: '0', // 채권금액(청구금액)
    basicPrice: '0',
    songdalFee: '0', // 1회 송달료
    realEstate: '1', // 부동산(갯수)
    jiphengCnt: '1', // 집행권원 또는 저당권(건)
    researchCnt: '1', // 현황조사건수
    relPersonCnt: '0', // 신청서상의 이해관계인(명)
    // 구분: 계산결과를 담을 객체
    cRes: {
      // 계산값이 아닌 변수
      reqPrice: 0, // 채권금액(청구금액)
      basicPrice: 0, // 부동산기준금액
      // 1) 경매등기비용 관련 변수
      injiTax: 0, // 계산된 인지세
      songdalFee: 0, // 계산된 송달료
      regTax: 0, // 계산된 등록면허세
      eduTax: 0, // 계산된 지방교육세
      stampFee: 0, // 계산된 수입증지금액 -> 등기신청수수료
      // 2) 경매예납금 관련
      noticeFee: 0, // 계산된 신문공고료
      researchFee: 0, // 계산된 현황조사수수료
      appraiseFee: 0, // 계산된 감정료
      sellFee: 0, // 계산된 매각수수료
      // 3) 소계 및 합계
      sum01: 0, // 소계 1. 경매등기비용 합계
      sum02: 0, // 소계 2. 경매예납금 합계
      totalSum: 0 // 총합계. 소계 1 + 소계 2
    },
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        reqPrice: '채권금액', // 구 청구금액
        songdalFee: '1회송달료',
        realEstate: '부동산갯수',
        jiphengCnt: '집행/저당권',
        researchCnt: '현황조사건'
      }
    }
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    this.doInit()
  },

  watch: {
    // 구분: 채권금액(청구금액) !![2021.3.17] 수정
    'reqPrice': {
      handler: function (val, oldVal) {
        if (!val) {
          this.reqPrice = '0'
          this.$refs.numReqPrice.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.reqPrice = val.replace(/(^0+)/, '')
          }
          this.reqPrice = numberFormatR(this.reqPrice)
          this.reqPrice = numberFormat(this.reqPrice)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.reqPrice)) {
            this.reqPrice = oldVal
            this.$refs.numReqPrice.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 부동산기준금액 !![2021.3.17] 수정
    'basicPrice': {
      handler: function (val, oldVal) {
        if (!val) {
          this.basicPrice = '0'
          this.$refs.numBasicPrice.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.basicPrice = val.replace(/(^0+)/, '')
          }
          this.basicPrice = numberFormatR(this.basicPrice)
          this.basicPrice = numberFormat(this.basicPrice)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.basicPrice)) {
            this.basicPrice = oldVal
            this.$refs.numBasicPrice.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 1회 송달료 !![2021.3.17] 수정
    'songdalFee': {
      handler: function (val, oldVal) {
        if (!val) {
          this.songdalFee = '0'
          this.$refs.numSongdalFee.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.songdalFee = val.replace(/(^0+)/, '')
          }
          this.songdalFee = numberFormatR(this.songdalFee)
          this.songdalFee = numberFormat(this.songdalFee)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.songdalFee)) {
            this.songdalFee = oldVal
            this.$refs.numSongdalFee.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 부동산 갯수 !![2021.3.17] 수정
    'realEstate': {
      handler: function (val, oldVal) {
        if (!val) {
          this.realEstate = '0'
          this.$refs.numRealEstate.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.realEstate = val.replace(/(^0+)/, '')
          }
          this.realEstate = numberFormatR(this.realEstate)
          this.realEstate = numberFormat(this.realEstate)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.realEstate)) {
            this.realEstate = oldVal
            this.$refs.numRealEstate.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 집행권원 또는 저당권 !![2021.3.17] 수정
    'jiphengCnt': {
      handler: function (val, oldVal) {
        if (!val) {
          this.jiphengCnt = '0'
          this.$refs.numJiphengCnt.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.jiphengCnt = val.replace(/(^0+)/, '')
          }
          this.jiphengCnt = numberFormatR(this.jiphengCnt)
          this.jiphengCnt = numberFormat(this.jiphengCnt)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.jiphengCnt)) {
            this.jiphengCnt = oldVal
            this.$refs.numJiphengCnt.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 현황조사건수 !![2021.3.17] 수정
    'researchCnt': {
      handler: function (val, oldVal) {
        if (!val) {
          this.researchCnt = '0'
          this.$refs.numResearchCnt.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.researchCnt = val.replace(/(^0+)/, '')
          }
          this.researchCnt = numberFormatR(this.researchCnt)
          this.researchCnt = numberFormat(this.researchCnt)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.researchCnt)) {
            this.researchCnt = oldVal
            this.$refs.numResearchCnt.lazyValue = oldVal
          }
        }
      }
    },
    // 구분: 이해관계인 숫자 !![2021.3.17] 수정
    'relPersonCnt': {
      handler: function (val, oldVal) {
        if (!val) {
          this.relPersonCnt = '0'
          this.$refs.numRelPersonCnt.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.relPersonCnt = val.replace(/(^0+)/, '')
          }
          this.relPersonCnt = numberFormatR(this.relPersonCnt)
          this.relPersonCnt = numberFormat(this.relPersonCnt)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.relPersonCnt)) {
            this.relPersonCnt = oldVal
            this.$refs.numRelPersonCnt.lazyValue = oldVal
          }
        }
      }
    }
  },

  methods: {
    numberFormat,
    numberFormatR,
    preventComma,
    strToNumber,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 구분: 유형 변경 이벤트 핸들러
    async rbChkChanged (e) {
      try {
        // 타이틀 변수를 변경한다.
        this.rbChkTitle = this.rbChkOptions.find(r => r.value === this.rbChk).text
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 초기화
    async doInit () {
      try {
        // !! form validation 초기화
        this.$validator.reset()

        this.rbChk = 1 // 유형
        this.rbChkTitle = '토지,일반건물(아파트제외)'

        this.reqPrice = '0' // 채권금액(청구금액)
        this.basicPrice = '0' // 부동산기준금액
        this.songdalFee = _songdalFee // 주의: 1회 송달료 - 지정된 상수로

        this.realEstate = '1' // 부동산(갯수)
        this.jiphengCnt = '1' // 집행권원 또는 저당권(건)
        this.researchCnt = '1' // 현황조사건수
        this.relPersonCnt = '0' // 신청서상의 이해관계인(명)

        this.cRes.reqPrice = 0
        this.cRes.basicPrice = 0
        this.cRes.injiTax = 0 // 계산된 인지세
        this.cRes.songdalFee = 0 // 계산된 송달료
        this.cRes.regTax = 0 // 계산된 등록면허세
        this.cRes.eduTax = 0 // 계산된 지방교육세
        this.cRes.stampFee = 0 // 계산된 수입증지금액 -> 등기신청수수료
        this.cRes.noticeFee = 0 // 계산된 신문공고료
        this.cRes.researchFee = 0 // 계산된 현황조사수수료
        this.cRes.appraiseFee = 0 // 계산된 감정료
        this.cRes.sellFee = 0 // 계산된 매각수수료
        this.cRes.sum01 = 0 // 소계1. 경매등기비용 합계
        this.cRes.sum02 = 0 // 소계2. 경매예납금 합계
        this.cRes.totalSum = 0 // 총합계. 소계1 + 소계2
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 개별공시지가,시가푠준액 바로가기
    async goOutSite () {
      try {
        const gmailUrl = `http://www.realtyprice.kr/notice/main/mainBody.htm`
        const link = document.createElement('a')
        link.href = gmailUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 감정료 계산 1 - 토지, 일반건물
    async calcAppraiseFee01 () {
      try {
        const bp = 100000000 // 기준가격 1억원
        let cResult = 0 // 계산결과

        if (this.cRes.basicPrice > 0 && this.cRes.basicPrice <= 140909090) { // 140909080
          //
          cResult = parseInt(((240000 + 48000) * 1.1), 10)
          //
        } else if (this.cRes.basicPrice > 140909090 && this.cRes.basicPrice <= (bp * 2)) {
          //
          cResult = parseInt(((((this.cRes.basicPrice * 0.0011 + 145000) * 0.8) + 48000) * 1.1), 10)
          //
        } else if (this.cRes.basicPrice > (bp * 2) && this.cRes.basicPrice <= (bp * 5)) {
          //
          cResult = parseInt(((((this.cRes.basicPrice * 0.0011 + 145000) * 0.8) + 88000) * 1.1), 10)
          //
        } else if (this.cRes.basicPrice > (bp * 5) && this.cRes.basicPrice <= (bp * 10)) {
          //
          cResult = parseInt(((((this.cRes.basicPrice * 0.0009 + 245000) * 0.8) + 88000) * 1.1), 10)
          //
        } else if (this.cRes.basicPrice > (bp * 10) && this.cRes.basicPrice <= (bp * 50)) {
          //
          cResult = parseInt(((((this.cRes.basicPrice * 0.0008 + 345000) * 0.8) + 88000) * 1.1), 10)
          //
        } else if (this.cRes.basicPrice > (bp * 50) && this.cRes.basicPrice <= 9507142857) {
          //
          cResult = parseInt(((((this.cRes.basicPrice * 0.0007 + 845000) * 0.8) + 88000) * 1.1), 10)
          //
        } else {
          //
          cResult = parseInt(((6000000 + 88000) * 1.1), 10)
          //
        }

        return Math.floor(cResult)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 감정료 계산 2 - 아파트
    async calcAppraiseFee02 () {
      try {
        const bp = 100000000 // 기준가격 1억원
        let cResult = 0 // 계산결과

        if (this.cRes.basicPrice > 0 && this.cRes.basicPrice <= 179870129) {
          //
          cResult = parseInt(((240000 + 48000) * 1.1), 10)
          //
        } else if (this.cRes.basicPrice > 179870129 && this.cRes.basicPrice <= (bp * 2)) {
          //
          cResult = parseInt(((((this.cRes.basicPrice * 0.0011 + 145000) * 0.7) + 48000) * 1.1), 10)
          //
        } else if (this.cRes.basicPrice > (bp * 2) && this.cRes.basicPrice <= (bp * 5)) {
          //
          cResult = parseInt(((((this.cRes.basicPrice * 0.0011 + 145000) * 0.7) + 88000) * 1.1), 10)
          //
        } else if (this.cRes.basicPrice > (bp * 5) && this.cRes.basicPrice <= (bp * 10)) {
          //
          cResult = parseInt(((((this.cRes.basicPrice * 0.0009 + 245000) * 0.7) + 88000) * 1.1), 10)
          //
        } else if (this.cRes.basicPrice > (bp * 10) && this.cRes.basicPrice <= (bp * 50)) {
          //
          cResult = parseInt(((((this.cRes.basicPrice * 0.0008 + 345000) * 0.7) + 88000) * 1.1), 10)
          //
        } else if (this.cRes.basicPrice > (bp * 50) && this.cRes.basicPrice <= 11037755102) {
          //
          cResult = parseInt(((((this.cRes.basicPrice * 0.0007 + 845000) * 0.7) + 88000) * 1.1), 10)
          //
        } else {
          //
          cResult = parseInt(((6000000 + 88000) * 1.1), 10)
          //
        }

        return Math.floor(cResult)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 매각수수료 계산
    async clacSellFee () {
      try {
        const bp10 = 100000 // 기준가격 10만원
        const bp1000 = 10000000 // 기준가격 1000만원
        const bp1b = 100000000 // 기준가격 1억원
        let cResult = 0 // 계산결과

        if (this.cRes.basicPrice > 0 && this.cRes.basicPrice <= bp10) {
          cResult = 5000
        } else if (this.cRes.basicPrice > bp10 && this.cRes.basicPrice <= bp1000) {
          cResult = ((this.cRes.basicPrice - bp10) * 0.02) + 5000
        } else if (this.cRes.basicPrice > bp1000 && this.cRes.basicPrice <= (bp1000 * 5)) {
          cResult = ((this.cRes.basicPrice - bp1000) * 0.015) + 203000
        } else if (this.cRes.basicPrice > (bp1000 * 5) && this.cRes.basicPrice <= bp1b) {
          cResult = ((this.cRes.basicPrice - (bp1000 * 5)) * 0.01) + 803000
        } else if (this.cRes.basicPrice > bp1b && this.cRes.basicPrice <= (bp1b * 3)) {
          cResult = ((this.cRes.basicPrice - bp1b) * 0.005) + 1303000
        } else if (this.cRes.basicPrice > (bp1b * 3) && this.cRes.basicPrice <= (bp1b * 5)) {
          cResult = ((this.cRes.basicPrice - (bp1b * 3)) * 0.003) + 2303000
        } else if (this.cRes.basicPrice > (bp1b * 5) && this.cRes.basicPrice <= (bp1b * 10)) {
          cResult = ((this.cRes.basicPrice - (bp1b * 5)) * 0.002) + 2903000
        } else {
          cResult = 3903000
        }
        return Math.floor(cResult)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 계산 버튼 이벤트 핸들러
    // * [2022.9.15] 대대적인 수정 - 채권금액(청구금액)과 부동산기준금액을 따로 계산하도록 수정함.
    async doCalc () {
      try {
        // if (strToNumber(this.reqPrice) === 0) {
        //   this.reqPrice = '0'
        // }
        // if (strToNumber(this.basicPrice) === 0) {
        //   this.basicPrice = '0'
        // }

        // #0. 입력값 검증 & 입력받은 변수 정수화 처리
        // * 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // * 입력받은 주요 변수 정수화
        this.cRes.reqPrice = strToNumber(this.reqPrice) // 채권금액(청구금액)
        this.cRes.basicPrice = strToNumber(this.basicPrice) // 부동산기준가액

        const songdalFee = strToNumber(this.songdalFee) // * 입력받은 송달료
        const realEstate = strToNumber(this.realEstate) // 부동산갯수
        const jiphengCnt = strToNumber(this.jiphengCnt) // 집행권원 또는 저당권(건)
        const researchCnt = strToNumber(this.researchCnt) // 현황조사건수
        const relPersonCnt = strToNumber(this.relPersonCnt) // 신청서상의 이해관계인(명)

        // #1. 채권금액(청구금액)이 '0' 이 아닌 경우 '경매등기비용' 계산
        // 주의: 정확한 절사의 규칙을 알지못하므로 그냥 Math.floor()로 소숫점이하를 버린다.
        // ***************************************************************************************************
        // 1. 경매등기비용
        // 인지액 = 집행권원 또는 저당권 x 5,000원
        // 송달료 = ((이해관계인 + 3) x 10회) x 1회 송달료
        // 등록세 = 청구금액 x 0.002 로 변경[2016.05.30] > 기존:기준금액 x 0.002 (단, 최저금액 6,000원)
        // 교육세 = [2022.9.15]등록세 x 0.2 로 변경 > 채권금액(청구금액) x 0.0004 로 변경[2016.05.30] > 기존:기준금액 x 0.0004
        // 수입증지 = 부동산갯수 x 3,000원(등기신청수수료)
        // ***************************************************************************************************
        if (this.reqPrice !== '0') {
          //
          // 채권금액(청구금액)
          this.cRes.reqPrice = strToNumber(this.reqPrice)
          // 인지액
          this.cRes.injiTax = Math.floor(jiphengCnt * 5000)
          // 송달료
          this.cRes.songdalFee = Math.floor(((relPersonCnt + 3) * 10) * songdalFee)
          // 등록세
          this.cRes.regTax = Math.floor(this.cRes.reqPrice * 0.002)
          if (this.cRes.regTax < 6000) {
            this.cRes.regTax = 6000
          }
          // # 교육세 - 2022.9.14 수정: (등록세 x 0.2)로 수정함.
          // this.cRes.eduTax = Math.floor(this.cRes.reqPrice * 0.0004) // 청구금액 x 0.0004
          this.cRes.eduTax = Math.floor(this.cRes.regTax * 0.2) // 등록세 x 0.2
          // 수입증지 -> 등기신청수수료
          this.cRes.stampFee = realEstate * 3000
          // 소계 1
          this.cRes.sum01 = Math.floor(this.cRes.injiTax + this.cRes.songdalFee + this.cRes.regTax + this.cRes.eduTax + this.cRes.stampFee)
          //
        } else {
          // * 경매등기비용 초기화
          this.cRes.reqPrice = 0 // 채권금액(청구금액)
          this.cRes.injiTax = 0 // 인지액
          this.cRes.songdalFee = 0 // 송달료
          this.cRes.regTax = 0 // 등록세
          this.cRes.eduTax = 0 // 교육세
          this.cRes.stampFee = 0 // 등기신청수수료
          this.cRes.sum01 = 0 // 소계 1
        }

        // #2. 부동산기준금액이 '0' 이 아닌 경우 '경매예납금' 계산
        // ***************************************************************************************************
        // 2. 경매예납금
        // 신문공고료 = 부동산이 1 또는 2 => 200,000원
        //             3이상 => 200,000 + ((부동산갯수 - 2) x 100,000)
        // 현황조사수수료 = [2016.05.30]수정 70,000원 x 현황조사건수 > 기존엔 70,000원 고정
        // ***************************************************************************************************
        if (this.basicPrice !== '0') {
          //
          // 부동산기준가액
          this.cRes.basicPrice = strToNumber(this.basicPrice)
          // 신문공고료
          this.cRes.noticeFee = (realEstate > 0 && realEstate < 3) ? 200000 : 200000 + ((realEstate - 2) * 100000)
          this.cRes.noticeFee = parseInt(this.cRes.noticeFee * 1.1, 10) // 정수로 변환
          this.cRes.noticeFee = Math.floor(this.cRes.noticeFee)
          // 현황조사수수료
          this.cRes.researchFee = 70000 * researchCnt
          // 감정료
          if (this.rbChk === 1) { // 토지, 일반건물
            this.cRes.appraiseFee = await this.calcAppraiseFee01()
          } else { // 아파트
            this.cRes.appraiseFee = await this.calcAppraiseFee02()
          }
          // 매각수수료
          this.cRes.sellFee = await this.clacSellFee()
          // 소계2. 경매예납금 합계
          this.cRes.sum02 = Math.floor(this.cRes.noticeFee + this.cRes.researchFee + this.cRes.appraiseFee + this.cRes.sellFee)
        } else {
          // * 경매예납금 초기화
          this.cRes.basicPrice = 0 // 부동산기준가액
          this.cRes.noticeFee = 0 // 신문공고료
          this.cRes.researchFee = 0 // 현황조사수수료
          this.cRes.appraiseFee = 0 // 감정료
          this.cRes.sellFee = 0 // 매각수수료
          this.cRes.sum02 = 0 // 소계 2
        }

        // #3. 총합계 = 소계1 + 소계2
        this.cRes.totalSum = Math.floor(this.cRes.sum01 + this.cRes.sum02)

        // this.execCalculate()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 사용안함: 실제 계산 실행
    async execCalculate () {
      try {
        if (strToNumber(this.reqPrice) === 0) {
          this.reqPrice = '0'
        }
        // * this.basicPrice - 부동산기준금액
        if (strToNumber(this.basicPrice) === 0) {
          this.basicPrice = '0'
        }

        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // !! 입력받은 주요 변수 정수화
        this.cRes.reqPrice = strToNumber(this.reqPrice) // 채권금액(청구금액)
        this.cRes.basicPrice = strToNumber(this.basicPrice) // 부동산기준가액

        const songdalFee = strToNumber(this.songdalFee) // !! 입력받은 송달료
        const realEstate = strToNumber(this.realEstate) // 부동산갯수
        const jiphengCnt = strToNumber(this.jiphengCnt) // 집행권원 또는 저당권(건)
        const researchCnt = strToNumber(this.researchCnt) // 현황조사건수
        const relPersonCnt = strToNumber(this.relPersonCnt) // 신청서상의 이해관계인(명)

        // 주의: 정확한 절사의 규칙을 알지못하므로 그냥 Math.floor()로 소숫점이하를 버린다.
        // ***************************************************************************************************
        // 1. 경매등기비용
        // 인지액 = 집행권원 또는 저당권 x 5,000원
        // 송달료 = ((이해관계인 + 3) x 10회) x 1회 송달료
        // 등록세 = 청구금액 x 0.002 로 변경[2016.05.30] > 기존:기준금액 x 0.002 (단, 최저금액 6,000원)
        // 교육세 = 청구금액 x 0.0004 로 변경[2016.05.30] > 기존:기준금액 x 0.0004
        // 수입증지 = 부동산갯수 x 3,000원(등기신청수수료)
        // ***************************************************************************************************
        // 인지액
        this.cRes.injiTax = Math.floor(jiphengCnt * 5000)
        // 송달료
        this.cRes.songdalFee = Math.floor(((relPersonCnt + 3) * 10) * songdalFee)
        // 등록세
        this.cRes.regTax = Math.floor(this.cRes.reqPrice * 0.002)
        if (this.cRes.regTax < 6000) {
          this.cRes.regTax = 6000
        }
        // # 교육세 - 2022.9.14 수정: (등록세 x 0.2)로 수정함.
        // this.cRes.eduTax = Math.floor(this.cRes.reqPrice * 0.0004) // 청구금액 x 0.0004
        this.cRes.eduTax = Math.floor(this.cRes.regTax * 0.2) // 등록세 x 0.2
        // 수입증지 -> 등기신청수수료
        this.cRes.stampFee = realEstate * 3000
        // 소계 1
        this.cRes.sum01 = Math.floor(this.cRes.injiTax + this.cRes.songdalFee + this.cRes.regTax + this.cRes.eduTax + this.cRes.stampFee)

        // ***************************************************************************************************
        // 2. 경매예납금
        // 신문공고료 = 부동산이 1 또는 2 => 200,000원
        //             3이상 => 200,000 + ((부동산갯수 - 2) x 100,000)
        // 현황조사수수료 = [2016.05.30]수정 70,000원 x 현황조사건수 > 기존엔 70,000원 고정
        // ***************************************************************************************************
        // 신문공고료
        this.cRes.noticeFee = (realEstate > 0 && realEstate < 3) ? 200000 : 200000 + ((realEstate - 2) * 100000)
        this.cRes.noticeFee = parseInt(this.cRes.noticeFee * 1.1, 10) // 정수로 변환
        this.cRes.noticeFee = Math.floor(this.cRes.noticeFee)
        // 현황조사수수료
        this.cRes.researchFee = 70000 * researchCnt
        // 감정료
        if (this.rbChk === 1) { // 토지, 일반건물
          this.cRes.appraiseFee = await this.calcAppraiseFee01()
        } else { // 아파트
          this.cRes.appraiseFee = await this.calcAppraiseFee02()
        }
        // 매각수수료
        this.cRes.sellFee = await this.clacSellFee()
        // 소계2. 경매예납금 합계
        this.cRes.sum02 = Math.floor(this.cRes.noticeFee + this.cRes.researchFee + this.cRes.appraiseFee + this.cRes.sellFee)

        // 총합계. 소계1 + 소계2
        this.cRes.totalSum = Math.floor(this.cRes.sum01 + this.cRes.sum02)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 클립보드에 복사하기
    // 주의: async 를 붙여선 안된다. 매뉴얼 참고
    async doCopy () {
      try {
        const elem = document.querySelector('#copyArea')
        if (!elem) throw new Error(`요청하는 엘리먼트가 없습니다.`)

        const headerTitle = '▣ 부동산경매신청예납비용\n'
        await copy(`${headerTitle}${elem.innerText}`)
        this.$store.commit('SB_POP', { msg: '클립보드에 복사되었습니다.', color: 'success' })
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
