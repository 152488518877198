<template>
  <v-card
    tile
    :elevation="0"
  >
    <v-row no-gutters>
      <!-- 컨텐츠 시작 -->
      <v-col class="ma-2">
        <v-container fluid class="ma-0 pa-0">
          <v-toolbar dense flat color="grey lighten-2">
            <v-icon small class="mr-2">mdi-search-web</v-icon> 기타 실무계산기
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card
            tile
            outlined
            elevation="0"
            min-height="568"
            max-height="568"
            class="pa-4 overflow-y-auto"
          >
            <!-- !! -->
            <v-row
              align="center"
              justify="start"
              class="px-2"
            >
              <template v-for="item in items">
                <v-col
                  :Key="item.id"
                  cols="12" lg="4" md="4" sm="6" xs="12"
                >
                  <v-hover v-slot:default="{ hover }" transition="fade-transition">
                    <v-card
                      class="mx-auto"
                      max-width="344"
                      :elevation="hover ? '9' : '3'"
                    >
                      <v-toolbar dense flat>
                        <span
                          class="text-subtitle-2 font-weight-bold"
                          :class="hover ? 'primary--text' : ''"
                        >{{ item.title }}</span>
                      </v-toolbar>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-toolbar flat>
                          <div>
                            <v-icon small>mdi-chevron-right</v-icon> {{ item.location }}
                          </div>
                          <v-spacer></v-spacer>
                          <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                fab
                                small
                                right
                                color="primary"
                                @click="goLink(item.url)"
                              >
                                <v-icon dark>mdi-launch</v-icon>
                              </v-btn>
                            </template>
                            <span>바로가기</span>
                          </v-tooltip>
                        </v-toolbar>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </template>
            </v-row>
            <!-- !! -->
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

export default {
  components: {
  },

  data: () => ({
    items: [
      // {
      //   id: 1,
      //   title: '부동산가액 및 소가 계산기',
      //   location: '나홀로 소송 페이지로 이동',
      //   url: `https://pro-se.scourt.go.kr/wsh/wsh800/WSH8H0.jsp`
      // },
      {
        id: 2,
        title: '손해배상, 상속분, 변제상계충당 계산기',
        location: 'pc 설치 버전',
        url: `http://ejpc.scourt.go.kr/`
      }
    ]
  }),

  mounted () {
    this.doInit()
  },

  watch: {
    // 'spPrice': { // 금액 자동감지
    //   handler: function (val, oldVal) {
    //     if (val.length > 0) { // 이게 없으면 무한루프 걸릴 수 있다.
    //       // 1) 정상적인 숫자값이 아닌 경우는 isNumber() 로 처리
    //       // 2) 금액은 0으로 시작할 수 없다. 첫번째 값이 0이면 안된다
    //       if (isNumber(val) || val[0] === '0') {
    //         this.spPrice = oldVal
    //         this.$refs.numSpPrice.lazyValue = oldVal
    //       }
    //     }
    //   }
    // }
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 구분: 초기화
    async doInit () {
      try {
        // !! form validation 초기화
        // this.$validator.reset()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 링크 열기 > 새탭으로
    async goLink (oUrl) {
      try {
        const link = document.createElement('a')
        link.href = oUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
