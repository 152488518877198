// preventComma.js
// 쉼표입력을 막아준다!
// keypress 이벤트에서 사용된다. 이벤트에 걸리는 함수라 파라미터로 e 가 그냥 들어간다!
export default (e) => {
  // console.log(e.charCode)

  // 특수문자 키코드 배열 - '_' 아래 밑줄(95), '-'대쉬(45)
  // 원하는 것은 쉼표(44)
  // const specialKeys = [
  //   32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44,
  //   46, 47, 58, 59, 60, 61, 62, 63, 64, 91, 92, 93, 94, 96,
  //   123, 124, 125, 126
  // ]
  // if (specialKeys.includes(e.charCode)) {
  //   e.preventDefault()
  // }

  // 쉼표 하나만 막는다.
  if (e.charCode === 44) {
    e.preventDefault()
  }
}
